import { Map } from 'immutable';
// import { onlineChatLocalStorage } from '../store/localStorage';
export const REQUEST_COMMENTS = 'REQUEST_COMMENTS';
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS';
export const ERROR_REQUEST = 'ERROR_REQUEST';

export function requestComments(page) {
  return {
    type: REQUEST_COMMENTS,
    payload: page
  };
}

export function receiveComments(comments, ids, curLoadingStart) {
  return {
    type: RECEIVE_COMMENTS,
    payload: comments,
    unreadIds: ids,
    currentLoadingStart: curLoadingStart
  };
}

export function errorRequest(error) {
  return {
    type: ERROR_REQUEST,
    payload: error
  };
}

export function loadMoreCommentsAsync(channelId, currPage, offerId) {
  return (dispatch, getState) => {
    const page = Map({ currentPage: currPage + 1 });
    const { loadingStart } = getState().chat.toJS();

    const startId = loadingStart[channelId];

    if (startId === undefined) return;

    dispatch(requestComments(page));

    $.ajax({
      url: `/channels/${channelId}/comments`,
      type: 'GET',
      dataType: 'json',
      data: { page: currPage + 1, start_id: startId, offer_id: offerId },
      cache: false,
      success(data) {
        // console.log(data, channelId);
        // console.log(data.comments_ids);
        // Object.getOwnPropertyNames(data).map((id) => {
        //   // console.log('INIT_CHAT', chat, chat.comments[id], id);
        //   onlineChatLocalStorage.setItem(id, data[id]);
        // });
        const commentsIds = Map(getState().chat.get('unreadCommentsIds')).
          update(channelId.toString(), v => [...v].filter(x => ![...data.comments_ids].includes(x)));

        const currentLoadingStart = Map(getState().chat.get('loadingStart')).set(channelId, Object.keys(data.comments)[0]);

        dispatch(receiveComments(data.comments, commentsIds, currentLoadingStart));
      },
      error(xhr, status, err) {
        console.error(`/channels/${channelId}/comments`, status, err.toString());
        // console.warn(xhr.responseText);
        dispatch(errorRequest(Map({ asyncError: err.toString() })));
      }
    });
  };
}
