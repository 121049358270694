import React, { PureComponent } from 'react';

let isEditChannel = false;
let timeoutId = 0;

export default class Person extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEdit: false,
      selectedChannel: -1
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChooseActiveChat = this.handleChooseActiveChat.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { active, asyncLoading, newCommentsIds } = this.props;

  //   if (active === null) return true;
  //   if (active) {
  //     const rt = [...nextProps.newCommentsIds[active]].filter((x) => {
  //       if (![...newCommentsIds[active]].includes(x)) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     if (rt) return true;
  //   }
  //   return false;
  // }

  componentWillUnmount() {
    clearTimeout(timeoutId);
  }

  handleChooseActiveChat(e) {
    if (isEditChannel) return;

    const { activeChatAction, viewNewCommentsAction } = this.props;
    const idChannel = e.currentTarget.getAttribute('data-chat');

    activeChatAction(idChannel);
    viewNewCommentsAction(idChannel);

    this.setState({ selectedChannel: idChannel });
  }

  handleEdit(e) {
    const idChannel = e.target.getAttribute('data-channel');
    const nameChannel = document.querySelector(`#name${idChannel}`);
    const descChannel = document.querySelector(`#desc${idChannel}`);
    const { editPersonAsync } = this.props;

    if (!isEditChannel) return;

    this.delay(3000).then(() => {
      editPersonAsync(idChannel, nameChannel.value, descChannel.value);
      this.setState({ isEdit: false });
      isEditChannel = false;
    });
  }

  handleBlur() {
    this.setState({ isEdit: false });
    isEditChannel = false;
  }

  handleClickEdit() {
    this.setState(prevState => ({
      isEdit: !prevState.isEdit
    }));
    isEditChannel = !isEditChannel;
  }

  handleToggle() {
    const {
      id,
      name,
      description,
      membersCount,
      newCommentsIds,
      unreadCommentsIds,
      active
    } = this.props;

    const channelId = id;
    const nameChannel = `name${id}`;
    const descChannel = `desc${id}`;
    const unreadComments = unreadCommentsIds[channelId].length;
    let btnEdit, labelUnRead, selectCh;
    let notActive = false;

    if (active === null) {
      notActive = true;
    } else if (active.toString() !== channelId.toString()) {
      notActive = true;
    }

    if (unreadComments > 0 && notActive) {
      labelUnRead = (
        <div className='chat-channels__channel-unread-msgs'>
          {unreadComments}
        </div>);
    }

    if (gon.current_user_role === 'admin') {
      btnEdit = (
        <a
          className='chat__sidebar-channel-edit-link'
          onClick={this.handleClickEdit}
        >
          <svg version='1.1' width='14px' height='14px'
            viewBox='0 0 34 34'
          >
            <polygon points='15.888,24.656 6.976,28 10.316,19.085 '/>
            <rect
              x='16.618'
              y='5.739'
              transform='matrix(-0.707 -0.7072 0.7072 -0.707 24.7792 39.4361)'
              width='7.88'
              height='17.694'
            />
            <rect
              x='25.383'
              y='3.852'
              transform='matrix(-0.7072 -0.707 0.707 -0.7072 45.9406 30.6714)'
              width='7.878'
              height='3.94'
            />
            <rect width='16' height='2' />
            <rect width='2' height='34' />
            <rect x='32' y='17' width='2' height='17' />
            <rect y='32' width='33' height='2' />
          </svg>
        </a>
      );
    }

    if (active) {
      if (active.toString() === this.state.selectedChannel.toString()) {
        selectCh = 'chat-channels__channel-item_active';
      }
    } else {
      selectCh = '';
    }

    const nameSplit = name.split(/\u2013|\u2014/);
    const nameFirst = nameSplit[0].trim();

    if (!this.state.isEdit) {
      return (
        <div>
          <a
            className={`chat-channels__channel-item ${selectCh}`}
            data-chat={channelId}
            onClick={this.handleChooseActiveChat}
          >
            <div className='chat-channels__badge'>{membersCount}</div>
            <div className='chat-channels__info'>
              <div className='chat-channels__channel-name'>{nameFirst}</div>
              <div className='chat-channels__channel-description'>{description}</div>
            </div>
            <div className='chat-channels__channel-actions'>
              {btnEdit}
              {labelUnRead}
            </div>
          </a>
        </div>
      );
    }
    if (this.state.isEdit) {
      return (
        <div>
          <a
            className='chat-channels__channel-item chat-channels__channel-item_active'
            data-chat={channelId}
          >
            <div className='chat-channels__badge'>{membersCount}</div>
            <div className='chat-channels__info'>
              <div className='chat-channels__channel-name'>
                <input
                  type='text'
                  defaultValue={nameFirst}
                  id={nameChannel}
                  data-channel={id}
                  onChange={this.handleEdit}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='chat-channels__channel-description'>
                <input
                  type='text'
                  defaultValue={description}
                  id={descChannel}
                  data-channel={id}
                  onChange={this.handleEdit}
                  onBlur={this.handleBlur}
                />
              </div>
            </div>
          </a>
        </div>
      );
    }
  }

  delay(t) {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(resolve, t);
    });
  }

  render() {
    return (
      <div>{this.handleToggle()}</div>
    );
  }
}
