import openMenuTable from './openMenuTable';
import hideMenuTable from './hideMenuTable';

export default function clickOpenMenuTable(e) {
  const header1 = document.querySelector('.events__menu--open');
  const header2 = document.querySelector('.events__menu-wrap--open');

  e.preventDefault();

  if (header1 && header2) hideMenuTable();
  else openMenuTable();
}
