import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import BookingDatePicker from './booking_date_picker';
import { sortObject } from '../../../../common/javascript/api';
import Popup from '../../components/Popup';
import PeriodsList from '../../components/PeriodsList';

export default class OfferDates extends PureComponent {
  constructor(props) {
    super(props);

    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDayFromClick = this.handleDayFromClick.bind(this);
    this.handleDayToClick = this.handleDayToClick.bind(this);
    this.handleOpenPeriodClick = this.handleOpenPeriodClick.bind(this);
    this.handleClosePeriodClick = this.handleClosePeriodClick.bind(this);
    this.handleSelectedDates = this.handleSelectedDates.bind(this);

    moment.locale('ru');
  }

  componentDidMount() {
    const { offer, addStartDate, addFinishDate } = this.props;
    const keyPeriod = `${offer.recommendedFirstDate}-${offer.recommendedLastDate}`;

    if (offer.allowCustomDuration) {
      addStartDate(offer.recommendedFirstDate);
      addFinishDate(offer.recommendedLastDate);
    } else {
      addStartDate(offer.periodDates[keyPeriod].startDate);
      addFinishDate(offer.periodDates[keyPeriod].finishDate);
    }
  }

  handleDayFromClick() {
    const { isOpenFromDay, openStartDate, closeStartDate } = this.props;

    if (isOpenFromDay) {
      closeStartDate();
    } else {
      openStartDate();
    }
  }

  handleDayToClick() {
    const { isOpenToDay, openFinishDate, closeFinishDate } = this.props;

    if (isOpenToDay) {
      closeFinishDate();
    } else {
      openFinishDate();
    }
  }

  handleFromChange(day) {
    const {
      offerDates,
      addStartDate,
      addFinishDate,
      closeStartDate,
      clearSelectedItems,
      offer,
      searchHotel
    } = this.props;
    let currentStartDate = moment(moment(day).format('YYYY-MM-DD')).toDate();

    const endPickerEnabledDays = offer.customDurationDates[moment(currentStartDate).format('YYYY-MM-DD')];
    const isFixedPeriod = endPickerEnabledDays[0].fromEndDate === endPickerEnabledDays[0].toEndDate;

    if (isFixedPeriod) {
      addFinishDate(moment(endPickerEnabledDays[0].toEndDate).toDate());
    } else {
      addFinishDate(null);
    }

    addStartDate(currentStartDate);
    closeStartDate();
    clearSelectedItems();

    if (isFixedPeriod) searchHotel();
  }

  handleToChange(day) {
    const {
      offerDates,
      addFinishDate,
      closeFinishDate,
      clearSelectedItems,
      searchHotel
    } = this.props;
    let currentFinishDate = moment(moment(day).format('YYYY-MM-DD')).toDate();

    addFinishDate(currentFinishDate);
    closeFinishDate();
    clearSelectedItems();
    searchHotel();
  }

  handleOpenPeriodClick() {
    const {
      openPeriodDate,
      openForm
    } = this.props;

    openPeriodDate();
    openForm();
  }

  handleClosePeriodClick() {
    const {
      closePeriodDate,
      closeForm
    } = this.props;

    closePeriodDate();
    closeForm();
  }

  handleSelectedDates(e, dateKey) {
    e.preventDefault();

    const {
      offer,
      addStartDate,
      addFinishDate,
      closePeriodDate,
      clearSelectedItems,
      closeForm,
      initOptionList
    } = this.props;
    const startDate = offer.periodDates[dateKey].startDate;
    const finishDate = offer.periodDates[dateKey].finishDate;
    addStartDate(startDate);
    addFinishDate(finishDate);
    closePeriodDate();
    clearSelectedItems();
    closeForm();
    initOptionList();
  }

  periodDatesRows() {
    const { offer, offerDates, members } = this.props;
    const parentEl = Object.entries(members.activeHotels).length > 0 ? members : offer;

    if (Object.entries(parentEl.periodDates).length === 0) return '';

    return (
      <PeriodsList
        periodsByMonth={parentEl.periodDatesGroupByMonth}
        minPriceByPeriod={parentEl.minPriceByPeriod}
        periods={parentEl.periodDates}
        selectedPeriod={{ startDate: offerDates.startDate, finishDate: offerDates.finishDate }}
        offer={offer}
        onClick={this.handleSelectedDates}
      />
    );
  }

  renderCustomDurationDates() {
    const {
      offer,
      offerDates,
      isOpenFromDay,
      isOpenToDay
    } = this.props;
    const firstDate = moment(offer.firstDate).toDate();
    const lastDate = moment(offer.lastDate).toDate();
    const startDate = moment(offerDates.startDate || offer.recommendedFirstDate).toDate();
    const finishDate = moment(offerDates.finishDate || offer.recommendedLastDate).toDate();
    const startPickerEnabledDays = Object.keys(offer.customDurationDates);
    const endPickerEnabledDays = offer.customDurationDates[moment(startDate).format('YYYY-MM-DD')];

    return (
      <Fragment>
        <div className='booking__select-item booking__select-item-small'>
          <div className='booking__select-item-content'>
            <div className='booking__select-item-content-title'>Заезд</div>
          </div>
          <div
            className={`booking__selectbox ${isOpenFromDay ? 'booking__selectbox--open' : ''}`}
          >
            <div
              className='booking__selectbox-head'
              onClick={this.handleDayFromClick}
            >
              <div className='booking__selectbox-head-text'>
                <strong>{moment(startDate).format('D MMMM YYYY, ')}</strong>
                <div className='booking__selectbox-full-day'>{moment(startDate).format('dddd')}</div>
                <div className='booking__selectbox-small-day'>{moment(startDate).format('dd')}</div>
              </div>
              <div className='booking__selectbox-booking__calendar' />
            </div>
            <div className='booking__selectbox-dropdown booking__calendar-wrap'>
              <div
                className='booking__selectbox-head'
                onClick={this.handleDayFromClick}
              >
                <div className='booking__selectbox-head-text'>
                  <strong>{moment(startDate).format('D MMMM YYYY, ')}</strong>
                  <div className='booking__selectbox-full-day'>{moment(startDate).format('dddd')}</div>
                  <div className='booking__selectbox-small-day'>{moment(startDate).format('dd')}</div>
                </div>
              </div>
              <div className='booking__selectbox-dropdown-content'>
                <div className='booking__calendar'>
                  <BookingDatePicker
                    onChange={this.handleFromChange}
                    firstDate={firstDate}
                    lastDate={lastDate}
                    startDate={startDate}
                    finishDate={null}
                    initialMonth={startDate}
                    enabledDays={startPickerEnabledDays}
                    recommendedDate={moment(offer.recommendedFirstDate).toDate()}
                    startPicker
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='booking__select-item booking__select-item-small'>
          <div className='booking__select-item-content'>
            <div className='booking__select-item-content-title'>Выезд</div>
          </div>
          <div
            className={`booking__selectbox ${isOpenToDay ? 'booking__selectbox--open' : ''}`}
          >
            <div
              className='booking__selectbox-head'
              onClick={this.handleDayToClick}
            >
              <div className='booking__selectbox-head-text'>
                <strong>{moment(finishDate).format('D MMMM YYYY, ')}</strong>
                <div className='booking__selectbox-full-day'>{moment(finishDate).format('dddd')}</div>
                <div className='booking__selectbox-small-day'>{moment(finishDate).format('dd')}</div>
              </div>
              <div className='booking__selectbox-booking__calendar' />
            </div>
            <div className='booking__selectbox-dropdown booking__calendar-wrap'>
              <div
                className='booking__selectbox-head'
                onClick={this.handleDayToClick}
              >
                <div className='booking__selectbox-head-text'>
                  <strong>{moment(finishDate).format('D MMMM YYYY, ')}</strong>
                  <div className='booking__selectbox-full-day'>{moment(finishDate).format('dddd')}</div>
                  <div className='booking__selectbox-small-day'>{moment(finishDate).format('dd')}</div>
                </div>
              </div>
              <div className='booking__selectbox-dropdown-content'>
                <div className='booking__calendar'>
                  <BookingDatePicker
                    onChange={this.handleToChange}
                    firstDate={firstDate}
                    lastDate={lastDate}
                    startDate={startDate}
                    finishDate={finishDate}
                    initialMonth={moment(endPickerEnabledDays[0].fromEndDate).toDate()}
                    recommendedDate={moment(offer.recommendedLastDate).toDate()}
                    enabledDays={endPickerEnabledDays}
                    endPicker
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderPeriodDates() {
    const {
      offer,
      offerDates,
      isOpenPeriodDate
    } = this.props;

    const startDate = moment(offerDates.startDate || moment()).toDate();
    const finishDate = moment(offerDates.finishDate || moment()).toDate();
    const qntPeriods = Object.keys(offer.periodDates).length;
    const btnOtherDates = (qntPeriods > 1)
      ? <div className='booking__selectbox-multi-other'><span>выбрать</span>другие даты</div>
      : '';


    return (
      <div className='booking__select-item booking__select-item-medium'>
        <div className='booking__select-item-content'>
          <div className='booking__select-item-content-title booking__select-item-content-title-multi'>
            <div className='booking__select-item-content-title-word'>заезд</div>
            <div className='booking__select-item-content-title-word center'>и</div>
            <div className='booking__select-item-content-title-word'>выезд</div>
          </div>
          <div
            className={`booking__selectbox ${isOpenPeriodDate ? 'booking__selectbox--open' : ''}`}
            onClick={(qntPeriods > 1) ? this.handleOpenPeriodClick : null}
          >
            <div
              className={(qntPeriods > 1) ? 'booking__selectbox-head booking__selectbox-head-multi' : 'booking__selectbox-head-one-row'}
              onClick={(qntPeriods > 1) ? this.handleOpenPeriodClick : null}
            >
              <div className='booking__selectbox-multi-text'>
                <div className='booking__selectbox-multi-date'>
                  <strong>{moment(startDate).format('D MMMM YYYY, ')}</strong>
                  {moment(startDate).format('dddd')}
                </div>
                <div className='booking__selectbox-multi-dash' />
                <div className='booking__selectbox-multi-date'>
                  <strong>{moment(finishDate).format('D MMMM YYYY, ')}</strong>
                  {moment(finishDate).format('dddd')}
                </div>
              </div>
              {btnOtherDates}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      offer,
      isFormOpen
    } = this.props;

    return (
      <Fragment>
        {offer.allowCustomDuration ? this.renderCustomDurationDates() : this.renderPeriodDates()}
        <Popup
          show={isFormOpen}
          onClose={this.handleClosePeriodClick}
          variant='medium'
          withHeader
        >
          {this.periodDatesRows()}
        </Popup>
      </Fragment>
    );
  }
}
