export const SHOW_MORE_ROOMS = 'SHOW_MORE_ROOMS';
export const CLEAR_HOTEL_ITEMS = 'CLEAR_HOTEL_ITEMS';
export const OPEN_DESCRIPTION_HOTEL = 'OPEN_DESCRIPTION_HOTEL';
export const CLOSE_DESCRIPTION_HOTEL = 'CLOSE_DESCRIPTION_HOTEL';

function showMoreRoomsAction(date) {
  return {
    type: SHOW_MORE_ROOMS,
    payload: date
  };
}

function clearHotelItemsAction() {
  return {
    type: CLEAR_HOTEL_ITEMS
  };
}

function openDescriptionHotelAction(data) {
  return {
    type: OPEN_DESCRIPTION_HOTEL,
    payload: data
  };
}

function closeDescriptionHotelAction(data) {
  return {
    type: CLOSE_DESCRIPTION_HOTEL,
    payload: data
  };
}

export function openDescriptionHotel(hotelId) {
  return (dispatch, getState) => {
    const descriptionHotelIds = [...new Set([...getState().hotelItems.descriptionHotelIds, ...[hotelId]])];

    dispatch(openDescriptionHotelAction(descriptionHotelIds));
  };
}

export function closeDescriptionHotel(hotelId) {
  return (dispatch, getState) => {
    const descriptionHotelIds = getState().hotelItems.descriptionHotelIds.filter((elem) => elem !== hotelId);

    dispatch(closeDescriptionHotelAction(descriptionHotelIds));
  };
}

export function showMoreRooms(date) {
  return (dispatch, getState) => {
    const showRoomsIds = [...new Set([...getState().hotelItems.showRoomIds, ...[date]])];
    dispatch(showMoreRoomsAction(showRoomsIds));
  };
}

export function clearHotelItems() {
  return (dispatch) => {
    dispatch(clearHotelItemsAction());
  };
}
