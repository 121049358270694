import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Booking from '../components/Booking';
import * as BookingActions from '../actions/booking';
import WrapHook from '../components/WrapHook';

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BookingActions, dispatch);
}

const BookingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapHook(Booking));

export default BookingContainer;
