import { combineReducers } from 'redux';
import bookingReducer from './booking';
import offerDatesReducer from './offer_dates';
import membersReducer from './members';
import bookingHotelReducer from './booking_hotel';
import bookingRoomsItemReducer from './booking_rooms_item';
import bookingOptionsItemReducer from './booking_options_item';
import interestCategorieReducer from './interest_categories';
import filterHotelsReducer from './filter_hotels';
import filterFoodsReducer from './filter_food';
import sharedReducer from './shared';

const rootReducer = combineReducers({
  offer: bookingReducer,
  offerDates: sharedReducer.merge(offerDatesReducer),
  members: sharedReducer.merge(membersReducer),
  hotelItems: bookingHotelReducer,
  roomItems: sharedReducer.merge(bookingRoomsItemReducer),
  optionItems: bookingOptionsItemReducer,
  interestCategories: sharedReducer.merge(interestCategorieReducer),
  filterHotels: sharedReducer.merge(filterHotelsReducer),
  filterFoods: sharedReducer.merge(filterFoodsReducer)
});

export default rootReducer;
