import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import BonusCard from '../BonusCard/BonusCard';

import * as styles from './ChpuEvents.module.scss';
import Action from '../Action';

const CHPU_LINKS = {
  bonus: 'bonus'
};

const ChpuEvents = ({ bonuses, referalLink }) => {
  const [isBonusesOpen, openBonuses, closeBonuses] = usePopup();
  const [isReferalOpen, openReferal, closeReferal] = usePopup();
  const [headerColor, setHeaderColor] = useState('');

  const urlParams = window.location.search.substr(1);

  const BonusesListItems = bonuses?.map(bonus => (
    <BonusCard key={`bonus-card__${bonus.id}`} bonus={bonus} />
  ));

  const referalParam = referalLink.links?.find(referalItem => referalItem === urlParams);

  useEffect(() => {
    if (urlParams === CHPU_LINKS.bonus) {
      openBonuses();
    }
    if (referalParam) {
      setHeaderColor('purple');
      openReferal();
    }
  }, []);

  return (
    <>
      {bonuses?.length > 0 && (
        <Popup
          show={isBonusesOpen}
          onClose={closeBonuses}
          variant='medium'
          withHeader={false}
        >
          <div>
            <div className={styles.ChpuEvents}>
              {BonusesListItems}
            </div>
            <div className={styles.ChpuEvents__center}>
              <Action
                themes={['Button', 'ButtonCommon', 'ButtonWarning', 'Fullwidth']}
                onClick={closeBonuses}
              >
                ок, понятно
              </Action>
            </div>
          </div>
        </Popup>
      )}
      {referalParam && (
        <Popup
          show={isReferalOpen}
          onClose={closeReferal}
          variant='medium'
          withHeader
          header={
            `Ваш подарочный промокод:
            <strong>${referalParam.split('&')[1]?.toUpperCase()}</strong>
            `
          }
          headerColor={headerColor}
        >
          <div className={styles.ChpuEvents__desc}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: referalLink.description }} />
          </div>
        </Popup>
      )}
    </>
  );
};

ChpuEvents.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired,
  referalLink: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.string)
  }))
};

ChpuEvents.defaultProps = {
  referalLink: {
    description: '',
    links: []
  }
};

export default ChpuEvents;
