import {
  INIT_OPTION_LIST
} from '../actions/booking_options_list';


import {
  SELECTED_OPTION_ITEM,
  REMOVE_SELECTED_OPTION_ITEM,
  CLEAR_SELECTED_OPTION_ITEM,
  OPEN_DESCRIPTION_OPTION_ITEM,
  CLOSE_DESCRIPTION_OPTION_ITEM
} from '../actions/booking_options_item';

const initialState = {
  selectedOptionIds: {},
  optionPrices: {},
  descriptionOptionsIds: []
};

const actionsMap = {
  [INIT_OPTION_LIST]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedOptionIds: payload.selectedOptionIds,
      optionPrices: payload.optionPrices
    };
  },

  [SELECTED_OPTION_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedOptionIds: payload
    };
  },

  [REMOVE_SELECTED_OPTION_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedOptionIds: payload
    };
  },

  [CLEAR_SELECTED_OPTION_ITEM]: (state) => {
    return {
      ...state,
      selectedOptionIds: {}
    };
  },

  [OPEN_DESCRIPTION_OPTION_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionOptionsIds: payload
    };
  },

  [CLOSE_DESCRIPTION_OPTION_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionOptionsIds: payload
    };
  }
};

export default function bookingOptionsItemReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
