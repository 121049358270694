// import clickLink from '../../common/clickLink';
import showHideCallbackPhoneLink from '../../callback_phone/showHideCallbackPhoneLink';
import hideHamburgerMenu from '../../common/hideHamburgerMenu';

export default function clickContact(e) {
  hideHamburgerMenu();
  // clickLink(e, '.page-main__block_contacts', 'contacts');
  e.currentTarget.href = '#anchor-page-main__block_contacts';
  showHideCallbackPhoneLink();
}
