import React, { PureComponent, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { ReachGoal } from '../../YandexMetrika';
import { Ga } from '../../GoogleAnalytics';
import { Goal } from '../../Vk';


import AuthForm from '../../components/AuthForm';
import Popup from '../../components/Popup';
import Action from '../../components/Action';
import { action } from '../../../../common/javascript/api';
import Loader from '../../components/Loader';
import BonusesList from '../../components/BonusesList/BonusesList';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import AttentionIcon from '../../../../../icons/attention.svg';
import { deleteCookie, getCookie, isBrowser, setCookie } from '../../../../helpers/browser';
import DatePeriodShow from '../../components/DatePeriodShow';
import DayNightDifferenceShow from '../../components/DayNightDifferenceShow';

class BookingPrice extends PureComponent {
  constructor() {
    super();

    this.state = {
      isOrdering: false
    };

    this.handleClickBooking = this.handleClickBooking.bind(this);
    this.handlePriceInfo = this.handlePriceInfo.bind(this);
    this.handleClosePriceInfoClick = this.handleClosePriceInfoClick.bind(this);
    this.handleCloseAuthForm = this.handleCloseAuthForm.bind(this);
  }

  componentDidMount() {
    const { isLoggedIn } = this.props;

    if (!isLoggedIn) return;

    if (isBrowser) {
      const orderParams = getCookie('spotwayOrderParamsForNotLoggedUser');

      if (!getCookie('spotwayAuthForNotLoggedUser')) return;
      if (!orderParams) return;

      const data = {
        order: orderParams
      };

      this.setState({
        isOrdering: true
      }, () => {
        action('/orders.json?', data, 'POST')
          .then(
            (result) => {
              // console.log('result =>', result);
              deleteCookie('spotwayAuthForNotLoggedUser');
              deleteCookie('spotwayOrderParamsForNotLoggedUser');
              deleteCookie('spotwayRedirectUrlForNotLoggedUser');
              window.location = `/orders/${result.order.pnr}/edit?order_tab=members`;
            },
            (error) => {
              console.log('error =>', error);
              this.setState({
                isOrdering: false
              });
            }
          );
      });
    }
  }

  generateAndSaveOrderParams() {
    const {
      offer,
      members,
      offerDates,
      roomItems,
      optionItems
    } = this.props;

    const choiceApartments = members.isShowHotels ? Object.values(members.single_choice_products).filter(value => value.product_type) : [];
    const choiceSingleProducts = Object.values(optionItems.selectedOptionIds).reduce((acc = [], id) => { acc.push({ product_id: offer.singleChoiceProducts[id].id, program_id: offer.singleChoiceProducts[id].programId }); return acc; }, []);

    const orderParams = {
      offer_id: offer.id,
      date: moment(offerDates.startDate).format('DD.MM.YYYY'),
      duration: moment(offerDates.finishDate).diff(moment(offerDates.startDate), 'days') + 1,
      choices: [...new Set([...choiceApartments, ...choiceSingleProducts])],
      food_type: roomItems.selectedRoom.foodTypeCode,
      age_group: Object.entries(members.age_data).map(([key, value]) => [key, value.value]),
      cost: this.calculatePrice(),
      new_design: true,
      adults_count: members.adultsCount,
      childs_count: members.childsCount
    };

    if (members.isShowHotels) orderParams['apartment_product_id'] = roomItems.selectedRoomId;

    let date = new Date();
    // one minute
    date.setTime(date.getTime() + (60 * 1000));

    setCookie('spotwayOrderParamsForNotLoggedUser', window.btoa(JSON.stringify(orderParams)), { expires: date });
    setCookie('spotwayRedirectUrlForNotLoggedUser', location.href, { expires: date });
  }

  ordering() {
    const {
      offer,
      members,
      offerDates,
      roomItems,
      optionItems
    } = this.props;

    if (offer.click_btn_booked_target) ReachGoal(offer.click_btn_booked_target, { offer: offer.name });
    Ga(`${offer.slug}_choose`);
    Goal(`${offer.slug}_choose`);

    // const choiceApartments = Object.entries(members.single_choice_products).map(([key, value]) => value);
    const choiceApartments = members.isShowHotels ? Object.values(members.single_choice_products).filter(value => value.product_type) : [];
    const choiceSingleProducts = Object.values(optionItems.selectedOptionIds).reduce((acc = [], id) => { acc.push({ product_id: offer.singleChoiceProducts[id].id, program_id: offer.singleChoiceProducts[id].programId }); return acc; }, []);

    const orderParams = {
      offer_id: offer.id,
      date: moment(offerDates.startDate).format('DD.MM.YYYY'),
      duration: moment(offerDates.finishDate).diff(moment(offerDates.startDate), 'days') + 1,
      choices: [...new Set([...choiceApartments, ...choiceSingleProducts])],
      food_type: roomItems.selectedRoom.foodTypeCode,
      age_group: Object.entries(members.age_data).map(([key, value]) => [key, value.value]),
      cost: this.calculatePrice(),
      new_design: true,
      adults_count: members.adultsCount,
      childs_count: members.childsCount
      // sub_offers: optionItems.selectedOptionIds.reduce((acc = {}, id) => { acc[id] = { sub_offer_id: offer.singleChoiceProducts[id].id, price: offer.singleChoiceProducts[id].price }; return acc; }, {})
    };

    if (members.isShowHotels) orderParams['apartment_product_id'] = roomItems.selectedRoomId;

    // console.log('startDate', moment(offerDates.startDate));
    // console.log('finishDate', moment(offerDates.finishDate));
    // console.log(moment(offerDates.finishDate).diff(moment(offerDates.startDate), 'days') + 1);
    // console.log(orderParams);
    // console.log(window.btoa(JSON.stringify(orderParams)));

    // window.location = `/orders/new?order=${window.btoa(JSON.stringify(orderParams))}`;

    const data = {
      order: window.btoa(JSON.stringify(orderParams))
    };

    action('/orders.json?', data, 'POST')
      .then(
        (result) => {
          console.log('result =>', result);
          window.location = `/orders/${result.order.pnr}/edit?order_tab=members`;
        },
        (error) => {
          console.log('error =>', error);
          this.setState({
            isOrdering: false
          });
        }
      );
  }

  handleCloseAuthForm() {
    const { closeAuthForm, isLoggedIn, offer } = this.props;

    closeAuthForm();

    if (!isLoggedIn) return;

    this.setState({
      isOrdering: true
    }, () => {
      if (offer.after_auth_from_booking_target) ReachGoal(offer.after_auth_from_booking_target, { offer: offer.name });
      this.ordering();
    });
  }

  handleClickBooking() {
    const { openAuthForm, isLoggedIn } = this.props;

    if (!isLoggedIn) {
      this.generateAndSaveOrderParams();
      openAuthForm();
      return;
    }

    this.setState({
      isOrdering: true
    }, () => {
      this.ordering();
    });
  }

  handlePriceInfo() {
    const { openForm } = this.props;

    openForm();
  }

  handleClosePriceInfoClick() {
    const { closeForm } = this.props;

    closeForm();
  }

  handleOptionDescription(element) {
    const { openDescriptionOption } = this.props;

    openDescriptionOption(element.id);
  }

  handleRoomDescription(roomId, roomFoodTypeCode) {
    const { openDescriptionRoomItem } = this.props;

    openDescriptionRoomItem(roomId, roomFoodTypeCode);
  }

  calculatePrice() {
    const {
      offer,
      members,
      roomItems,
      optionItems,
      offerDates
    } = this.props;
    const price = roomItems.selectedRoom.priceAll || 0;
    const options = Object.entries(members.singleChoiceProductPrices).length === 0
      ? optionItems.optionPrices
      : members.singleChoiceProductPrices;

    const keyPeriod = `${moment(offerDates.startDate).format('YYYY-MM-DD')}-${moment(offerDates.finishDate).format('YYYY-MM-DD')}`;

    if (Object.entries(options).length === 0) return price;

    const priceOptions = Object.entries(optionItems.selectedOptionIds).reduce((acc, [k, v]) => {
      let cost = 0;

      if (offer.allowCustomDuration) {
        cost = options[keyPeriod]
          ? options[keyPeriod][parseInt(v, 10)].gross
          : 0;
      } else {
        cost = options[keyPeriod][parseInt(v, 10)]
          ? options[keyPeriod][parseInt(v, 10)].gross
          : 0;
      }
      acc += cost;
      return acc;
    }, 0);

    return price + priceOptions;
  }

  oneMemberPrice(nameClass) {
    const price = this.calculatePrice();

    if (price === 0) return '';

    const { offer, members } = this.props;
    const membersCount = members.currentAdultsCount + members.currentChildsCount;

    return (
      <>
        {(membersCount < 2) && (
          <div className={nameClass} />
        )}
        {(membersCount > 1) && (
          <div className={nameClass}>
            или
            <strong>
              {` ${new Intl.NumberFormat('ru-RU').format(Math.round(price / membersCount))} ${offer.currency} `}
            </strong>
            с человека
          </div>
        )}
      </>
    );
  }

  notesDates() {
    const { offerDates } = this.props;

    const { startDate, finishDate } = offerDates;

    return (
      (startDate && finishDate) && (
        <>
          <DatePeriodShow
            startDate={Date.parse(startDate)}
            finishDate={Date.parse(finishDate)}
          />
          <DayNightDifferenceShow
            startDate={Date.parse(startDate)}
            finishDate={Date.parse(finishDate)}
          />
        </>
      )
    );
  }

  notes(type = 'desktop') {
    const isVisible = true;

    return (
      <>
        {isVisible && (
          <div
            className={type === 'desktop' ? 'booking__price-valid __desktop' : ''}
          >
            {this.notesDates()}
          </div>
        )}
        {/* {!isVisible && (
          <>
            {(type === 'mobile') && <div className='booking__popup-price-cost-person' />}
            <div
              className={`booking__price-note __${type}`}
            >
                Выберите проживание
            </div>
          </>
        )} */}
      </>
    );
  }

  nameHotelRoom(nameClass) {
    const { offer, roomItems } = this.props;

    if (!roomItems.selectedRoomId) return '';

    const isPreviousSearchHotels = roomItems.previousApartments ? Object.entries(roomItems?.previousApartments).length !== 0 : false;
    const isCurrentSearchHotels = Object.entries(roomItems.apartments).length !== 0;
    const isInitHotels = Object.entries(roomItems.apartments).length === 0
      && !isPreviousSearchHotels;

    let apartments = {};
    if (isInitHotels) apartments = offer.apartments;
    else if (isCurrentSearchHotels) apartments = roomItems.apartments;
    else if (!isCurrentSearchHotels && isPreviousSearchHotels) apartments = roomItems.previousApartments;

    // const apartments = Object.entries(roomItems.apartments).length === 0
    //   ? offer.apartments
    //   : roomItems.apartments;

    return (
      <div className={nameClass}>
        {apartments[roomItems.selectedRoom.apartmentId]?.name}
        <br />
        <FontAwesomeIcon icon={faUtensils} />
        &nbsp;
        {roomItems.selectedRoom.foodType}
        <br />
        {roomItems.selectedRoom.description && (
          <Action
            className='booking__popup-price-cost-link'
            onClick={this.handleRoomDescription.bind(this, roomItems.selectedRoom.apartmentId, roomItems.selectedRoom.foodTypeCode)}
          >
            Фото и описание номера
          </Action>
        )}
      </div>
    );
  }

  renderOptions(nameClass) {
    const { offer, roomItems, optionItems } = this.props;

    if (Object.entries(optionItems.selectedOptionIds).length === 0) return '';

    const arrOptions = Object.values(optionItems.selectedOptionIds).map(optionItemId => {
      const product = offer.singleChoiceProducts[optionItemId];

      if (!product.hideProgram) {
        return (
          <Fragment key={`fragment_name_${optionItemId}`}>
            <ul className='booking__price-included-list booking__popup-price-included-list'>
              <li
                key={`option_name_${optionItemId}`}
              >
                {offer.singleChoicePrograms[product.programId].name}
              </li>
            </ul>
            <div className={nameClass}>
              {product.name}
              {product.description && (
                <Action
                  className='booking__popup-price-cost-link'
                  onClick={this.handleOptionDescription.bind(this, product)}
                >
                  подробнее
                </Action>
              )}
            </div>
          </Fragment>
        );
      }
    });

    if (roomItems.selectedRoomId) {
      arrOptions.push(
        <ul key={`ul_aprt_name_${roomItems.selectedRoomId}`} className='booking__price-included-list booking__popup-price-included-list'>
          <li key={`aprt_name_${roomItems.selectedRoomId}`}>
            {offer.apartment_programs[0].name}
          </li>
        </ul>
      );
    }

    return arrOptions;
  }

  renderButton() {
    const { roomItems, members } = this.props;
    const isBtnVisible = members.isShowHotels ? roomItems.selectedRoomId !== 0 : true;

    return (
      <div className='booking__price-btn-wrap'>
        {isBtnVisible && (
          <Action
            themes={['Button', 'Fullwidth', 'ButtonWarning', 'ButtonCommon']}
            disabled={!isBtnVisible}
            onClick={this.handleClickBooking}
          >
            Бронировать
          </Action>
        )}
        {!isBtnVisible && (
          <Action
            className='booking__price-btn-red-border'
            themes={['Button', 'ButtonSecondary', 'Fullwidth', 'ButtonCommon']}
            onClick={null}
          >
            <div className='booking__price-btn-text__desktop'>
              <IconSvg icon={AttentionIcon} />
              Выберите проживание
            </div>
            <div className='booking__price-btn-text__mobile'>Выберите<br />проживание</div>
          </Action>
        )}
        {/* <div className='booking__price-text'>Бронируй сейчас, плати потом!</div> */}
      </div>
    );
  }

  renderPopup() {
    const { offer, roomItems, members, isFormOpen } = this.props;
    const cost = this.calculatePrice();
    const options = this.renderOptions('booking__popup-price-hotel');
    const buttonVisible = members.isShowHotels ? roomItems.selectedRoomId !== 0 : true;
    const { childsCount, adultsCount } = members;
    const countHuman = parseInt(adultsCount, 10) + parseInt(childsCount, 10);

    return (
      <Popup show={isFormOpen} onClose={this.handleClosePriceInfoClick} variant='medium' withHeader={false}>
        <div className='booking__popup-content'>
          <div className='booking__popup-price'>
            <div className='booking__popup-price-choose'>{`сумма заказа - ${countHuman} чел.`}</div>
            <div className='booking__popup-price-cost'>
              <div className='booking__popup-price-cost-figure'>
                {new Intl.NumberFormat('ru-RU').format(cost)}
              </div>
              <div className='booking__popup-price-cost-currency'>
                {offer.currency}
              </div>
            </div>
            {this.oneMemberPrice('booking__popup-price-cost-person')}
            <div className='booking__popup-price-included-title'>{this.notes('mobile')}</div>
            <div className='booking__popup-price-included'>
              <div className='booking__popup-price-included-title'>в цену включено</div>
              {options}
              {this.nameHotelRoom('booking__popup-price-hotel')}
            </div>
            {buttonVisible && (
              <div className='booking__popup-price-btn-wrap'>
                <Action
                  themes={['Button', 'Fullwidth', 'ButtonWarning', 'ButtonCommon']}
                  disabled={!buttonVisible}
                  onClick={this.handleClickBooking}
                >
                  Бронировать
                </Action>
              </div>
            )}
            {!buttonVisible && (
              <div className='booking__popup-price-btn-wrap'>
                <Action
                  themes={['Button', 'ButtonSecondary', 'Fullwidth', 'ButtonCommon']}
                  onClick={null}
                >
                  <div className='booking__popup-price-btn-text'>
                    <IconSvg icon={AttentionIcon} />
                    Выберите проживание
                  </div>
                </Action>
              </div>
            )}
            {offer.bonuses.length > 0 && (
              <div className='booking__popup-price-btn-wrap'>
                <BonusesList bonuses={offer.bonuses} bonusesBackground='white' />
              </div>
            )}
          </div>
        </div>
      </Popup>
    );
  }

  render() {
    const { offer, isAuthFormOpen, isFormOpen, members } = this.props;
    const { isOrdering } = this.state;
    const cost = this.calculatePrice();
    const options = this.renderOptions('booking__price-hotel');
    const { childsCount, adultsCount } = members;
    const countHuman = (
      <span>
        {parseInt(adultsCount, 10) + parseInt(childsCount, 10)}
        &nbsp;чел.
      </span>
    );

    return (
      <Fragment>
        <Loader view={isOrdering} />
        <div className='booking__price __desktop'>
          <div className='booking__price-sticky'>
            <div className='booking__price-choose'>
              сумма заказа&nbsp;-&nbsp;
              {countHuman}
            </div>
            <div className='booking__price-cost'>
              <div className='booking__price-cost-figure'>
                {new Intl.NumberFormat('ru-RU').format(cost)}
              </div>
              <div className='booking__price-cost-currency'>{offer.currency}</div>
              {!isFormOpen && (
                <Action
                  className='booking__price-cost-link'
                  onClick={this.handlePriceInfo}
                >
                  В цену включено?
                </Action>
              )}
            </div>
            {this.oneMemberPrice('booking__price-cost-person')}
            {this.renderButton()}
            {this.notes()}
            <div className='booking__price-included'>
              {/* {offer.bonuses.length > 0 && (
                <div className='booking__price-included-bonuses'>
                  <BonusesList bonuses={offer.bonuses} bonusesBackground='purple' />
                </div>
              )} */}
              <div className='booking__price-included-title'>
                в цену включено
              </div>
              {options}
            </div>
            {this.nameHotelRoom('booking__price-hotel')}
          </div>
        </div>
        {this.renderPopup()}
        <Popup
          show={isAuthFormOpen}
          onClose={this.handleCloseAuthForm}
          variant='small'
          panelClassNames='booking__price-grey'
        >
          <AuthForm onClose={this.handleCloseAuthForm} />
        </Popup>
      </Fragment>
    );
  }
}

export default BookingPrice;
