export const ADD_START_DATE = 'ADD_START_DATE';
export const ADD_FINISH_DATE = 'ADD_FINISH_DATE';

function addStartDateAction(date) {
  return {
    type: ADD_START_DATE,
    payload: date
  };
}

function addFinishDateAction(date) {
  return {
    type: ADD_FINISH_DATE,
    payload: date
  };
}

export function addStartDate(date) {
  return (dispatch) => {
    dispatch(addStartDateAction(date));
  };
}

export function addFinishDate(date) {
  return (dispatch) => {
    dispatch(addFinishDateAction(date));
  };
}
