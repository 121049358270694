import React, { PureComponent } from 'react';
import cn from 'classnames';

import Slider from '../../../components/Slider/Slider';
import Popup from '../../../components/Popup';
import Action from '../../../components/Action';
import HotelInfo from './HotelInfo';
import RoomInfo from './RoomInfo';

import * as styles from './BookingDescription.module.scss';

export default class BookingDescription extends PureComponent {
  componentDidMount() {
    const { openForm } = this.props;
    openForm();
  }

  handleCloseClick(elementId) {
    const {
      closeDescriptionRoomItem,
      closeDescriptionHotel,
      closeDescriptionOption
    } = this.props;

    closeDescriptionRoomItem(elementId);
    closeDescriptionHotel(elementId);
    closeDescriptionOption(elementId);
  }

  handleSelectClick(roomId, room) {
    const {
      selectedRoomItem,
      removeSelectedRoomItem,
      closeDescriptionRoomItem,
      element
    } = this.props;

    if (roomId === null) removeSelectedRoomItem();
    else selectedRoomItem(roomId, room);

    closeDescriptionRoomItem(element.id);
  }

  handleOpenHotel() {
    const { openForm2 } = this.props;

    openForm2();
  }

  handleCloseHotel() {
    const { closeForm2 } = this.props;
    closeForm2();
  }

  isSelectedRoom() {
    const { room, roomItems } = this.props;

    return room.id === roomItems.selectedRoomId
      && room.foodTypeCode === roomItems.selectedRoom.foodTypeCode;
  }

  elementContent(element) {
    return {
      contents: element?.images?.reduce((acc, f) => { acc.push(Object.assign({}, { url: f, title: '', info: '', type: 'image' })); return acc; }, []) || []
    };
  }

  renderSlider(typeView = 'mobile') {
    const { element } = this.props;

    if (typeView === 'desktop' && !element.images) return '';
    if (typeView === 'mobile' && !element.thumb_images) return '';

    const contents = this.elementContent(element);

    return (
      <div className={styles.BookingDescription__slider}>
        <Slider {...contents} />
      </div>
    );
  }

  renderButton(typeView = 'desktop') {
    const { room } = this.props;

    if (room === undefined) return '';

    const selectedRoom = this.isSelectedRoom();

    return (
      <>
        {selectedRoom && (
          <Action
            to='/'
            className='btn btn_red booking__rooms-item-btn'
            onClick={this.handleSelectClick.bind(this, null, null)}
            themes={['Fullwidth']}
          >
            удалить этот номер
          </Action>
        )}
        {!selectedRoom && (
          <Action
            to='/'
            className='btn btn_gradient_purple booking__rooms-item-btn'
            onClick={this.handleSelectClick.bind(this, room.id, room)}
            themes={['Fullwidth']}
          >
            выбрать за
            {/* `выбрать этот номер за ${room.coastAll} ${room.currencySign}` */}
            {room.discountDate && typeView === 'mobile' && (
              <div className='booking__rooms-item-btn-text-old'>
                &nbsp;
                {`${room.costCurrentAll} ${room.currencySign}`}
              </div>
            )}
            &nbsp;
            {`${room.coastAll} ${room.currencySign}`}
          </Action>
        )}
      </>
    );
  }

  renderLinkAndButton() {
    const { room } = this.props;

    if (room === undefined) return '';

    return (
      <div className={styles.BookingDescription__content__select_room}>
        <>
          {/* <div className='booking__rooms-item-btn-wrap'>
            {this.renderButton()}
          </div> */}
          {room && (
            <Action
              themes={['Button', 'ButtonSecondary', 'Fullwidth']}
              className={styles.BookingDescription__secondaryBtn}
              onClick={this.handleOpenHotel.bind(this)}
            >
              смотреть еще описание отеля
            </Action>
          )}
        </>
      </div>
    );
  }

  render() {
    const { element, room, isFormOpen, isFormOpen2, offer, isOption, isHotel } = this.props;

    let btnRoom = '';
    if (room !== undefined) {
      btnRoom = (
        <div className='booking__popup-block'>
          {/* <div className='booking__rooms-item-btn-wrap'>
            {this.renderButton('mobile')}
          </div> */}
          {room.discountDate && (
            <div className='booking__rooms-item-discount-date is-medium'>
              {`скидка до ${room.discountDate}`}
            </div>
          )}
        </div>
      )
    } else '';

    return (
      <>
        <Popup
          show={isFormOpen}
          onClose={this.handleCloseClick.bind(this, element.id)}
          variant='medium'
          withHeader={isOption || false}
          footer={this.renderLinkAndButton()}
        >
          <div className={styles.BookingDescription__content}>
            {this.renderSlider()}
            {isHotel && <HotelInfo hotel={element} />}
            {room && (
              <RoomInfo
                hotel={offer.hotels[element.hotel_id]}
                room={room}
                openHotelForm={this.handleOpenHotel.bind(this)}
              />
            )}
            <div className={styles.BookingDescription__content__info}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: element.description }} />
            </div>
          </div>
        </Popup>
        {room && (
          <Popup show={isFormOpen2} onClose={this.handleCloseHotel.bind(this)} variant='medium' withHeader={false}>
            <div className={styles.BookingDescription__content}>
              <div className={styles.BookingDescription__slider}>
                <Slider {...this.elementContent(offer.hotels[element.hotel_id])} />
              </div>
              <HotelInfo hotel={offer.hotels[element.hotel_id]} />
              <div className={styles.BookingDescription__content__info}>
                <div dangerouslySetInnerHTML={{ __html: offer.hotels[element.hotel_id].description }} />
              </div>
            </div>
          </Popup>
        )}
      </>
    );
  }
}
