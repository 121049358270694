import React from 'react';
import hideMenuTable from '../menu_table/hideMenuTable';
// import useIntersect from './useIntersect';

const EventPageMenuItem = (props) => {
  const { item, rootClass } = props;

  // const [refItem, entry] = useIntersect({
  //   root: document.querySelector(rootClass),
  //   rootMargin: '0px',
  //   threshold: 1.0
  // });

  const handleClick = (id, e) => {
    const anchor = document.querySelectorAll(`a[name$="_${id}"]`)[0];

    if (!anchor) return;

    e.nativeEvent.stopImmediatePropagation();
    hideMenuTable();
    e.currentTarget.href = `${window.location.pathname}#${anchor.name}`;
  };

  // return (
  //   <li
  //     ref={refItem}
  //     key={`menu_item_${item.id}`}
  //     className='w-event__nav-list-item'
  //   >
  //     <a
  //       href='#'
  //       onClick={handleClick.bind(this, item.id)}
  //       className={`w-event__nav-list-link ${entry.intersectionRatio > 0.25 ? 'white' : 'black'} ${item.highlight ? 'highlight' : ''}`}
  //     >
  //       {item.title}
  //     </a>
  //   </li>
  // );

  return (
    <li
      key={`menu_item_${item.id}`}
      className='w-event__nav-list-item'
    >
      <a
        href='#'
        onClick={handleClick.bind(this, item.id)}
        className={`w-event__nav-list-link ${item.highlight ? 'highlight' : ''}`}
      >
        {item.title}
      </a>
    </li>
  );
};

export default EventPageMenuItem;
