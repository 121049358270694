import {
  SHOW_MORE_ROOMS,
  OPEN_DESCRIPTION_HOTEL,
  CLOSE_DESCRIPTION_HOTEL,
  CLEAR_HOTEL_ITEMS
} from '../actions/booking_hotel';

const initialState = {
  showRoomIds: [],
  descriptionHotelIds: []
};

const actionsMap = {
  [SHOW_MORE_ROOMS]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      showRoomIds: payload
    };
  },

  [CLEAR_HOTEL_ITEMS]: (state) => {
    return {
      ...state,
      showRoomIds: [],
      descriptionHotelIds: []
    };
  },

  [OPEN_DESCRIPTION_HOTEL]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionHotelIds: payload
    };
  },

  [CLOSE_DESCRIPTION_HOTEL]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionHotelIds: payload
    };
  }
};

export default function bookingHotelReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
