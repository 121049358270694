import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingDescription from '../components/BookingDescription';
import * as BookingRoomsItemActions from '../actions/booking_rooms_item';
import * as BookingHotelActions from '../actions/booking_hotel';
import * as BookingOptionActions from '../actions/booking_options_item';
import * as SharedActions from '../actions/shared';
import WrapHook from '../components/WrapHook';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...BookingHotelActions,
    ...BookingRoomsItemActions,
    ...BookingOptionActions,
    ...SharedActions
  }, dispatch);
}
const BookingDescriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapHook(BookingDescription));

export default BookingDescriptionContainer;
