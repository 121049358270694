import React, { Component } from 'react';
import People from './people';
import FormCommentsApp from '../containers/FormCommentsApp';
import BlockMessagesApp from '../containers/BlockMessagesApp';

export default class Chat extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { socketOpen } = this.props;

    socketOpen();
  }

  shouldComponentUpdate(nextProps) {
    const { channels, active } = this.props;

    if (active === null) return true;
    if (channels[active].name !== nextProps.channels[active].name) return true;
    if (channels[active].description !== nextProps.channels[active].description) return true;
    if (active && nextProps.active === null && active !== nextProps.active) return true;
    if (active !== null && active !== nextProps.active) return true;

    return false;
  }

  componentWillUnmount() {
    const { socketClose } = this.props;

    socketClose();
  }

  handleClick() {
    const { resetChannelAction } = this.props;

    resetChannelAction();
  }

  renderFormSubmit() {
    const { active, channels } = this.props;

    if (!active) return '';
    if (channels[active].blocked && gon.current_user_role !== 'admin') return '';
    return (<FormCommentsApp />);
  }

  renderSpinner() {
    return (
      <div
        className='text-center chat__choose_blank'
        style={styles.index999}
      >
        <div
          style={styles.centered}
        >
          <i
            className='fa fa-spinner'
            aria-hidden='true'
          />
            &nbsp;Загрузка...
        </div>
      </div>
    );
  }

  render() {
    const { channels, asyncLoading, active } = this.props;
    const spinner = asyncLoading ? this.renderSpinner() : '';
    const nameChannel = active === null ? '' : channels[active].name.split(/\u2013|\u2014/)[0].trim();
    const classSelected = `chat chat_channel-selected_${(active === null ? 'false' : 'true')}`;

    return (
      <div className={classSelected}>
        <div className='chat__channels-container chat-channels'>
          <div className='chat-channels__header'>
            Все чаты
          </div>
          <People channels={channels} />
        </div>

        <div className='chat__msgs-global-container'>
          <div className='chat__msgs-header chat-msgs-header'>
            <div className='chat-message-header__choose-channel-link'>
              <a onClick={this.handleClick}>
                <svg
                  version='1.1'
                  viewBox='0 0 1000 1000'
                >
                  <path
                    d='M732.1,989.9c6.6,0,13.2-2.5,18.3-7.5c10.1-10.1,10.1-26.4,0-36.5l-446-446l446-446c10.1-10.1,10.1-26.4,0-36.5c-10.1-10.1-26.4-10.1-36.5,0L249.7,481.8c-10.1,10.1-10.1,26.4,0,36.5l464.2,464.2C718.9,987.5,725.5,990,732.1,989.9L732.1,989.9z'
                  />
                </svg>
                Чаты
              </a>
            </div>
            <div className='chat-message-header__channel-name'>
              {nameChannel}
            </div>
          </div>
          <div className='chat__msgs-body chat-msgs-body'>
            <BlockMessagesApp />
          </div>
          {this.renderFormSubmit()}
        </div>
        {spinner}
      </div>
    );
  }
}

const styles = {
  index999: {
    zIndex: '999',
    height: '100%',
    opacity: '0.7'
  },
  centered: {
    marginTop: '50%'
  }
};
