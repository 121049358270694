export const INIT_FILTER_HOTEL = 'INIT_FILTER_HOTEL';
export const SELECTED_FILTER_HOTEL = 'SELECTED_FILTER_HOTEL';
export const REMOVE_SELECTED_FILTER_HOTEL = 'REMOVE_SELECTED_FILTER_HOTEL';

function initFilterHotelAction(data) {
  return {
    type: INIT_FILTER_HOTEL,
    payload: data
  };
}

function selectedFilterHotelsAction(data) {
  return {
    type: SELECTED_FILTER_HOTEL,
    payload: data
  };
}

function removeSelectedFilterHotelsAction(data) {
  return {
    type: REMOVE_SELECTED_FILTER_HOTEL,
    payload: data
  };
}

export function initFilterHotel() {
  return (dispatch, getState) => {
    const { offer, members } = getState();
    const parent = Object.entries(members.activeHotels).length > 0 ? members : offer;
    const selectedHotelIds = [...new Set(...Object.values(parent.activeHotels))];

    dispatch(initFilterHotelAction(selectedHotelIds));
  };
}

export function selectedFilterHotels(hotelIds) {
  return (dispatch) => {
    // const selectedHotelIds = [...new Set([...getState().filterHotels.selectedHotelIds, ...[hotelId]])];

    dispatch(selectedFilterHotelsAction(hotelIds));
  };
}

export function removeSelectedFilterHotels(hotelIds) {
  return (dispatch) => {
    // const selectedHotelIds = getState().filterHotels.selectedHotelIds.filter((elem) => elem !== hotelId);

    dispatch(removeSelectedFilterHotelsAction(hotelIds));
  };
}
