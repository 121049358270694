import React, { Component } from 'react';

export default class FormComment extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      commentBody: '',
      isCheckedEmail: gon.current_user_role === 'admin',
      isCheckedSms: false,
      caretContainer: '',
      caretOffset: 0,
      isVisiblePlaceholder: true,
      isVisibleButtonSend: false,
      isNewLine: false
    };

    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitComment = this.handleSubmitComment.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleFormClick = this.handleFormClick.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const msie = window.navigator.userAgent.indexOf('Trident');

    if (msie > 0) {
      this.textContainer.addEventListener('keyup', this.handleKeyUp);
      this.textContainer.addEventListener('keydown', this.handleKeyDown);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.commentBody !== this.state.commentBody) return true;
    if (nextProps.replyIdMsg !== this.props.replyIdMsg) return true;
    if (nextState.isVisiblePlaceholder !== this.state.isVisiblePlaceholder) return true;
    if (nextState.isCheckedEmail !== this.state.isCheckedEmail) return true;
    if (nextState.isCheckedSms !== this.state.isCheckedSms) return true;
    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.replyIdMsg !== this.props.replyIdMsg) {
      let visiblePlaceholder = false;

      if (prevProps.replyIdMsg && !this.props.replyIdMsg) {
        visiblePlaceholder = true;
      }

      this.setState({
        commentBody: '',
        caretContainer: '',
        caretOffset: 0,
        isVisiblePlaceholder: visiblePlaceholder,
        isVisibleButtonSend: false,
        isNewLine: false
      });
    }

    if (this.state.commentBody) {
      if (this.state.isNewLine) {
        this.placeCaretAtEnd(this.textContainer);
        this.setState({ isNewLine: false });
      } else {
        this.placeCaretAtPosition(this.textContainer, this.state.caretContainer, this.state.caretOffset);
      }
    }
  }

  componentWillUnmount() {
    const msie = window.navigator.userAgent.indexOf('Trident');

    if (msie > 0) {
      this.textContainer.removeEventListener('keyup', this.handleKeyUp);
      this.textContainer.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleChecked(e) {
    const currTarge = e.currentTarget;
    const idCheckBox = currTarge.getAttribute('id');

    if (idCheckBox === 'email') {
      this.setState({ isCheckedEmail: !this.state.isCheckedEmail });
    } else if (idCheckBox === 'sms') {
      this.setState({ isCheckedSms: !this.state.isCheckedSms });
    }
  }

  handleChange(e) {
    e.preventDefault();

    const { attachMsgAuthor, removeReplyMsg, isAddComment } = this.props;
    const value = e.target.innerHTML
      .replace(/<br(\s*)\/*>/ig, '\n')
      .replace(/<[p|div]\s/ig, '\n$0')
      .replace(/(<([^>]+)>)/ig, '');

    let visiblePlaceholder = true;
    let visibleButtonSend = false;

    this.getCaretCharacterOffsetWithin(e.target);

    if (value.trim()) {
      visiblePlaceholder = false;
    } else {
      visiblePlaceholder = true;
    }

    if (value.trim().replace(attachMsgAuthor, '') && !isAddComment) {
      visibleButtonSend = true;
    } else {
      visibleButtonSend = false;
    }

    this.setState({
      isVisiblePlaceholder: visiblePlaceholder,
      isVisibleButtonSend: visibleButtonSend,
      commentBody: value
    });

    if (attachMsgAuthor) {
      const posAuthor = value.trim().search(`^${attachMsgAuthor}`);

      if (posAuthor === -1) {
        removeReplyMsg();
      }
    }
  }

  handleSubmitComment() {
    const { active, addCommentAction, replyIdMsg, removeReplyMsg, attachMsgAuthor, isAddComment } = this.props;

    if (isAddComment || !this.state.commentBody.trim()) return;

    const newComment = {
      'comment': {
        'reply_id': replyIdMsg,
        'body': this.state.commentBody.replace(attachMsgAuthor, '').trim()
      },
      'channel_id': active,
      'sendSms': this.state.isCheckedSms,
      'sendEmail': this.state.isCheckedEmail
    };

    addCommentAction(active, newComment);

    this.setState({
      commentBody: '',
      isCheckedEmail: gon.current_user_role === 'admin',
      isCheckedSms: false,
      caretContainer: '',
      caretOffset: 0,
      isVisiblePlaceholder: true,
      isVisibleButtonSend: false,
      isNewLine: false
    });

    removeReplyMsg();

    this.textContainer.focus();
  }

  handleKeyUp(e) {
    if (e.keyCode === 16) {
      if (e.keyCode === 13) {
        return;
      } else if (e.keyCode === 38
          || e.keyCode === 60
          || e.keyCode === 62
          || e.charCode === 38
          || e.charCode === 60
          || e.charCode === 62) {
        e.preventDefault();
      }
    }

    this.handleChange(e);
  }

  handleKeyDown(e) {
    const value = e.currentTarget.textContent;

    if (e.keyCode === 16) {
      if (e.keyCode === 13) {
        this.setState({ isNewLine: true });
        return;
      } else if (e.keyCode === 38
          || e.keyCode === 60
          || e.keyCode === 62
          || e.charCode === 38
          || e.charCode === 60
          || e.charCode === 62) {
        e.preventDefault();
      } else if (e.keyCode === 13) {
        e.preventDefault();

        if (!value.trim()) return;

        this.handleSubmitComment();

        this.setState({ caretContainer: '', caretOffset: 0 });
        return;
      }
    }
  }

  handleKeyPress(e) {
    const value = e.currentTarget.textContent;

    if (e.nativeEvent.shiftKey) {
      if (e.nativeEvent.keyCode === 13) {
        this.setState({ isNewLine: true });
      } else if (e.nativeEvent.keyCode === 38
          || e.nativeEvent.keyCode === 60
          || e.nativeEvent.keyCode === 62
          || e.nativeEvent.charCode === 38
          || e.nativeEvent.charCode === 60
          || e.nativeEvent.charCode === 62) {
        e.preventDefault();
      }
    } else if (e.nativeEvent.keyCode === 13) {
      e.preventDefault();

      if (!value.trim()) return;

      this.handleSubmitComment();

      this.setState({ caretContainer: '', caretOffset: 0 });
    }
  }

  handleFormClick(e) {
    const dataClick = e.target.getAttribute('data-click');

    if (dataClick) {
      this.placeCaretAtEnd(this.textContainer);
    }
  }

  getCaretCharacterOffsetWithin(el) {
    if (!el) return -1;

    el.focus();

    let caretOffset = 0;
    let sel;

    if (typeof window.getSelection !== undefined && typeof document.createRange !== undefined) {
      sel = window.getSelection();

      if (sel.rangeCount > 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();

        preCaretRange.selectNodeContents(el);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;

        this.setState({ caretContainer: range.endContainer, caretOffset: range.endOffset });
      }
    } else if (typeof document.body.createTextRange !== undefined) {
      const textRange = sel.createRange();
      const preCaretTextRange = document.body.createTextRange();

      preCaretTextRange.moveToElementText(el);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;

      this.setState({ caretContainer: textRange.endContainer, caretOffset: textRange.endOffset });
    }
    return caretOffset;
  }

  placeCaretAtEnd(el) {
    if (!el) return;

    el.focus();
    if (typeof window.getSelection !== undefined && typeof document.createRange !== undefined) {
      const range = document.createRange();

      range.selectNodeContents(el);
      range.collapse(false);

      const sel = window.getSelection();

      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== undefined) {
      const textRange = document.body.createTextRange();

      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  placeCaretAtPosition(el, char, pos) {
    if (!el) return;

    el.focus();
    if (typeof window.getSelection !== undefined && typeof document.createRange !== undefined) {
      const range = document.createRange();

      range.selectNodeContents(el);
      range.setEnd(char, pos);
      range.collapse(false);

      const sel = window.getSelection();

      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== undefined) {
      const textRange = document.body.createTextRange();

      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.moveEnd(char, pos);
      textRange.moveStart(char, pos);
      textRange.select();
      el.focus();
    }
  }

  attachReplyAutor() {
    const { attachMsgAuthor } = this.props;
    let body = '';

    if (this.state.commentBody && attachMsgAuthor) {
      const posAuthor = this.state.commentBody.trim().search(`^${attachMsgAuthor}`);

      if (posAuthor === -1) {
        body = `${attachMsgAuthor} `;
      } else {
        body = this.state.commentBody;
      }
    } else if (this.state.commentBody && !attachMsgAuthor) {
      body = this.state.commentBody;
    } else if (!this.state.commentBody && attachMsgAuthor) {
      body = `${attachMsgAuthor} `;
    }

    return body;
  }

  render() {
    const { active, replyIdMsg } = this.props;
    const keyReplyInput = `reply${active}`;
    let panelSmsEmail, buttonSend, placeholder;

    if (gon.current_user_role === 'admin') {
      panelSmsEmail = (
        <div data-click='true'>
          <label>
            <input
              id='sms'
              type='checkbox'
              onChange={this.handleChecked}
              checked={this.state.isCheckedSms}
            />
            SMS
          </label>
          <label>
            <input
              id='email'
              type='checkbox'
              onChange={this.handleChecked}
              checked={this.state.isCheckedEmail}
            />
            Email
          </label>
        </div>
      );
    }

    if (this.state.isVisibleButtonSend) {
      buttonSend = (
        <div className='chat-msgs-footer__send-container'>
          <a
            className='chat-msgs-footer__send-link'
            onClick={this.handleSubmitComment}
          >
            Отправить
          </a>
        </div>
      );
    } else {
      buttonSend = '';
    }

    if (this.state.isVisiblePlaceholder) {
      placeholder = (
        <div className='chat-msgs-footer__textbox-placeholder'>
          Введите сообщение...
        </div>
      );
    } else {
      placeholder = '';
    }

    return (
      <form
        className='chat__msgs-footer chat-msgs-footer'
        id={`form-channel-person${active}`}
        onClick={this.handleFormClick}
        data-click='true'
      >
        <div className='chat-msgs-footer__inner' data-click='true'>
          <div className='chat-msgs-footer__textbox-container' data-click='true'>
            <div
              className='chat-msgs-footer__textbox'
              suppressContentEditableWarning='true'
              contentEditable='true'
              tabIndex='1'
              role='textbox'
              aria-multiline='true'
              aria-haspopup='true'
              autoCorrect='off'
              autoComplete='off'
              spellCheck='true'
              placeholder='Введите сообщение...'
              id='comment_body'
              name='comment[body]'
              onKeyPress={this.handleKeyPress}
              onInput={this.handleChange}
              ref={(refTextContainer) => this.textContainer = refTextContainer}
            >
              {this.attachReplyAutor()}
            </div>
            {placeholder}
          </div>
          <div className='chat-msgs-footer__actions' data-click='true'>
            <div className='chat-msgs-footer__admin-actions'>
              {panelSmsEmail}
            </div>
            {buttonSend}
          </div>
        </div>
      </form>
    );
  }
}
