import * as React from 'react';
import PropTypes from 'prop-types';

import { differenceInDays } from 'date-fns';

import * as styles from './DayNightDifferenceShow.module.scss';

const DayNightDifferenceShow = ({ startDate, finishDate }) => {
  const days = differenceInDays(finishDate, startDate);

  return (
    <div className={styles.DayNightDifferenceShow}>
      {`${days + 1} дней, ${days} ночей`}
    </div>
  );
};

DayNightDifferenceShow.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  finishDate: PropTypes.instanceOf(Date).isRequired
};

export default DayNightDifferenceShow;
