export default function showMoreFaqBlock(e) {
  e.preventDefault();

  const link = e.currentTarget;
  const showTags = document.querySelectorAll(`#show-more_${e.currentTarget.dataset.blockId}`);

  if (showTags.length > 0) {
    showTags.forEach((item) => {
      item.classList.remove('hide');
    });

    link.classList.add('hide');
  }
}
