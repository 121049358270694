import {
  ADULTS_PLUS,
  ADULTS_MINUS,
  CHILDS_PLUS,
  CHILDS_MINUS,
  KIDS_AGES_PLUS,
  KIDS_AGES_MINUS,
  MEMBERS_PARAMS,
  INIT_MEMBERS,
  CURRENT_MEMBERS,
  PREVIOUS_MEMBERS
} from '../actions/members';

const initialState = {
  adultsCount: 1,
  minAdultsCount: 1,
  childsCount: 0,
  maxChildsAge: 17,
  minChildsCount: 0,
  childs: {},
  isOpenMembers: false,
  subOfferGroups: [],
  subOffers: {},
  apartmentPrograms: {},
  age_data: {},
  single_choice_products: {},
  singleChoiceProductPrices: {},
  currentAdultsCount: 1,
  currentChildsCount: 0,
  currentChilds: {}
};

const actionsMap = {
  [ADULTS_PLUS]: (state) => {
    return {
      ...state,
      adultsCount: state.adultsCount + 1
    };
  },

  [ADULTS_MINUS]: (state) => {
    return {
      ...state,
      adultsCount: state.adultsCount - 1
    };
  },

  [CHILDS_PLUS]: (state) => {
    return {
      ...state,
      childsCount: state.childsCount + 1
    };
  },

  [CHILDS_MINUS]: (state) => {
    return {
      ...state,
      childsCount: state.childsCount - 1
    };
  },

  [KIDS_AGES_PLUS]: (state, action) => {
    const { payload } = action;
    const newChilds = state.childs;

    newChilds[payload.index] = payload.age;

    return {
      ...state,
      childs: newChilds
    };
  },

  [KIDS_AGES_MINUS]: (state, action) => {
    const { payload } = action;
    const newChilds = state.childs;

    delete newChilds[payload.index];

    return {
      ...state,
      childs: newChilds
    };
  },

  [MEMBERS_PARAMS]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      age_data: payload.age_data,
      single_choice_products: payload.single_choice_products
    };
  },

  [INIT_MEMBERS]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      adultsCount: payload.adultsCount,
      currentAdultsCount: payload.currentAdultsCount,
      age_data: payload.age_data,
      single_choice_products: payload.single_choice_products
    };
  },

  [CURRENT_MEMBERS]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      currentAdultsCount: payload.currentAdultsCount,
      currentChildsCount: payload.currentChildsCount,
      currentChilds: payload.currentChilds
    };
  },

  [PREVIOUS_MEMBERS]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      adultsCount: payload.adultsCount,
      childsCount: payload.childsCount,
      childs: payload.childs
    };
  }
};

export default function membersReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
