import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from '../dev/logger';
import rootReducer from '../reducers';
import socketMiddleware from '../middleware/socketMiddleware';

const isProduction = process.env.NODE_ENV === 'production';
// const isProduction = false;

let store = null;

if (isProduction) {
  // In production adding only thunk middleware
  const middleware = applyMiddleware(thunk, socketMiddleware);

  store = createStore(
    rootReducer,
    middleware
  );
} else {
  // In development mode beside thunk
  // logger and DevTools are added
  const middleware = applyMiddleware(thunk, logger, socketMiddleware);
  // const middleware = applyMiddleware(thunk, logger);
  let enhancer;

  // Enable DevTools if browser extension is installed
  if (window.__REDUX_DEVTOOLS_EXTENSION__) { // eslint-disable-line
    enhancer = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    );
  } else {
    enhancer = compose(middleware);
  }

  store = createStore(
    rootReducer,
    enhancer
  );
}

// const createStoreWithMiddleware = applyMiddleware(
//   thunk
// )(createStore);

export default function configureStore() {
  // return createStoreWithMiddleware(rootReducer, initialState);
  return store;
}
