export default function showEvent(e) {
  e.preventDefault();

  const elemClassName = `js-events__item-tag-${e.currentTarget.getAttribute('data-tag')}`;
  const content = document.querySelector(`.${elemClassName}`);
  const allEvents = document.querySelectorAll('.events__item');
  const showItems = [];
  const hideItems = [];
  const rootEventNode = document.querySelector('.events__list');

  if (content === null) return;

  allEvents.forEach((item) => {
    if (item.classList.contains(elemClassName)) {
      item.style.display = '';
      showItems.push(item);
    } else {
      item.style.display = 'none';
      hideItems.push(item);
    }
    item.remove();
  });

  showItems.forEach((item) => {
    rootEventNode.append(item);
  });

  hideItems.forEach((item) => {
    rootEventNode.append(item);
  });
}
