import moment from 'moment';

export const INIT_OPTION_LIST = 'INIT_OPTION_LIST';

function initOptionListAction(data) {
  return {
    type: INIT_OPTION_LIST,
    payload: data
  };
}

export function initOptionList() {
  return (dispatch, getState) => {
    const { singleChoicePrograms, singleChoiceProductPrices, recommendedFirstDate, recommendedLastDate } = getState().offer;
    const { startDate, finishDate } = getState().offerDates;

    const keyPeriod = `${moment(startDate || recommendedFirstDate).format('YYYY-MM-DD')}-${moment(finishDate || recommendedLastDate).format('YYYY-MM-DD')}`;

    const optionIds = Object.keys(singleChoicePrograms).reduce((acc, elem) => {
      if (Object.values(singleChoicePrograms[elem].products).filter(p => p.recommended && (p.price[keyPeriod] && p.price[keyPeriod][p.id]?.gross > -1)).length > 0) {
        acc[elem] = Object.values(singleChoicePrograms[elem].products).filter(p => p.recommended)[0].id;
      }
      return acc;
    }, {});
    const data = { selectedOptionIds: optionIds, optionPrices: Object.assign({}, singleChoiceProductPrices) };

    dispatch(initOptionListAction(data));
  };
}
