import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingHotel from '../components/booking_hotel';
import * as BookingHotelActions from '../actions/booking_hotel';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BookingHotelActions, dispatch);
}
const BookingHotelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingHotel);

export default BookingHotelContainer;
