import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdultsCounter from '../components/AdultsCounter';
import * as MembersActions from '../actions/members';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(MembersActions, dispatch);
}
const AdultsCounterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdultsCounter);

export default AdultsCounterContainer;
