import React from 'react';
import { AuthProvider } from '../../../../../hooks/use-auth';
import SiteHeader from '../SiteHeader';

const AuthSiteHeader = () => (
  <AuthProvider>
    <SiteHeader />
  </AuthProvider>
);

export default AuthSiteHeader;
