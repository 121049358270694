export default function openPartnerDialog(e) {
  e.preventDefault();

  const partnerItem = document.querySelector('.js-contacts__item-producer');
  const clonePartnerItem = partnerItem.cloneNode(true);
  const modalDialog = document.querySelector('.js-partners__modal-dialog');
  const dialogContent = document.querySelector('.js-partners__modal-dialog-content');
  const btnClose = document.querySelector('.js-partners__modal-dialog-close');

  modalDialog.style.display = 'block';

  dialogContent.querySelectorAll('*').forEach(n => n.remove());
  dialogContent.append(clonePartnerItem);


  btnClose.onclick = () => {
    modalDialog.style.display = 'none';
  };

  window.onclick = (event) => {
    if (event.target === modalDialog) {
      modalDialog.style.display = 'none';
    }
  };
}
