import React, { PureComponent } from 'react';

export default class Notes extends PureComponent {
  alertNotes() {
    const { offer, members, offerDates } = this.props;
    const notes = [];

    // if (offer.maxAdultsCount <= members.adultsCount) {
    //   notes.push('Достигнуто макс. кол-во взрослых в одном номере. Если вас едет больше, то просто оформите несколько заказов!');
    //   notes.push(<br key='b_alert1' />);
    // }

    // if (offer.maxChildsCount <= members.childsCount && offer.maxChildsCount !== 0) {
    //   notes.push('Достигнуто макс. кол-во детей в одном номере. Если вас едет больше, то просто оформите несколько заказов!');
    //   notes.push(<br key='b_alert2' />);
    // }

    if (!offerDates.startDate || !offerDates.finishDate) {
      notes.push('Не задана дата заезда или выезда');
      notes.push(<br key='b_alert3' />);
    }

    if (members.childsCount > 0
      && Object.values(members.childs).filter(e => e === -1).length !== 0) {
      notes.push('Возраст ребёнка обязателен');
      notes.push(<br key='b_alert4' />);
    }

    return notes;
  }


  render() {
    const note = this.alertNotes();

    if (note.length === 0) return '';

    return (
      <div className='booking__selectbox-note'>
        {note}
      </div>
    );
  }
}
