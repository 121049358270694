import React, { PureComponent } from 'react';

export default class ShowMoreButton extends PureComponent {
  constructor(props) {
    super(props);

    // this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
  }

  handleShowMoreClick(hotelId) {
    const { showMoreRooms } = this.props;

    showMoreRooms(hotelId);
  }

  render() {
    const { hotel, qntRooms } = this.props;

    return (
      <div
        className='booking__show-more-btn-wrap'
      >
        <a
          className='btn btn_gradient_purple booking__show-more-btn'
          onClick={this.handleShowMoreClick.bind(this, hotel.id)}
        >
          {`все ${qntRooms} вариантов в ${hotel.name}`}
        </a>
      </div>
    );
  }
}
