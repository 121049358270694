import React, { PureComponent } from 'react';
import BookingSelectboxDropdown from './BookingSelectboxDropdown';
import Popup from '../../components/Popup';

export default class InterestCategories extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedInterestIds: [],
      initSelectedInterestIds: []
    };

    this.handleSelectedInput = this.handleSelectedInput.bind(this);
    this.handleClearSelectedInput = this.handleClearSelectedInput.bind(this);
    this.handleSelectedAllInput = this.handleSelectedAllInput.bind(this);
    this.handleSelectBoxClick = this.handleSelectBoxClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    const { initFilterInterest } = this.props;

    initFilterInterest();
    this.initStateSelectedInterestIds();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.entries(props.interestCategoryIds || []).length === 0) return null;
    // if (Object.entries(state.initSelectedInterestIds).length === 0) return null;

    if (props.interestCategoryIds.filter(e => !state.initSelectedInterestIds.includes(e)).length > 0) {
      return {
        selectedInterestIds: props.interestCategoryIds,
        initSelectedInterestIds: props.interestCategoryIds
      };
    }

    return null;
  }

  initStateSelectedInterestIds() {
    const { offer, members } = this.props;
    const parent = Object.entries(members.interestCategories).length > 0 ? members : offer;

    this.setState(() => ({
      selectedInterestIds: Object.keys(parent.interestCategories).map(elem => parseInt(elem, 10)),
      initSelectedInterestIds: Object.keys(parent.interestCategories).map(elem => parseInt(elem, 10))
    }));
  }

  handleSelectBoxClick() {
    const { isOpenFilterInterest, openFilterInterest, closeFilterInterest, interestCategories } = this.props;

    if (isOpenFilterInterest) {
      this.setState(() => ({
        selectedInterestIds: Object.assign([], interestCategories.selectedInterestIds)
      }));
      closeFilterInterest();
    } else {
      openFilterInterest();
    }
  }

  handleSelectedInput(event) {
    const { selectedInterestIds } = this.state;
    const input = event.target;
    const interestId = parseInt(input.value, 10);
    const newSelectedInterestIds = (input.checked)
      ? [...new Set([...selectedInterestIds, ...[interestId]])]
      : selectedInterestIds.filter(elem => elem !== interestId);

    this.setState(() => ({
      selectedInterestIds: newSelectedInterestIds
    }));
  }

  handleClearSelectedInput() {
    this.setState(() => ({
      selectedInterestIds: []
    }));
  }

  handleSelectedAllInput() {
    this.initStateSelectedInterestIds();
  }

  handleSaveClick() {
    const { selectedInterestIds } = this.state;
    const { closeFilterInterest, selectedInterest } = this.props;

    selectedInterest(selectedInterestIds);
    closeFilterInterest();
  }

  viewInterest(interest) {
    const { offer, members, filterHotels, filterFoods } = this.props;
    const parent = Object.entries(members.apartmentPrograms).length > 0 ? members : offer;
    let hotels = Object.entries(parent.apartmentPrograms).map(([id, program]) => program.hotels)[0];
    hotels = Object.keys(parent.hotels).map(k => parseInt(k, 10)).filter(f => filterHotels.selectedHotelIds.includes(f)).map(h => parent.hotels[h]);
    hotels = Object.values(hotels).filter(hotel => hotel.foodTypeCode.some(v => filterFoods.selectedFoodIds.includes(v)));

    return [...new Set(hotels.map(h => h.interestCategoriesIds).reduce((acc = [], el) => acc.concat(el), []))].includes(interest.id);
  }

  render() {
    const { selectedInterestIds } = this.state;
    const { offer, members, isOpenFilterInterest } = this.props;
    const parent = Object.entries(members.interestCategories).length > 0 ? members : offer;
    const interestCategories = Object.values(parent.interestCategories).filter(interest => this.viewInterest(interest)).reduce((acc = {}, interest) => { acc[interest.id] = interest; return acc; }, {});

    return (
      <div className='booking__select-item booking__select-item-small'>
        <div className='booking__select-item-content'>
          <div
            className={`booking__selectbox ${isOpenFilterInterest ? 'booking__selectbox--open' : ''}`}
          >
            <div
              className='booking__selectbox-head'
              onClick={this.handleSelectBoxClick}
            >
              <div className='booking__selectbox-head-text'>Интересы</div>
              <div className='booking__selectbox-filter'>фильтр</div>
            </div>
          </div>
        </div>
        <Popup show={isOpenFilterInterest} onClose={this.handleSelectBoxClick} variant='small' withHeader={false}>
          <BookingSelectboxDropdown
            handleInputChange={this.handleSelectedInput}
            listTitle='Интересы'
            nodes={interestCategories}
            prefix='interest'
            checkedItems={selectedInterestIds}
            onClick={this.handleSaveClick}
            onClearClick={this.handleClearSelectedInput}
            onSelectAllClick={this.handleSelectedAllInput}
            disabledSave={selectedInterestIds.length === 0}
          />
        </Popup>
      </div>
    );
  }
}
