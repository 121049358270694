import * as React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox';
import DatePeriodShow from '../DatePeriodShow';
import DayNightDifferenceShow from '../DayNightDifferenceShow';

import * as styles from './PeriodsList.module.scss';

import Action from '../Action';
import usePopup from '../../../../hooks/use-popup';
import Popup from '../Popup';
import RecallForm, { CALLBACK_TYPE } from '../ContactVariants/RecallForm';
import useAuth from '../../../../hooks/use-auth';

const PeriodsList = ({
  periodsByMonth,
  minPriceByPeriod,
  periods,
  selectedPeriod,
  offer,
  onClick
}) => {
  const [isRecallFormOpen, openRecallForm, closeRecallForm] = usePopup();
  const { user } = useAuth();
  const hasMonthHeader = Object.keys(periodsByMonth).length > 1;

  return (
    <div className={styles.PeriodsList}>
      {
        Object.keys(periodsByMonth).map(monthDate => (
          <React.Fragment key={monthDate}>
            {hasMonthHeader && (
              <h4 className={styles.PeriodsList__monthHeader}>
                {monthDate}
              </h4>
            )}
            <div className={styles.PeriodsList__monthWrap}>
              {
                periodsByMonth[monthDate].map(period => (
                  <div className={styles.PeriodsList__periodRow} key={period.id}>
                    <div>
                      <DatePeriodShow
                        startDate={Date.parse(periods[period].startDate)}
                        finishDate={Date.parse(periods[period].finishDate)}
                      />
                      <DayNightDifferenceShow
                        startDate={Date.parse(periods[period].startDate)}
                        finishDate={Date.parse(periods[period].finishDate)}
                      />
                    </div>
                    <div className={styles.PeriodsList__wrapCheck}>
                      <div className={styles.PeriodsList__minPrice}>
                        {minPriceByPeriod[period] && parseInt(minPriceByPeriod[period]?.costCurrentAll, 10) > 0 && (
                          <div className={styles.PeriodsList__discount}>
                            {`${minPriceByPeriod[period]?.costCurrentAll} ${minPriceByPeriod[period]?.currencySign}`}
                          </div>
                        )}
                        {minPriceByPeriod[period] && (
                          `${minPriceByPeriod[period]?.coastAll} ${minPriceByPeriod[period]?.currencySign}`
                        )}
                      </div>
                      <Checkbox
                        id={`${periods[period].id}`}
                        name='period-select'
                        likeButton
                        label={selectedPeriod.startDate === periods[period].startDate && selectedPeriod.finishDate === periods[period].finishDate ? 'Выбрано' : 'Выбрать'}
                        onClick={e => onClick(e, period)}
                        type='radio'
                        checked={selectedPeriod.startDate === periods[period].startDate && selectedPeriod.finishDate === periods[period].finishDate}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </React.Fragment>
        ))
      }
      {offer.other_period && (
        <>
          <Action
            to='/'
            onClick={openRecallForm}
            themes={['Button', 'ButtonSecondary', 'Fullwidth']}
            className={styles.PeriodsList__button}
          >
            <span>Хочу другой период, свяжитесь со мной</span>
          </Action>
          <Popup
            show={isRecallFormOpen}
            onClose={closeRecallForm}
            escapeClose={false}
            outsideClose={false}
            variant='small'
          >
            <RecallForm
              onClose={closeRecallForm}
              recallPhone={user.phone}
              recallName={user.name}
              callBackType={CALLBACK_TYPE.changePeriod}
              recallDescription={`${offer.spot}, другой период`}
            />
          </Popup>
        </>
      )}
    </div>
  );
};

PeriodsList.propTypes = {
  periodsByMonth: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  minPriceByPeriod: PropTypes.shape({
    key: PropTypes.shape({
      costCurrentAll: PropTypes.string,
      currencySign: PropTypes.string
    })
  }).isRequired,
  periods: PropTypes.shape({
    key: PropTypes.shape({
      startDate: PropTypes.string,
      finishDate: PropTypes.string,
      duration: PropTypes.string,
      position: PropTypes.number
    })
  }).isRequired,
  selectedPeriod: PropTypes.shape({
    startDate: PropTypes.string,
    finishDate: PropTypes.string
  }).isRequired,
  offer: PropTypes.shape({
    other_period: PropTypes.bool,
    spot: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default PeriodsList;
