import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Action from '../Action/Action';
// import lightenDarkenColor from '../../../../helpers/lightenDarkenColor';

import * as styles from './BonusCard.module.scss';

const BonusCard = ({ bonus, compactVariant, btnInFooter }) => {
  const imageSrc = useRef(null);
  // const offerBrandColor = bonus.offer_colors.btn_main_photo_color.hex;
  // const offerDarkenBrandColor = lightenDarkenColor(offerBrandColor, -35);
  // const buttonBackground = `linear-gradient(180deg, ${offerBrandColor} 0%, ${offerDarkenBrandColor} 100%)`;
  const buttonBackground = 'var(--color-yellow-1)';

  const getBlobImage = async (url) => {
    if (compactVariant) return;

    try {
      await fetch(url, {
        method: 'GET'
      })
        .then(response => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          if (!blobUrl) return;

          imageSrc.current.src = blobUrl;
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlobImage(bonus.image);
  }, []);

  return (
    <div className={styles.BonusCard}>
      <div className={styles.BonusCard__image}>
        <img
          ref={imageSrc}
          alt=''
          className={styles.BonusCard__coverImage}
        />
      </div>
      <div className={styles.BonusCard__name}>
        {bonus.title}
      </div>
      <div
        className={styles.BonusCard__html}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: bonus.description }}
      />
      {(bonus.text_on_btn && !btnInFooter) && (
        <div className={styles.BonusCard__priceWrap}>
          <Action
            href={bonus.link_by_btn}
            themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
            style={{ background: buttonBackground }}
            target='__blank'
          >
            {bonus.text_on_btn}
          </Action>
        </div>
      )}
    </div>
  );
};

BonusCard.propTypes = {
  bonus: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  compactVariant: PropTypes.bool,
  btnInFooter: PropTypes.bool
};

BonusCard.defaultProps = {
  compactVariant: false,
  btnInFooter: false
};

export default BonusCard;
