import moment from 'moment';
import React, { PureComponent } from 'react';
import BookingRoomsItemContainer from '../containers/booking_rooms_item';
import ShowMoreContainer from '../containers/show_more';

export default class BookingRooms extends PureComponent {
  roomFilter(room, hotelFoodTypeCode) {
    if (!room) return false;

    const { filterFoods } = this.props;
    const includeRoom = filterFoods.selectedFoodIds.includes(parseInt(room.foodTypeCode, 10))
      && hotelFoodTypeCode.includes(parseInt(room.foodTypeCode, 10))
      && room.price > 0;

    return includeRoom;
  }

  bookingRoomsItems(hotel) {
    const { offerDates } = this.props;
    const keyPeriod = `${moment(offerDates.startDate).format('YYYY-MM-DD')}-${moment(offerDates.finishDate).format('YYYY-MM-DD')}`;
    const arrBookingRoomsItem = hotel.rooms.filter(room => { return this.roomFilter(room[keyPeriod], hotel.foodTypeCode); }).map(room => {
      return (
        <BookingRoomsItemContainer
          key={`booking_rooms_item_${room[keyPeriod].id}_${room[keyPeriod].foodTypeCode}`}
          room={room[keyPeriod]}
        />
      );
    });

    return arrBookingRoomsItem;
  }

  render() {
    const { hotel, hotelItems } = this.props;
    const bookingRoomsItems = Object.assign([], this.bookingRoomsItems(hotel));
    const qntRooms = bookingRoomsItems.length;
    const showOverLimit = (!hotelItems.showRoomIds.includes(hotel.id) && qntRooms > 3);
    const showMoreButton = (showOverLimit ? <ShowMoreContainer hotel={hotel} qntRooms={qntRooms} /> : '');
    const showRooms = showOverLimit ? bookingRoomsItems.slice(0, 3) : bookingRoomsItems;

    return (
      <div className={`booking__rooms ${showOverLimit ? 'booking__rooms-over-limit' : ''}`}>
        <div className='booking__rooms-content'>
          <div className='booking__rooms-list'>
            {showRooms}
          </div>
          {showMoreButton}
        </div>
      </div>
    );
  }
}
