import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FaqCard from '../FaqCard';
import BonusesList from '../../../../components/BonusesList/BonusesList';
import { fetchFaqOfferData } from '../../../../../../services/base';

import * as styles from './FaqList.module.scss';

const FaqList = ({ offerId, bonuses }) => {
  const [faqData, setFaqData] = useState([]);

  const cardItems = faqData.map(item => (
    <li key={item.title}>
      <FaqCard
        question={item.title}
        blocks={item.blocks}
        icon={item.icon}
      />
    </li>
  ));

  useEffect(() => {
    fetchFaqOfferData(offerId, (result) => {
      setFaqData(result.faq);
    });
  }, []);

  return (
    <ul className={styles.FaqList}>
      {bonuses.length > 0 && (
        <li>
          <BonusesList bonuses={bonuses} bonusesBackground='yellow' />
        </li>
      )}
      {cardItems}
    </ul>
  );
};

FaqList.propTypes = {
  offerId: PropTypes.number.isRequired,
  bonuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired
};

export default FaqList;
