import React, { PureComponent, Fragment } from 'react';
import BookingOptionsItemContainer from '../containers/booking_options_item';

export default class BookingOptionsList extends PureComponent {
  componentDidMount() {
    const { initOptionList } = this.props;

    initOptionList();
  }

  renderBookingOptions() {
    const { offer } = this.props;

    const arrBookingOptions = Object.entries(offer.singleChoicePrograms).map(([id, program]) => {
      return (
        <Fragment key={`singleChoicePrograms_${id}`}>
          <BookingOptionsItemContainer
            programName={program.name}
            singleChoiceProgramsItems={program.products}
            isMandatoryChoice={program.isMandatoryChoice}
          />
        </Fragment>
      );
    });

    return arrBookingOptions;
  }

  render() {
    return this.renderBookingOptions();
  }
}
