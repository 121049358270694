/* JS */
import '../src/client/index-client-app';

/* CSS */
import '../src/client/stylesheets/client-app.scss';

const importAll = (r) => { r.keys().map(r); };

importAll(require.context('./../icons/', false, /\.svg$/));
importAll(require.context('./../images/', false, /\.(png|jpe?g)$/));
