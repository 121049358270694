import React, { PureComponent } from 'react';
import BookingDescriptionContainer from '../containers/booking_description';
// import { ReachGoal } from '../../YandexMetrika';
// import { Ga } from '../../GoogleAnalytics';
// import { Goal } from '../../Vk';

import MealIcon from '../../../../../icons/facilities/meal2.svg';
import NotMealIcon from '../../../../../icons/facilities/not-meal2.svg';
import OtherMealIcon from '../../../../../icons/facilities/other-meal2.svg';
import SquareIcon from '../../../../../icons/facilities/square.svg';
import SpecialRoomIcon from '../../../../../icons/special_room1.svg';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import Checkbox from '../../components/Checkbox';

export default class BookingRoomsItem extends PureComponent {
  constructor(props) {
    super(props);

    this.handleInfoClick = this.handleInfoClick.bind(this);
  }

  handleInfoClick(roomId, roomFoodTypeCode) {
    const { openDescriptionRoomItem } = this.props;

    openDescriptionRoomItem(roomId, roomFoodTypeCode);
  }

  handleSelectClick(roomId, room) {
    const { selectedRoomItem, removeSelectedRoomItem, offer } = this.props;

    // ReachGoal(`${offer.slug}_choose`, { offer: offer.name });
    // Ga(`${offer.slug}_choose`);
    // Goal(`${offer.slug}_choose`);

    if (roomId === null) removeSelectedRoomItem();
    else selectedRoomItem(roomId, room);
  }

  isSelectedRoom() {
    const { room, roomItems } = this.props;

    return room.id === roomItems.selectedRoomId
      && room.foodTypeCode === roomItems.selectedRoom.foodTypeCode;
  }

  renderFoodTypeImageSrc() {
    const { room } = this.props;
    let icon;

    if (room.foodTypeCode === 0) icon = NotMealIcon;
    else if (room.foodTypeCode === 20) icon = MealIcon;
    else icon = OtherMealIcon;

    return (
      <IconSvg icon={icon} className='booking__rooms-item-info-food-img' />
    );
  }

  renderRoomSquare() {
    const { room } = this.props;

    return (
      <>
        {room.square && (
          <div className='booking__rooms-item-info-attributes-item booking__rooms-item-info-area'>
            <IconSvg icon={SquareIcon} className='booking__rooms-item-info-meters-img' />
            {`${room.square} М`}
            <sup>2</sup>
          </div>
        )}
        {room.special && (
          <div className='booking__rooms-item-info-attributes-item booking__rooms-item-info-area'>
            <IconSvg icon={SpecialRoomIcon} className='booking__rooms-item-info-meters-img' />
            <div>
              Особый
            </div>
          </div>
        )}
      </>
    );
  }

  renderRoomDescription() {
    const { offer, room, roomItems } = this.props;

    // if (!roomItems.descriptionRoomsIds.includes(room.apartmentId)) return '';
    if (Object.entries(roomItems.descriptionRoomsIds).filter(([id, foodCode]) => parseInt(id, 10) === parseInt(room.apartmentId, 10) && parseInt(foodCode, 10) === parseInt(room.foodTypeCode, 10)).length === 0) return '';

    const isPreviousSearchHotels = roomItems.previousApartments ? Object.entries(roomItems?.previousApartments).length !== 0 : false;
    const isCurrentSearchHotels = Object.entries(roomItems.apartments).length !== 0;
    const isInitHotels = Object.entries(roomItems.apartments).length === 0
      && !isPreviousSearchHotels;

    let apartments = {};
    if (isInitHotels) apartments = offer.apartments;
    else if (isCurrentSearchHotels) apartments = roomItems.apartments;
    else if (!isCurrentSearchHotels && isPreviousSearchHotels) apartments = roomItems.previousApartments;

    // const { scrollPosition } = this.state;
    // const apartments = Object.entries(roomItems.apartments).length === 0
    //   ? offer.apartments
    //   : roomItems.apartments;

    return (
      <BookingDescriptionContainer
        key={`booking_description_${room.apartmentId}`}
        element={apartments[room.apartmentId]}
        room={room}
        isRoom
      />
    );
  }

  renderButton() {
    const { room, roomItems } = this.props;
    const selectedRoom = this.isSelectedRoom();

    const buttonUnChecked = (
      <>
        <div className='booking__rooms-item-btn-text __desktop'>Выбрать&nbsp;</div>
        <div
          className='booking__rooms-item-btn-text __mobile'
        >
          Выбрать за&nbsp;
          {` ${room.coastAll} ${room.currencySign}`}
        </div>
      </>
    );

    const buttonChecked = (
      <>
        <div className='booking__rooms-item-btn-text __desktop'>Выбрано&nbsp;</div>
        <div
          className='booking__rooms-item-btn-text __mobile'
        >
          Выбрано за&nbsp;
          {` ${room.coastAll} ${room.currencySign}`}
        </div>
      </>
    );

    return (
      <Checkbox
        id={`id-room-select-${room.id}`}
        name={`room-select-${room.id}`}
        className='booking__rooms-item-checkbox-btn'
        likeButton
        label={selectedRoom ? buttonChecked : buttonUnChecked}
        onClick={selectedRoom ? this.handleSelectClick.bind(this, null, null) : this.handleSelectClick.bind(this, room.id, room)}
        type='radio'
        checked={selectedRoom}
      />
    );
  }

  render() {
    const { room, roomItems } = this.props;
    // const selectedRoom = this.isSelectedRoom() || roomItems.descriptionRoomsIds.includes(room.apartmentId);
    const selectedRoom = this.isSelectedRoom()
    || Object.entries(roomItems.descriptionRoomsIds).filter(([id, foodCode]) => parseInt(id, 10) === parseInt(room.apartmentId, 10) && parseInt(foodCode, 10) === parseInt(room.foodTypeCode, 10)).length > 0;

    return (
      <>
        {room.pre_order_description && (
          <div className='booking__rooms-pre-order-description'>
            <span>
              {room.pre_order_description}
            </span>
          </div>
        )}
        <div className={`booking__rooms-item ${(selectedRoom) ? 'state-selected' : ''}`}>
          <div className='booking__rooms-item-info'>
            <div className='booking__rooms-item-info-description'>{room.name}</div>
            <div className='booking__rooms-item-info-attributes'>
              <div className='booking__rooms-item-info-attributes-item booking__rooms-item-info-food'>
                {/* <img
                  className='booking__rooms-item-info-food-img'
                  src={this.renderFoodTypeImageSrc()}
                /> */}
                {this.renderFoodTypeImageSrc()}
                {room.foodType}
              </div>
              {this.renderRoomSquare()}
            </div>
            <a
              className='booking__rooms-item-info-attributes-item booking__info-link'
              onClick={this.handleInfoClick.bind(this, room.apartmentId, room.foodTypeCode)}
            >
              фото и описание НОМЕРА
            </a>
          </div>
          <div className='booking__rooms-item-price-wrap'>
            {room.discountDate && (
              <div className='booking__rooms-item-discount'>
                <div className='booking__rooms-item-discount-figure'>
                  {`${room.costCurrentAll} ${room.currencySign}`}
                </div>
                <div className='booking__rooms-item-discount-date is-large'>
                  {`скидка до ${room.discountDate}`}
                </div>
              </div>
            )}
            {(!room.discountDate && room.discountForRegularCustomer !== 0) && (
              <div className='booking__rooms-item-discount'>
                <div className='booking__rooms-item-discount-figure'>
                  {`${room.priceForRegularCustomer} ${room.currencySign}`}
                </div>
              </div>
            )}
            <div className='booking__rooms-item-price'>
              <div className='booking__rooms-item-cost'>
                {room.coastAll}
              </div>
              <div className='booking__rooms-item-currency'>{room.currencySign}</div>
            </div>

            {room.discountDate && (
              <div className='booking__rooms-item-discount-date is-medium'>
                <div className='booking__rooms-item-discount-figure'>
                  {` ${room.costCurrentAll} ${room.currencySign}`}
                </div>
                <span>
                  {`скидка до ${room.discountDate}`}
                </span>
              </div>
            )}
            {(!room.discountDate && room.discountForRegularCustomer !== 0) && (
              <div className='booking__rooms-item-discount-date is-medium'>
                <div className='booking__rooms-item-discount-figure'>
                  {` ${room.priceForRegularCustomer} ${room.currencySign}`}
                </div>
              </div>
            )}
            <div className='booking__rooms-item-btn-wrap'>
              {this.renderButton()}
            </div>
          </div>
          {this.renderRoomDescription()}
        </div>
      </>
    );
  }
}
