import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import BookingContainer from './booking';
import configureStore from '../store/configureStore';
import { initBooking } from '../actions/booking';

const store = configureStore();

export default class Booking extends PureComponent {
  componentWillMount() {
    store.dispatch(initBooking(this.props));
  }

  render() {
    return (
      <Provider store={store}>
        <BookingContainer />
      </Provider>
    );
  }
}
