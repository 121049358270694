import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FilterHotels from '../components/filter_hotels';
import * as FilterHotelsActions from '../actions/filter_hotels';
import * as SharedActions from '../actions/shared';

function mapStateToProps(state) {
  return {
    ...state,
    isOpenMembers: state.members.isOpenMembers,
    isOpenFromDay: state.offerDates.isOpenFromDay,
    isOpenToDay: state.offerDates.isOpenToDay,
    isOpenFilterInterest: state.interestCategories.isOpenFilterInterest,
    isOpenFilterHotel: state.filterHotels.isOpenFilterHotel,
    isOpenFilterFood: state.filterFoods.isOpenFilterFood
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FilterHotelsActions, ...SharedActions }, dispatch);
}
const FilterHotelsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterHotels);

export default FilterHotelsContainer;
