/* eslint-disable import/no-unresolved */
import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useSpring, animated } from 'react-spring';
import cn from 'classnames';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';

import DownCircleIcon from '../../../../../../../icons/down-circle.svg';
import BonusesIcon from '../../../../../../../icons/bonuses.svg';
import Action from '../../../../components/Action';
import Popup from '../../../../components/Popup';
import usePopup from '../../../../../../hooks/use-popup';

import styles from './FaqCard.module.scss';

const IconItem = {
  'bonuses': BonusesIcon
};

const FaqCard = ({ question, blocks, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [answerHeight, setAnswerHeight] = useState('0px');
  const answerElem = useRef();
  const [qaBlock, SetQaBlock] = useState(null);
  const [isFormOpen, openForm, closeForm] = usePopup();

  useLayoutEffect(() => {
    if (question) setAnswerHeight(answerElem.current.clientHeight);
  }, []);

  const expand = useSpring({
    height: isOpen ? `${answerHeight}px` : '8px'
  });

  const handleClickQuestion = (block) => {
    console.log(block);
    SetQaBlock(block);
    openForm();
  };

  const blockOffer = blocks.map((block) => {
    if (question) return null;

    return (
      <li key={`blockItem_${block.position}`}>
        {!block?.isLink && (
          <Action
            className={styles.FaqCard__link}
            to='/'
            onClick={() => handleClickQuestion(block)}
          >
            <div
              className={styles.FaqCard__html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: block.question }}
            />
          </Action>
        )}
        {block?.isLink && (
          <Action
            className={styles.FaqCard__link}
            target='_blank'
            href={block.answer}
          >
            <div
              className={styles.FaqCard__html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: block.question }}
            />
          </Action>
        )}
        {block?.isButton && (
          <Action
            className={styles.FaqCard__link}
            target='_blank'
            href={block.answer}
          >
            <div
              className={styles.FaqCard__html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: block.question }}
            />
          </Action>
        )}
      </li>
    );
  });

  const blockItem = blocks.map((block) => {
    if (!question) return null;

    return (
      <li key={`blockItem_${block.position}`}>
        <div>
          {block.title}
        </div>
        <Action
          className={styles.FaqCard__link}
          to='/'
          onClick={() => handleClickQuestion(block)}
        >
          <div
            className={styles.FaqCard__accordionLink}
            dangerouslySetInnerHTML={{ __html: block.question }}
          />
        </Action>
      </li>
    );
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {!question && (
        <div className={styles.FaqCard}>
          <div className={styles.FaqCard__questionLinkWrap}>
            <div className={styles.FaqCard__question}>
              {blockOffer}
            </div>
          </div>
        </div>
      )}

      {question && (
        <div className={cn(styles.FaqCard, { [styles.FaqCard_isOpen]: isOpen })}>
          <button
            className={styles.FaqCard__questionWrap}
            onClick={handleClick}
            type='button'
          >
            <div className={styles.FaqCard__question}>
              {icon && (
                <div className={styles.FaqCard__iconTextWrap}>
                  <IconSvg icon={IconItem[icon]} className={styles.FaqCard__questionIcon} />
                  <div>{question}</div>
                </div>
              )}
              {!icon && question}
            </div>
            <IconSvg icon={DownCircleIcon} className={styles.FaqCard__toggleIcon} />
          </button>

          <animated.div className={styles.FaqCard__accordion} style={expand}>
            <div ref={answerElem} className={styles.FaqCard__answer}>
              {blockItem}
            </div>
          </animated.div>
        </div>
      )}

      <Popup show={isFormOpen} onClose={closeForm} withHeader={false} variant='medium'>
        <h3 className={styles.FaqCard__header}>
          {qaBlock?.question}
        </h3>
        <div
          className={styles.FaqCard__html}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: qaBlock?.answer }}

        />
      </Popup>
    </>
  );
};

FaqCard.propTypes = {
  question: PropTypes.string,
  blocks: PropTypes.instanceOf(Array),
  icon: PropTypes.string
};

FaqCard.defaultProps = {
  question: '',
  blocks: [],
  icon: ''
};

export default FaqCard;
