import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingOptionsItem from '../components/BookingOptionsItem';
import * as BookingOptionsItemActions from '../actions/booking_options_item';
import * as SharedActions from '../actions/shared';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingOptionsItemActions, ...SharedActions }, dispatch);
}
const BookingOptionsItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingOptionsItem);

export default BookingOptionsItemContainer;
