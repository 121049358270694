export const INIT_BOOKING = 'INIT_BOOKING';

function setData(data) {
  return {
    type: INIT_BOOKING,
    payload: data
  };
}

export function initBooking(data) {
  return (dispatch) => {
    dispatch(setData(data));
  };
}
