import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import BookingRoomsContainer from '../containers/booking_rooms';
import BookingDescriptionContainer from '../containers/booking_description';
import { sortObject } from '../../../../common/javascript/api';
import Action from '../../components/Action';

export default class BookingHotel extends PureComponent {
  handleInfoClick(hotelId) {
    const { openDescriptionHotel } = this.props;

    openDescriptionHotel(hotelId);
  }

  hotelFilter(hotel) {
    const { filterFoods, interestCategories, filterHotels, offerDates } = this.props;
    const keyPeriod = `${moment(offerDates.startDate).format('YYYY-MM-DD')}-${moment(offerDates.finishDate).format('YYYY-MM-DD')}`;

    return hotel.foodTypeCode.some(v => filterFoods.selectedFoodIds.includes(v))
      && hotel.interestCategoriesIds.some(v => interestCategories.selectedInterestIds.includes(v))
      && filterHotels.selectedHotelIds.includes(hotel.id)
      && hotel.rooms.filter(r => r[keyPeriod]).filter(r => r[keyPeriod].price > 0).length > 0;
  }

  renderBookingRooms(hotel) {
    if (!hotel.rooms) return '';

    return (
      <BookingRoomsContainer key={`hotel_rooms_${hotel.id}`} hotel={hotel} />
    );
  }

  renderHotelDescription(hotel) {
    const { offer, hotelItems } = this.props;

    if (!hotelItems.descriptionHotelIds.includes(hotel.id)) return '';

    return (
      <BookingDescriptionContainer
        key={`booking_description_${hotel.id}`}
        element={offer.hotels[hotel.id]}
        isHotel
      />
    );
  }

  renderBookingHotels() {
    const { hotels, hotelItems } = this.props;
    const sortHotels = sortObject(hotels);
    const arrBookingHotels = Object.entries(sortHotels).map(([id, hotel]) => {
      if (!this.hotelFilter(hotel.value)) return '';

      return (
        <Fragment key={`fhotel_${hotel.value.id}`}>
          <div className='booking__hotel'>
            <div className={`booking__hotel-content ${hotelItems.descriptionHotelIds.includes(hotel.value.id) ? 'state-selected' : ''}`}>
              <div className='booking__hotel-img-wrap'>
                <img
                  className='booking__hotel-img'
                  src={hotel.value.image || '/packs/media/images/booking-hotel-1943f1bcf58d8d22a504337ea60431d6.jpg'}
                  // src={'https://spotway.ru/uploads/image/src/19678/original_11-2-2022__tomkulibyakin_2-112.jpg'}
                />
              </div>
              <div className='booking__hotel-info'>
                <div className='booking__hotel-info-name'>{hotel.value.name}</div>
                <div className='booking__hotel-info-attributes'>
                  {/* <div className='booking__hotel-info-attributes-food'>{hotel.value.foodType}</div> */}
                  <div className='booking__hotel-badge-wrap'>
                    {hotel.value.interestCategoriesText.slice(0, 3).map(cat => (
                      <Action
                        themes={['Button', 'ButtonSmall', 'ButtonBadge']}
                        onClick={null}
                      >
                        {cat}
                      </Action>
                    ))}
                  </div>
                  <div
                    className='booking__hotel-link-wrap'
                  >
                    <Action
                      themes={['Link']}
                      className='booking__hotel-info-link'
                      onClick={this.handleInfoClick.bind(this, hotel.value.id)}
                    >
                      фото и описание ОТЕЛЯ
                    </Action>
                  </div>
                  {/* <a
                    className='booking__info-link'
                    onClick={this.handleInfoClick.bind(this, hotel.value.id)}
                  >
                    фото и описание ОТЕЛЯ
                  </a> */}
                </div>
              </div>
              {this.renderHotelDescription(hotel.value)}
            </div>
          </div>
          {this.renderBookingRooms(hotel.value)}
        </Fragment>
      );
    });

    return arrBookingHotels;
  }

  render() {
    const bookingHotels = this.renderBookingHotels();

    if (bookingHotels.filter(h => h !== '').length > 0) {
      return bookingHotels;
    } else {
      return (
        <div className='booking__hotel-not-found'>
          <span>
            НИЧЕГО НЕ НАЙДЕНО
          </span>
        </div>
        );
    }
  }
}
