import React from 'react';
import reactCSS from 'reactcss';

// import SbpBlueIcon from '../../../../../icons/sbp-btn-blue.svg';
import EventPageMenuItem from './EventPageMenuItem';
import BonusesList from '../../components/BonusesList/BonusesList';
// import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

const EventPageMenu = (initialProps) => {
  const { rgba, hex, reachgoal, ga, vkgoal, target, offer, available, bonuses, bonusesBackground } = initialProps;
  const listItems = initialProps.list.map((item) => {
    return (<EventPageMenuItem key={`menu_item_key_${item.id}`} item={item} rootClass={initialProps.rootClass} />);
  });

  const styles = reactCSS({
    'default': {
      'color': {
        'borderColor': `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`,
        '--admin-color': hex
      },
      'groupBtn': {
        'display': 'flex',
        'flex-direction': 'column',
        'gap': '0.1rem',
        'width': 'fit-content',
        'marginLeft': 'auto'
      },
      'btnSbp': {
        'display': 'flex',
        'justifyContent': 'center',
        'height': '50px'
      }
    }
  });

  return (
    <div className={`w-event__nav-content ${initialProps.class}`}>
      <ul className='w-event__nav-list'>
        {listItems}
      </ul>
      <div style={styles.groupBtn}>
        {bonuses?.length > 0 && (
          <BonusesList bonuses={bonuses} bonusesBackground={bonusesBackground} />
        )}
        {available && (
          <>
            <a
              className='w-event__nav-btn btn btn_gradient_green js-events__links-click-go-to-booking'
              style={styles.color}
              data-reachgoal={reachgoal}
              data-ga={ga}
              data-vkgoal={vkgoal}
              data-target={target}
              data-offer={offer}
              href='#'
            >
              Бронирование
            </a>
            {/* <div style={styles.btnSbp}>
              <IconSvg icon={SbpBlueIcon} />
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default EventPageMenu;
