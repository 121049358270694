import React, { PureComponent } from 'react';
import cn from 'classnames';

import Counter from '../Counter';
import * as styles from './AdultsCounter.module.scss';

export default class AdultsCounter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleMinusClick = this.handleMinusClick.bind(this);
    this.handlePlusClick = this.handlePlusClick.bind(this);
  }

  handleMinusClick() {
    const { minusAdults } = this.props;

    minusAdults();
  }

  handlePlusClick() {
    const { plusAdults } = this.props;

    plusAdults();
  }

  alertNotes() {
    const { offer, members } = this.props;

    if (offer.maxAdultsCount <= members.adultsCount) {
      return (
        <div className={cn(styles.AdultsCounter__row, styles.AdultsCounter__invalid)}>
          <span>
            Достигнуто макс. кол-во взрослых в одном номере. Если вас едет больше, то просто оформите несколько заказов!
          </span>
        </div>
      );
    }

    return null;
  }

  render() {
    const { offer, members } = this.props;

    return (
      <div className={styles.AdultsCounter}>
        <div className={styles.AdultsCounter__row}>
          <div className={styles.AdultsCounter__labelWrap}>
            <strong>Взрослые</strong>
            <div className={styles.AdultsCounter__hint}>18+</div>
          </div>
          <Counter
            handleMinusClick={this.handleMinusClick}
            handlePlusClick={this.handlePlusClick}
            count={members.adultsCount}
            disableMinus={members.adultsCount < 2}
            disablePlus={offer.maxAdultsCount <= members.adultsCount}
            note={this.alertNotes()}
          />
        </div>
        {this.alertNotes()}
        <div className={styles.AdultsCounter__row_adults} />
      </div>
    );
  }
}
