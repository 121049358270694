import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingRooms from '../components/booking_rooms';
import * as BookingRoomsActions from '../actions/booking_rooms';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BookingRoomsActions, dispatch);
}
const BookingRoomsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingRooms);

export default BookingRoomsContainer;
