import React, { PureComponent } from 'react';
import OfferDatesContainer from '../../containers/offer_dates';
import MembersContainer from '../../containers/members';
import BookingOptionsListContainer from '../../containers/booking_options_list';
import BookingHotelContainer from '../../containers/booking_hotel';
import BookingCrossBar from '../BookingCrossBar';
import BookingPriceContainer from '../../containers/booking_price';
import InterestCategoriesContainer from '../../containers/interest_categories';
import FilterHotelsContainer from '../../containers/filter_hotels';
import FilterFoodsContainer from '../../containers/filter_food';
// import FilterInterestsFoodsContainer from '../containers/filter_interests_foods';
import PageSectionHeader from '../../../components/PageSectionHeader';
import Action from '../../../components/Action';
import Popup from '../../../components/Popup';
import FaqList from '../BookingCrossBar/FaqList';

import { AuthProvider } from '../../../../../hooks/use-auth';

import * as styles from './Booking.module.scss';
import BannerList from '../../../components/BannerList';

export default class Booking extends PureComponent {
  constructor(props) {
    super(props);
    this.refBookingContainer = React.createRef(null);
    this.refStickyElm = React.createRef(null);
  }

  componentDidUpdate() {
    const bookingContainer = this.refBookingContainer.current;
    const stickyElm = this.refStickyElm.current;
    const bookingPriceContainer = document.querySelector('.booking__price.__desktop');

    if (bookingContainer && stickyElm && bookingPriceContainer) {
      const observer = new IntersectionObserver(([e]) => {
        stickyElm.classList.toggle('booking__price-cost-background-hide', e.intersectionRatio > 0 || e.boundingClientRect.y < 0);
        bookingPriceContainer.classList.toggle('booking__price-sticky-full-width', stickyElm.classList.contains('booking__price-cost-background-hide'));
      }, { threshold: [1] });
      observer.observe(bookingContainer);

    }
  }

  renderStep1() {
    return (
      <div
        className='booking__select-items'
      >
        <OfferDatesContainer />
        <MembersContainer />
      </div>
    );
  }

  renderStep2() {
    const { offer } = this.props;
    if (Object.entries(offer.singleChoicePrograms).length === 0) return '';

    return (
      <div className='booking__options'>
        <div className='booking__options-content'>
          <BookingOptionsListContainer />
        </div>
      </div>
    );
  }

  renderBookingHotels() {
    const { offer, members } = this.props;
    const isPreviousSearchHotels = members.previousApartmentPrograms ? Object.entries(members?.previousApartmentPrograms).length !== 0 : false;
    const isCurrentSearchHotels = Object.entries(members.apartmentPrograms).length !== 0;
    const isInitHotels = Object.entries(members.apartmentPrograms).length === 0
      && !isPreviousSearchHotels;

    let apartmentPrograms = {};
    if (isInitHotels) apartmentPrograms = offer.apartmentPrograms;
    else if (isCurrentSearchHotels) apartmentPrograms = members.apartmentPrograms;
    else if (!isCurrentSearchHotels && isPreviousSearchHotels) apartmentPrograms = members.previousApartmentPrograms;

    const arrBookingHotels = Object.entries(apartmentPrograms).map(([id, program]) => {
      return (
        <BookingHotelContainer hotels={program.hotels} key={`program_${id}`} />
      );
    });

    return arrBookingHotels;
  }

  renderStep3() {
    const { members, offer } = this.props;
    const parentEl = Object.entries(members.activeHotels).length > 0 ? members : offer;
    const qntHotels = Object.values(parentEl.activeHotels).map(h => h.length)[0];

    return (
      <>
        <div className={qntHotels > 1 ? '' : 'booking__hide'}>
          <div className='booking__select-item-content-title'>проживание</div>
          <div className='booking__select-items'>
            <FilterFoodsContainer activeFoodIds={Object.keys(parentEl.hotelFoodTypes).map(elem => parseInt(elem, 10))} />
            <InterestCategoriesContainer interestCategoryIds={Object.keys(parentEl.interestCategories).map(elem => parseInt(elem, 10))} />
            <FilterHotelsContainer typeView='desctop' activeHotelIds={[...new Set(...Object.values(parentEl.activeHotels))]} />
          </div>
        </div>
        {this.renderBookingHotels()}
      </>
    );
  }

  render() {
    const { members, offer, isFormOpen, openForm, closeForm } = this.props;

    return (
      <AuthProvider>
        <div className='booking__wrap wrap'>
          <div className={styles.Booking__title}>
            <PageSectionHeader>Бронирование</PageSectionHeader>
            <Action
              onClick={openForm}
              className={styles.Booking__Link}
              themes={['Button', 'ButtonCommon']}
            >
              <div
                className={styles.Booking__Text}
              >
                БОНУСЫ, УСЛОВИЯ ОПЛАТЫ И ОТМЕНЫ (FAQ)
              </div>

            </Action>
          </div>
          <div className='booking__content'>
            {/* step 1 */}
            {this.renderStep1()}
            {/* step 2 */}
            {this.renderStep2()}
            {/* step 3 */}
            {members.isShowHotels ? this.renderStep3() : ''}
            <BannerList items={offer.banners} />
            <BookingPriceContainer />
            <BookingCrossBar offerId={offer.id} bonuses={offer.bonuses} />
            <div ref={this.refBookingContainer} />
          </div>
          <div ref={this.refStickyElm} className='booking__price-cost-background' />
        </div>
        <Popup show={isFormOpen} onClose={closeForm} variant='medium'>
          <FaqList offerId={offer.id} bonuses={offer.bonuses} />
        </Popup>
      </AuthProvider>
    );
  }
}
