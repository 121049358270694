import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Action from '../Action/Action';

import * as styles from './BannerList.module.scss';
import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import BonusCard from '../BonusCard/BonusCard';

const ITEMS_PER_PAGE = 3;

const BannerList = ({ items }) => {
  const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_PAGE);
  const [isDescriptionOpen, openDescription, closeDescription] = usePopup();
  const [banner, setBanner] = useState({});

  const isShowMoreButton = items?.length > itemsToShow;

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + ITEMS_PER_PAGE);
  };

  const hadleShowDescription = (item) => {
    setBanner(item);

    if (item?.description) openDescription();
  };

  return (
    <section className={styles.BannerList}>
      <div className={styles.BannerList__itemsGrid}>
        {items?.slice(0, itemsToShow).map(item => (
          <div
            key={`press_${item.id}`}
            className={styles.BannerList__item}
            onClick={() => hadleShowDescription(item)}
            role='button'
          >
            {item.image && (
              <div className={styles.BannerList__logoWrap}>
                <img
                  className={styles.BannerList__logo}
                  src={item.image}
                  alt={item.title}
                />
              </div>
            )}
            <div className={
              cn(
                styles.BannerList__textWrap,
                { [styles.BannerList__withImg]: item.image }
              )}
            >
              <div className={styles.BannerList__title}>{item.title}</div>
              <div className={styles.BannerList__short_description}>{item.short_description}</div>
              <div className={styles.BannerList__discount}>{item.discount}</div>
            </div>
          </div>
        ))}
      </div>
      {isShowMoreButton && (
        <div className={styles.BannerList__more}>
          <Action themes={['Button', 'ButtonCommon', 'ButtonWarning']} onClick={handleShowMore}>
            Другие бонусы
          </Action>
        </div>
      )}

      {banner?.description && (
        <Popup
          show={isDescriptionOpen}
          onClose={closeDescription}
          variant='medium'
          withHeader={false}
          footer={
            banner.text_on_btn && (
              <div className={styles.BonusCard__priceWrap}>
                <Action
                  href={banner.link_by_btn}
                  themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
                  target='__blank'
                >
                  {banner.text_on_btn}
                </Action>
              </div>
            )
          }
        >
          <BonusCard key={`banner-card__${banner.id}`} bonus={banner} btnInFooter />
        </Popup>
      )}
    </section>
  );
};

BannerList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    readMoreUrl: PropTypes.string
  })).isRequired
};

export default BannerList;
