import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingOptionsList from '../components/booking_options_list';
import * as BookingOptionsListActions from '../actions/booking_options_list';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BookingOptionsListActions, dispatch);
}

const BookingOptionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingOptionsList);

export default BookingOptionsListContainer;
