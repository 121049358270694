import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ShowMoreButton from '../components/show_more_button';
import * as BookingHotelActions from '../actions/booking_hotel';
import * as SharedActions from '../actions/shared';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  // return bindActionCreators(OfferDatesActions, dispatch);
  return bindActionCreators({ ...BookingHotelActions, ...SharedActions }, dispatch);
}
const ShowMoreContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowMoreButton);

export default ShowMoreContainer;
