import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import ru from 'react-phone-number-input/locale/ru.json';
import examples from 'libphonenumber-js/examples.mobile.json';
import { getExampleNumber, getCountryCallingCode } from 'libphonenumber-js';

export default class InputPhone extends Component {
  constructor(props) {
    super(props);
    const {
      phone,
      id,
      name,
      disabled,
      hideError,
      internationalVisible,
      allCountries
    } = this.props;

    this.state = {
      value: phone,
      idInputPhone: id,
      nameInputPhone: name,
      disabledInput: disabled,
      defaultCountry: 'RU',
      hideInputError: hideError,
      isInternationalVisible: internationalVisible,
      isAllCountries: allCountries,
      country: 'RU'
    };

    this.timer = null;

    this.handlePoneInputChange = this.handlePoneInputChange.bind(this);
    this.handlePoneInputCountryChange = this.handlePoneInputCountryChange.bind(this);
    this.handlePoneInputKeyDown = this.handlePoneInputKeyDown.bind(this);
  }

  getPlaceholderNumber(country) {
    const { defaultCountry } = this.state;
    const localCountry = country || defaultCountry;
    const phoneNumber = getExampleNumber(localCountry, examples);

    return phoneNumber.formatInternational();
  }

  getCountryPrefix() {
    const { country, defaultCountry } = this.state;
    const localCountry = country || defaultCountry;
    const prefixPhoneNumber = `+${getCountryCallingCode(localCountry)}`;

    return prefixPhoneNumber;
  }

  getValue(value) {
    const prefixPhoneNumber = this.getCountryPrefix();
    const localValue = value || '';

    if (prefixPhoneNumber.length >= localValue.length) {
      return this.getCountryPrefix();
    }

    return value;
  }

  setValue(val) {
    let valuePhone;

    if (val && formatPhoneNumber(val, 'International')) {
      valuePhone = formatPhoneNumber(val, 'International');
    } else {
      valuePhone = this.getValue(val);
    }

    this.setState({ value: valuePhone });
  }

  buttonDisableEnable(valid) {
    const { btnClassName } = this.props;
    const buttons = document.querySelectorAll(btnClassName);

    for (let i = 0, len = buttons.length; i < len; i++) {
      if (valid) {
        buttons[i].removeAttribute('disabled');
      } else {
        buttons[i].setAttribute('disabled', 'disabled');
      }
    }
  }

  validatePhone(value) {
    const { country, defaultCountry, isInternationalVisible } = this.state;

    if (!country) return false;

    const localCountry = isInternationalVisible ? (country || defaultCountry) : country;
    const codValue = getCountryCallingCode(localCountry);
    let valid = true;

    if (value) {
      valid = value.length < 3 ? false : isValidPhoneNumber(value);
    } else if (codValue) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  }

  handlePoneInputCountryChange(val) {
    this.setState({ country: val });
  }

  handlePoneInputChange(val) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const valid = this.validatePhone(val);
      // if (valid) {
      this.setValue(val);
      // }
      this.buttonDisableEnable(valid);
    }, 100);
  }

  handlePoneInputKeyDown(value) {
    // Backspace = 8, delete = 46
    if (value.keyCode !== 8 && value.keyCode !== 46) return;

    const inputValue = value.currentTarget;
    const prefixPhoneNumber = this.getCountryPrefix();

    if (prefixPhoneNumber.length >= (inputValue.value.length - 1)) {}
  }

  render() {
    const {
      value,
      idInputPhone,
      nameInputPhone,
      disabledInput,
      country,
      defaultCountry,
      hideInputError,
      isInternationalVisible,
      isAllCountries
    } = this.state;

    let errorMsg;
    const countries = isAllCountries ? [] : ['RU', 'BY', 'UA'];

    if (value) {
      errorMsg = isValidPhoneNumber(value) ? undefined : 'Неправильно указан телефон.';
    }

    if (value && !formatPhoneNumber(value, 'International')) {
      errorMsg = 'Неправильно указан телефон.';
    }

    if (hideInputError) {
      errorMsg = undefined;
    }

    return (
      <PhoneInput
        labels={ru}
        country={defaultCountry}
        international={isInternationalVisible}
        countryOptions={['RU', 'BY', 'UA', '|']}
        countries={countries}
        limitMaxLength
        id={idInputPhone}
        name={nameInputPhone}
        placeholder={this.getPlaceholderNumber(country)}
        value={this.getValue(value)}
        onChange={this.handlePoneInputChange}
        error={errorMsg}
        disabled={disabledInput}
        onCountryChange={this.handlePoneInputCountryChange}
        onKeyDown={this.handlePoneInputKeyDown}
      />
    );
  }
}

InputPhone.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  hideError: PropTypes.bool,
  internationalVisible: PropTypes.bool,
  allCountries: PropTypes.bool,
  btnClassName: PropTypes.string.isRequired
};

InputPhone.defaultProps = {
  hideError: false,
  internationalVisible: false,
  allCountries: true
};
