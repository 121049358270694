import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FreeMode, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Action from '../Action';
import lightenDarkenColor from '../../../../helpers/lightenDarkenColor';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import ChevronLeftIcon from '../../../../../icons/chevron-left.svg';
import ChevronRightIcon from '../../../../../icons/chevron-right.svg';

import * as styles from './BlocksSlider.module.scss';

const BlocksSlider = ({
  blocks,
  sliderClassName,
  compactVariant
}) => {
  if (blocks === undefined || blocks.length === 0) return '';

  // TODO: move from state to ref
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const [paginationEl, setPaginationEl] = useState(null);

  const buttonBackground = (block) => {
    const blockBrandColor = block.buttonColor.hex;
    const blockDarkenBrandColor = lightenDarkenColor(blockBrandColor, -35);

    return `linear-gradient(180deg, ${blockBrandColor} 0%, ${blockDarkenBrandColor} 100%)`;
  };


  return (
    <section className={styles.BlocksSlider}>
      <Swiper
        className={sliderClassName}
        modules={[FreeMode, Pagination, Navigation]}
        slidesPerView='auto'
        navigation={{
          prevEl,
          nextEl,
          disabledClass: styles.BlocksSlider__navDisabled
        }}
        pagination={{
          el: paginationEl,
          clickable: true
        }}
        onAfterInit={(s) => {
          const thisSlides = s.slides;
          // BlocksSlider__description
          const descriptions = thisSlides.map(slide => slide.childNodes[0]?.childNodes[2]);
          const maxHeight = Math.max(...descriptions.map(slide => slide.offsetHeight));

          descriptions.forEach((description) => {
            if (description) description.style.minHeight = `${maxHeight}px`;
          });
        }}
      >
        {/* {blocks.map(block => (
          <SwiperSlide className={styles.BlocksSlider__block} key={block.key}>
            <div
              className={styles.BlocksSlider__html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: block.html }}
            />
          </SwiperSlide>
        ))} */}
        {blocks.map(block => (
          <SwiperSlide className={styles.BlocksSlider__block} key={block.key}>
            <div
              className={styles.BlocksSlider__container}
            >
              <div
                className={styles.BlocksSlider__image}
              >
                <img
                  alt=''
                  src={block.coverImage}
                  className={styles.BlocksSlider__coverImage}
                />
              </div>
              <div className={styles.BlocksSlider__name}>
                {block.name}
              </div>
              <div
                className={styles.BlocksSlider__description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: block.description }}
              />
              {(block.linkMore && block.buttonText) && (
                <Action
                  href={block.linkMore}
                  themes={['Button', 'ButtonCommon', 'ButtonWarning']}
                  style={{ background: buttonBackground(block) }}
                  target='_blank'
                >
                  {block.buttonText}
                </Action>
              )}
            </div>
          </SwiperSlide>
        ))}
        <div
          className={styles.BlocksSlider__navLeft}
          ref={node => setPrevEl(node)}
        >
          <IconSvg icon={ChevronLeftIcon} className={styles.BlocksSlider__chevron} />
        </div>
        <div
          className={styles.BlocksSlider__navRight}
          ref={node => setNextEl(node)}
        >
          <IconSvg icon={ChevronRightIcon} className={styles.BlocksSlider__chevron} />
        </div>
      </Swiper>
      <div
        className={styles.BlocksSlider__navPagination}
        ref={node => setPaginationEl(node)}
      />
    </section>
  );
};

BlocksSlider.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    html: PropTypes.string
  })).isRequired,
  sliderClassName: PropTypes.string.isRequired,
  compactVariant: PropTypes.bool
};

BlocksSlider.defaultProps = {
  compactVariant: false
};

export default BlocksSlider;
