import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Action from '../../../../components/Action';
import BonusesList from '../../../../components/BonusesList';

import { fetchEvensOfferData } from '../../../../../../services/base';

import * as styles from './EventsList.module.scss';
import { ReachGoal } from '../../../../YandexMetrika';

const EventsList = ({ offerId, onClose }) => {
  const [eventsData, setEventsData] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [offerName, setOfferName] = useState('');
  const [target, setTarget] = useState('');

  const handleClick = (id, e) => {
    const anchor = document.querySelectorAll(`a[name$="_${id}"]`)[0];

    e.nativeEvent.stopImmediatePropagation();
    e.currentTarget.href = `#${anchor?.name || 'anchor-page-event__booking'}`;
    onClose();
  };

  const handleGoToBookingClick = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.currentTarget.href = '#anchor-page-event__booking';
    if (target) ReachGoal(target, { offer: offerName });
    onClose();
  };

  const actions = eventsData.map(action => (
    <li key={action.title}>
      <Action
        className={styles.EventsList__link}
        href='#'
        onClick={e => handleClick(action.id, e)}
      >
        {action.title}
      </Action>
    </li>
  ));

  useEffect(() => {
    fetchEvensOfferData(offerId, (result) => {
      setEventsData(result.events);
      setBonuses(result.bonuses);
      setTarget(result.scroll_to_booking_target);
      setOfferName(result.name);
    });
  }, []);

  return (
    <ul className={styles.EventsList}>
      {bonuses?.length > 0 && (
        <li key='key_bonuses'>
          <BonusesList bonuses={bonuses} bonusesBackground='yellow' />
        </li>
      )}
      {actions}
      <li key='key_booking'>
        <Action
          className={cn(styles.EventsList__link, 'js-events__links-click-go-to-booking')}
          href='#'
          onClick={e => handleGoToBookingClick(e)}
          data-target={target}
          data-offer={offerId}
        >
          Цены и бронирование
        </Action>
      </li>
    </ul>
  );
};

EventsList.propTypes = {
  offerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EventsList;
