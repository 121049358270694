import { Map } from 'immutable';
// import fetch from 'isomorphic-fetch';

export const REQUEST_PERSON = 'REQUEST_PERSON';
export const RECEIVE_PERSON = 'RECEIVE_PERSON';
export const CHOOSE_ACTIVE_CHAT = 'CHOOSE_ACTIVE_CHAT';
export const VIEW_NEW_COMMENTS = 'VIEW_NEW_COMMENTS';

export function activeChat(channelId) {
  return {
    type: CHOOSE_ACTIVE_CHAT,
    activeChannel: channelId
  };
}

export function editPerson(channel) {
  return {
    type: RECEIVE_PERSON,
    payload: channel
  };
}

export function viewNewComments(unreadIds) {
  return {
    type: VIEW_NEW_COMMENTS,
    payload: unreadIds
  };
}

export function activeChatAction(channelId) {
  return (dispatch) => {
    dispatch(activeChat(channelId));
  };
}

export function editPersonAsync(channelId, n, desc) {
  // console.log('editPersonAsync');
  // return dispatch => {
  // dispatch(editData());

  // api.testAsync()
  //   .then(data => dispatch(testAsyncSuccess(data)))
  //   .catch(error => dispatch(testAsyncError(error)));
  // };
  return (dispatch) => {
    const ch = Map().set(channelId, { name: n, description: desc });
    // const options = {
    //   method: 'PATCH',
    //   cache: 'default',
    //   mode: 'cors',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    //     'Accept': 'application/json, text/javascript, */*; q=0.01',
    //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    //   },
    //   body: JSON.stringify({
    //     authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
    //     channel: {
    //       name: n,
    //       description: desc
    //     },
    //     channel_id: channelId
    //   })
    // };

    // return fetch(`/channels/${channelId}`, options)
    //   .then(response => {
    //     if (response.status === 200) {
    //       dispatch(editPerson(ch.toJS()));
    //     }
    //   })
    //   .catch(error => console.log('editPersonAsync', error));
    $.ajax({
      url: `/channels/${channelId}`,
      type: 'PATCH',
      dataType: 'json',
      data: {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
        channel: {
          name: n,
          description: desc
        },
        channel_id: channelId
      },
      cache: false,
      success(data) {
        // console.log(data);
        dispatch(editPerson(ch.toJS()));
      },
      error(xhr, status, err) {
        console.error(`/channels/${channelId}`, status, err.toString());
      }
    });
  };
}

export function viewNewCommentsAction(channelId) {
  return (dispatch, getState) => {
    const newCommentsIds = getState().chat.get('newCommentsIds');
    const unreadCommentsIds = Map(getState().chat.get('unreadCommentsIds'));

    // console.log('viewNewComments', newCommentsIds, unreadCommentsIds);

    const unreadIds = unreadCommentsIds.update(channelId.toString(),
      v => v.filter(x => ![...newCommentsIds[channelId]].includes(x)));

    // console.log('viewNewComments', unreadIds);
    dispatch(viewNewComments(unreadIds.toJS()));
  };
}
