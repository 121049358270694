export const SELECTED_ROOM_ITEM = 'SELECTED_ROOM_ITEM';
export const REMOVE_SELECTED_ROOM_ITEM = 'REMOVE_SELECTED_ROOM_ITEM';
export const CLEAR_SELECTED_ROOM_ITEM = 'CLEAR_SELECTED_ROOM_ITEM';
export const OPEN_DESCRIPTION_ROOM_ITEM = 'OPEN_DESCRIPTION_ROOM_ITEM';
export const CLOSE_DESCRIPTION_ROOM_ITEM = 'CLOSE_DESCRIPTION_ROOM_ITEM';

function selectedRoomItemAction(data) {
  return {
    type: SELECTED_ROOM_ITEM,
    payload: data
  };
}

function removeSelectedRoomItemAction(data) {
  return {
    type: REMOVE_SELECTED_ROOM_ITEM,
    payload: data
  };
}

function clearSelectedRoomItemsAction() {
  return {
    type: CLEAR_SELECTED_ROOM_ITEM
  };
}

function openDescriptionRoomItemAction(data) {
  return {
    type: OPEN_DESCRIPTION_ROOM_ITEM,
    payload: data
  };
}

function closeDescriptionRoomItemAction(data) {
  return {
    type: CLOSE_DESCRIPTION_ROOM_ITEM,
    payload: data
  };
}

export function selectedRoomItem(roomId, room) {
  return (dispatch) => {
    const data = { selectedRoomId: roomId, selectedRoom: room };

    dispatch(selectedRoomItemAction(data));
  };
}

export function removeSelectedRoomItem() {
  return (dispatch) => {
    const data = { selectedRoomId: 0, selectedRoom: {} };

    dispatch(removeSelectedRoomItemAction(data));
  };
}

export function clearSelectedRoomItems() {
  return (dispatch) => {
    dispatch(clearSelectedRoomItemsAction());
  };
}

export function openDescriptionRoomItem(roomId, roomFoodTypeCode) {
  return (dispatch, getState) => {
    const newDescriptionRoomsId = {};
    newDescriptionRoomsId[roomId] = roomFoodTypeCode;
    // const descriptionRoomsIds = [...new Set([...getState().roomItems.descriptionRoomsIds, ...[roomId]])];
    const descriptionRoomsIds = Object.assign({}, getState().roomItems.descriptionRoomsIds, newDescriptionRoomsId);

    dispatch(openDescriptionRoomItemAction(descriptionRoomsIds));
  };
}

export function closeDescriptionRoomItem(roomId) {
  return (dispatch, getState) => {
    // const descriptionRoomsIds = getState().roomItems.descriptionRoomsIds.filter((elem) => elem !== roomId);
    const { descriptionRoomsIds } = getState().roomItems;
    const newDescriptionRoomsId = descriptionRoomsIds;
    delete newDescriptionRoomsId[roomId];

    dispatch(closeDescriptionRoomItemAction(newDescriptionRoomsId));
  };
}
