export const ADULTS_PLUS = 'ADULTS_PLUS';
export const ADULTS_MINUS = 'ADULTS_MINUS';
export const CHILDS_PLUS = 'CHILDS_PLUS';
export const CHILDS_MINUS = 'CHILDS_MINUS';
export const KIDS_AGES_PLUS = 'KIDS_AGES_PLUS';
export const KIDS_AGES_MINUS = 'KIDS_AGES_MINUS';
export const MEMBERS_PARAMS = 'MEMBERS_PARAMS';
export const INIT_MEMBERS = 'INIT_MEMBERS';
export const CURRENT_MEMBERS = 'CURRENT_MEMBERS';
export const PREVIOUS_MEMBERS = 'PREVIOUS_MEMBERS';

function plusAdultsAction() {
  return {
    type: ADULTS_PLUS
  };
}

function minusAdultsAction() {
  return {
    type: ADULTS_MINUS
  };
}

function plusChildsAction() {
  return {
    type: CHILDS_PLUS
  };
}

function minusChildsAction() {
  return {
    type: CHILDS_MINUS
  };
}

function plusKidsAgesAction(index, age) {
  return {
    type: KIDS_AGES_PLUS,
    payload: { index: index, age: age }
  };
}

function minusKidsAgesAction(index, age) {
  return {
    type: KIDS_AGES_MINUS,
    payload: { index: index, age: age }
  };
}

function addParamsAction(ageData, singleChoiceProducts) {
  return {
    type: MEMBERS_PARAMS,
    payload: { age_data: ageData, single_choice_products: singleChoiceProducts }
  };
}

function prepareAgeData(members, adultsCount) {
  const ageData = {};

  for (let i = 0; i <= 17; i++) {
    const v = Object.values(members.childs).filter(e => e === i).length;

    if (v > 0) {
      const had = {};
      const val = `age_${i}`;

      had['name'] = val;
      had['value'] = v;
      ageData[i] = had;
    }
  }

  ageData[99] = { 'name': 'age_99', 'value': adultsCount };

  return ageData;
}

function prepareSingleChoiceProduct(offer) {
  const choiceSingleProducts = Object.values(offer.singleChoiceProducts).reduce((acc = [], product) => {
    acc.push({ product_id: product.id, program_id: product.programId, recommended: product.recommended });
    return acc;
  }, []);
  const apartmentProducts = {};

  for (const [key, value] of Object.entries(offer.allActiveHotels)) {
    apartmentProducts[key] = {
      program_id: key,
      product_id: value.reduce((acc, curr, ind) => { acc[ind] = curr; return acc; }, {}),
      product_type: 'aprt'
    };
  }

  return Object.assign({}, { ...choiceSingleProducts, ...apartmentProducts });
}

function initMembersAction(recommendedQntAdults, singleChoiceProducts, ageData) {
  return {
    type: INIT_MEMBERS,
    payload: {
      adultsCount: recommendedQntAdults,
      currentAdultsCount: recommendedQntAdults,
      single_choice_products: singleChoiceProducts,
      age_data: ageData
    }
  };
}

function saveCurrentMembersAction(data) {
  return {
    type: CURRENT_MEMBERS,
    payload: data
  };
}

function previousMembersAction(data) {
  return {
    type: PREVIOUS_MEMBERS,
    payload: data
  };
}

export function plusChilds() {
  return (dispatch) => {
    dispatch(plusChildsAction());
  };
}

export function minusChilds(age) {
  return (dispatch) => {
    dispatch(minusChildsAction(age));
  };
}

export function plusKidsAges(index, age) {
  return (dispatch) => {
    dispatch(plusKidsAgesAction(index, age));
  };
}

export function minusKidsAges(index, age) {
  return (dispatch) => {
    dispatch(minusKidsAgesAction(index, age));
  };
}

export function plusAdults() {
  return (dispatch) => {
    dispatch(plusAdultsAction());
  };
}

export function minusAdults() {
  return (dispatch) => {
    dispatch(minusAdultsAction());
  };
}

export function initMembers() {
  return (dispatch, getState) => {
    const { offer, members } = getState();
    const { recommendedQntAdults } = offer;
    const singleChoiceProducts = prepareSingleChoiceProduct(offer);
    const ageData = prepareAgeData(members, recommendedQntAdults);

    dispatch(initMembersAction(recommendedQntAdults, singleChoiceProducts, ageData));
  };
}

export function addParams() {
  return (dispatch, getState) => {
    const {
      offer,
      members,
      offerDates
    } = getState();
    const ageData = prepareAgeData(members, members.adultsCount);
    const singleChoiceProducts = prepareSingleChoiceProduct(offer);


    dispatch(addParamsAction(ageData, singleChoiceProducts));
  };
}

export function saveCurrentMembers() {
  return (dispatch, getState) => {
    const { adultsCount, childsCount, childs } = getState().members;
    const members = {
      currentAdultsCount: adultsCount,
      currentChildsCount: childsCount,
      currentChilds: Object.assign({}, childs)
    };

    dispatch(saveCurrentMembersAction(members));
  };
}

export function previousMembers() {
  return (dispatch, getState) => {
    const { currentAdultsCount, currentChildsCount, currentChilds } = getState().members;
    const members = {
      adultsCount: currentAdultsCount,
      childsCount: currentChildsCount,
      childs: Object.assign({}, currentChilds)
    };

    dispatch(previousMembersAction(members));
  };
}
