import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Person from '../components/person';
import * as PersonActions from '../actions/person';

function mapStateToProps(state) {
  return {
    active: state.chat.get('active'),
    asyncLoading: state.chat.get('asyncLoading'),
    newCommentsIds: Map(state.chat.get('newCommentsIds')).toJS(),
    unreadCommentsIds: Map(state.chat.get('unreadCommentsIds')).toJS()
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PersonActions, dispatch);
}
const PersonApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(Person);

export default PersonApp;
