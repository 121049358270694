import * as actions from '../actions/chat';

const socketMiddleware = store => next => (action) => {
  let socket = null;

  const onOpen = (ws, store, token) => (e) => {
    store.dispatch(actions.subscriptions(ws.url));
  };

  const onClose = (ws, store) => (e) => {
    store.dispatch(actions.unsubscriptions());
  };

  const onMessage = (ws, store) => (e) => {
    const msg = JSON.parse(JSON.parse(e.data));

    switch (msg.type) {
      case 'SOCKET_ADD_MESSAGE':
        store.dispatch(actions.socketAddMessage(msg));
        break;
      case 'SOCKET_DEL_MESSAGE':
        store.dispatch(actions.socketDelMessage(msg));
        break;
      default:
        console.log(`Received unknown message type: '${e.data}'`);
        break;
    }
  };

  switch (action.type) {
    case 'SOCKET_OPEN':
      if (socket !== null) {
        socket.close();
      }
      socket = new WebSocket(action.payload);
      socket.onmessage = onMessage(socket, store);
      socket.onclose = onClose(socket, store);
      socket.onopen = onOpen(socket, store, action.token);

      break;

    case 'SOCKET_CLOSE':
      if (socket !== null) {
        socket.close();
      }
      socket = null;
      store.dispatch(actions.unsubscriptions());
      break;

    case 'SEND_CHAT_MESSAGE':
      socket.send(JSON.stringify(action));
      break;

    default:
      return next(action);
  }
};

export default socketMiddleware;
