// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notes from '../components/notes';

function mapStateToProps(state) {
  return state;
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(BookingActions, dispatch);
// }

const NotesContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(Notes);

export default NotesContainer;
