import showHideCallbackPhoneLink from './showHideCallbackPhoneLink';

export default function openFormCallBackPhone(e) {
  e.preventDefault();
  const headerMenu = document.querySelector('.header__menu-wrap');
  const headerMenuHamburger = document.querySelector('.header__menu-hamburger');

  document.body.classList.add('header__menu--open');
  headerMenu.classList.add('header__menu-wrap--open');
  headerMenuHamburger.classList.add('state-active');
  showHideCallbackPhoneLink();
}
