import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import BookingDescriptionContainer from '../../containers/booking_description';
import { sortObject } from '../../../../../common/javascript/api';
import Checkbox from '../../../components/Checkbox';
import { numberToMoneyWithoutCurrency } from '../../../../../helpers/currency';

import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import FaqIcon from '../../../../../../icons/help.svg';
import SbpIcon from '../../../../../../icons/sbp-without-text.svg';

import * as styles from './BookingOptionsItem.module.scss';

export default class BookingOptionsItem extends PureComponent {
  constructor(props) {
    super(props);

    const { offer } = this.props;

    this.state = {
      notApartProgram: Object.values(offer.singleChoiceProducts).filter(p => p.notApartmentProgram).reduce((acc, v) => { acc[v.programId] = (Object.keys(acc[v.programId] || {}).length > 0 ? acc[v.programId].concat(v.id) : [v.id]); return acc; }, {})
    };
  }

  componentDidMount() {
    const { singleChoiceProgramsItems } = this.props;
    let selectedOption = Object.values(singleChoiceProgramsItems).filter(p => p.recommended);

    if (Object.keys(selectedOption).length === 0) return;

    selectedOption.forEach((item, i) => {
      this.initSelectedOptions(item.programId, item.id);
    });
  }

  initSelectedOptions(programId, productId) {
    const {
      optionItems,
      selectedOptionItem,
      removeSelectedOptionItem,
      showHotels,
      hideHotels,
      clearSelectedItems,
      isMandatoryChoice
    } = this.props;
    const { notApartProgram } = this.state;
    const selectedOption = optionItems.selectedOptionIds[programId] === productId;

    if (selectedOption && !isMandatoryChoice) removeSelectedOptionItem(programId, productId);
    else selectedOptionItem(programId, productId);

    if (!notApartProgram[programId]) return;
    if (notApartProgram[programId].includes(parseInt(productId, 10))) {
      hideHotels();
      clearSelectedItems();
    } else showHotels();
  }

  handleLinkMoreClick(optionId) {
    const { openDescriptionOption } = this.props;

    openDescriptionOption(optionId);
  }

  handleSelectClick(programId, productId) {
    event.preventDefault();

    if (event.target.classList.contains('booking__options-item-hint')) return;
    if (event.target.classList.contains('booking__options-item-link-more-text')) return;

    this.initSelectedOptions(programId, productId);
  }

  renderHint(product) {
    const showIconSbp = moment(product.dateActiveSbpDiscount).valueOf() <= moment().valueOf();
    if (!product.withDescription) {
      if (showIconSbp) {
        return (<IconSvg icon={SbpIcon} />);
      }

      return '';
    }

    const tag = (
      <>
        {showIconSbp && (
          <div
            onClick={this.handleLinkMoreClick.bind(this, product.id)}
          >
            <IconSvg icon={SbpIcon} />
          </div>
        )}
        {!showIconSbp && (
          <div
            className='booking__options-item-hint'
            onClick={this.handleLinkMoreClick.bind(this, product.id)}
          >
            {!showIconSbp && <IconSvg icon={FaqIcon} />}
          </div>
        )}
      </>
    );

    return tag;
  }

  renderMoreText(product) {
    if (!product.withDescription) return '';

    const hint = 'i';
    const moreText = 'подробнее';
    const text = (
      <a
        key={`more_text_${product.id}`}
        className='booking__options-item-link-more'
        onClick={this.handleLinkMoreClick.bind(this, product.id)}
      >
        <div className='booking__options-item-hint'>{hint}</div>
        <div className='booking__options-item-link-more-text'>{moreText}</div>
      </a>
    );

    if (!product.id) return '';

    return text;
  }

  renderBookingDescription(product) {
    const { optionItems } = this.props;

    if (!optionItems.descriptionOptionsIds.includes(product.id)) return '';

    return (
      <BookingDescriptionContainer
        key={`booking_option_description_${product.id}`}
        element={product}
        isOption
      />
    );
  }

  renderBookingItems() {
    const {
      singleChoiceProgramsItems,
      optionItems,
      members,
      offerDates,
      offer
    } = this.props;

    if (Object.entries(optionItems.optionPrices).length === 0) return '';

    const options = Object.entries(members.singleChoiceProductPrices).length === 0
      ? optionItems.optionPrices
      : members.singleChoiceProductPrices;

    const sortSingleChoiceProgramsItems = sortObject(singleChoiceProgramsItems);

    const isDisable = Object.keys(singleChoiceProgramsItems).length === 1;
    const keyPeriod = `${moment(offerDates.startDate).format('YYYY-MM-DD')}-${moment(offerDates.finishDate).format('YYYY-MM-DD')}`;

    const arrBookingItems = sortSingleChoiceProgramsItems.map((item) => {
      const product = item.value;
      const productId = parseInt(item.key, 10);
      const selectedOption = optionItems.selectedOptionIds[product.programId] === product.id;
      let cost = -1;
      const currentOptionPrice = options[keyPeriod][product.id];

      if (offer.allowCustomDuration) {
        cost = options[keyPeriod]
          ? options[keyPeriod][product.id].gross
          : 0;
      } else {
        cost = options[keyPeriod][product.id]
          ? options[keyPeriod][product.id].gross
          : 0;
      }

      if (cost > -1) {
        return (
          <Fragment key={`frag_option_${product.id}`}>
            <div
              className={`booking__options-item ${selectedOption ? 'state-selected' : ''}`}
              key={`productItem_${productId}`}
              onClick={isDisable ? null : this.handleSelectClick.bind(this, product.programId, product.id)}
            >
              <div className={styles.BookingOptionsItem__optionsItemContent}>
                <Checkbox
                  label={product.name}
                  id={`id-option-checkbox-${productId}`}
                  name={`option-checkbox-${productId}`}
                  type='checkbox'
                  checked={selectedOption}
                  onClick={isDisable ? null : this.handleSelectClick.bind(this, product.programId, product.id)}
                />
                {/* <div className='booking__options-item-info'>{product.name}</div> */}
                <div className={styles.BookingOptionsItem__optionsItemPrice}>
                  {currentOptionPrice?.discountDate && (
                    <div className={styles.BookingOptionsItem__optionsItemDiscountDesktop}>
                      <div className={styles.BookingOptionsItem__optionsItemDiscountFigure}>
                        {`${currentOptionPrice.costCurrentAll} ${currentOptionPrice.currencySign}`}
                      </div>
                      <div className={styles.BookingOptionsItem__optionsItemDiscountDateDesktop}>
                        {`до ${currentOptionPrice.discountDate}`}
                      </div>
                    </div>
                  )}
                  {(!currentOptionPrice?.discountDate && currentOptionPrice?.discountForRegularCustomer !== 0) && (
                    <div className={styles.BookingOptionsItem__optionsItemDiscount}>
                      <div className={styles.BookingOptionsItem__optionsItemDiscountFigure}>
                        {`${currentOptionPrice.priceForRegularCustomer} ${currentOptionPrice.currencySign}`}
                      </div>
                    </div>
                  )}
                  <div className={styles.BookingOptionsItem__optionsItemCostContent}>
                    <div className={styles.BookingOptionsItem__optionsItemCostContentWrap}>
                      <div className={styles.BookingOptionsItem__optionsItemCost}>{numberToMoneyWithoutCurrency(cost)}</div>
                      <div className={styles.BookingOptionsItem__optionsItemCurrency}>{product.currencySign}</div>
                    </div>
                    {currentOptionPrice?.discountDate && (
                      <div className={styles.BookingOptionsItem__optionsItemDiscountMobile}>
                        <div className={styles.BookingOptionsItem__optionsItemDiscountFigure}>
                          {`${currentOptionPrice.costCurrentAll} ${currentOptionPrice.currencySign}`}
                        </div>
                        <div className={styles.BookingOptionsItem__optionsItemDiscountDateMobile}>
                          {`до ${currentOptionPrice.discountDate}`}
                        </div>
                      </div>
                    )}
                    {(!currentOptionPrice?.discountDate && currentOptionPrice?.discountForRegularCustomer !== 0) && (
                      <div className={styles.BookingOptionsItem__optionsItemDiscount}>
                        <div className={styles.BookingOptionsItem__optionsItemDiscountFigure}>
                          {`${currentOptionPrice.priceForRegularCustomer} ${currentOptionPrice.currencySign}`}
                        </div>
                      </div>
                    )}
                  </div>
                  {this.renderHint(product)}
                </div>
              </div>
              {this.renderMoreText(product)}
            </div>
            {this.renderBookingDescription(product)}
          </Fragment>
        );
      } else {
        return null;
      }
    });

    return arrBookingItems;
  }

  render() {
    const { programName } = this.props;
    const bookingItems = this.renderBookingItems();
    const canViewProgram = Object.assign([], bookingItems).filter(val => val !== null).length > 0;

    return (
      <>
        {canViewProgram && (
          <>
            <div className='booking__options-name'>{programName}</div>
            <div className='booking__options-list'>
              {bookingItems}
            </div>
          </>
        )}
      </>
    );
  }
}
