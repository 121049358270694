import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormComments from '../components/form_comment';
import * as FormCommentsActions from '../actions/form_comments';

function mapStateToProps(state) {
  return {
    active: state.chat.get('active'),
    asyncLoading: state.chat.get('asyncLoading'),
    replyIdMsg: state.chat.get('replyIdMsg'),
    isAddComment: state.chat.get('isAddComment'),
    attachMsgAuthor: state.chat.get('attachMsgAuthor')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FormCommentsActions, dispatch);
}
const FormCommentsApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComments);

export default FormCommentsApp;
