export const INIT_FILTER_FOOD = 'INIT_FILTER_FOOD';
export const SELECTED_FILTER_FOOD = 'SELECTED_FILTER_FOOD';
export const REMOVE_SELECTED_FILTER_FOOD = 'REMOVE_SELECTED_FILTER_FOOD';

function initFilterFoodAction(data) {
  return {
    type: INIT_FILTER_FOOD,
    payload: data
  };
}

function selectedFilterFoodsAction(data) {
  return {
    type: SELECTED_FILTER_FOOD,
    payload: data
  };
}

function removeSelectedFilterFoodsAction(data) {
  return {
    type: REMOVE_SELECTED_FILTER_FOOD,
    payload: data
  };
}

export function initFilterFood() {
  return (dispatch, getState) => {
    const { offer, members } = getState();
    const parent = Object.entries(members.activeHotels).length > 0 ? members : offer;
    const selectedFoodIds = Object.keys(parent.hotelFoodTypes).map(elem => parseInt(elem, 10));

    dispatch(initFilterFoodAction(selectedFoodIds));
  };
}

export function selectedFilterFoods(FoodIds) {
  return (dispatch) => {
    // const selectedFoodIds = [...new Set([...getState().filterFoods.selectedFoodIds, ...[FoodId]])];

    dispatch(selectedFilterFoodsAction(FoodIds));
  };
}

export function removeSelectedFilterFoods(FoodIds) {
  return (dispatch) => {
    // const selectedFoodIds = getState().filterFoods.selectedFoodIds.filter((elem) => elem !== FoodId);

    dispatch(removeSelectedFilterFoodsAction(FoodIds));
  };
}
