import moment from 'moment';
import { action } from '../../../../common/javascript/api';
import { clearSelectedRoomItems } from './booking_rooms_item';
import { clearHotelItems } from './booking_hotel';
import { initFilterFood } from './filter_food';
import { initFilterHotel } from './filter_hotels';
import { initFilterInterest } from './interest_categories';
import { clearSelectedOptionItems } from './booking_options_item';
import { initOptionList } from './booking_options_list';

export const OPEN_MEMBERS_DATE = 'OPEN_MEMBERS_DATE';
export const CLOSE_MEMBERS_DATE = 'CLOSE_MEMBERS_DATE';
export const OPEN_START_DATE = 'OPEN_START_DATE';
export const CLOSE_START_DATE = 'CLOSE_START_DATE';
export const OPEN_FINISH_DATE = 'OPEN_FINISH_DATE';
export const CLOSE_FINISH_DATE = 'CLOSE_FINISH_DATE';
export const SEARCH_HOTEL = 'SEARCH_HOTEL';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
export const OPEN_FILTER_INTEREST = 'OPEN_FILTER_INTEREST';
export const CLOSE_FILTER_INTEREST = 'CLOSE_FILTER_INTEREST';
export const OPEN_FILTER_HOTEL = 'OPEN_FILTER_HOTEL';
export const CLOSE_FILTER_HOTEL = 'CLOSE_FILTER_HOTEL';
export const OPEN_FILTER_FOOD = 'OPEN_FILTER_FOOD';
export const CLOSE_FILTER_FOOD = 'CLOSE_FILTER_FOOD';
export const OPEN_PERIOD_DATE = 'OPEN_PERIOD_DATE';
export const CLOSE_PERIOD_DATE = 'CLOSE_PERIOD_DATE';
export const OPEN_FILTER_INTEREST_FOOD = 'OPEN_FILTER_INTEREST_FOOD';
export const CLOSE_FILTER_INTEREST_FOOD = 'CLOSE_FILTER_INTEREST_FOOD';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_HOTELS = 'HIDE_HOTELS';
export const SHOW_HOTELS = 'SHOW_HOTELS';

function openMembersAction() {
  return {
    type: OPEN_MEMBERS_DATE
  };
}

function closeMembersAction() {
  return {
    type: CLOSE_MEMBERS_DATE
  };
}

function openStartDateAction() {
  return {
    type: OPEN_START_DATE
  };
}

function closeStartDateAction() {
  return {
    type: CLOSE_START_DATE
  };
}

function openFinishDateAction() {
  return {
    type: OPEN_FINISH_DATE
  };
}

function closeFinishDateAction() {
  return {
    type: CLOSE_FINISH_DATE
  };
}

function searchHotelAction(data) {
  return {
    type: SEARCH_HOTEL,
    payload: {
      ...data,
      isSearchHotel: false
    }
  };
}

function clearSelectedItemsAction() {
  return {
    type: CLEAR_SELECTED_ITEMS
  };
}

function openFilterInterestAction() {
  return {
    type: OPEN_FILTER_INTEREST
  };
}

function closeFilterInterestAction() {
  return {
    type: CLOSE_FILTER_INTEREST
  };
}

function openFilterHotelsAction() {
  return {
    type: OPEN_FILTER_HOTEL
  };
}

function closeFilterHotelsAction() {
  return {
    type: CLOSE_FILTER_HOTEL
  };
}

function openFilterFoodsAction() {
  return {
    type: OPEN_FILTER_FOOD
  };
}

function closeFilterFoodsAction() {
  return {
    type: CLOSE_FILTER_FOOD
  };
}

function openPeriodDateAction() {
  return {
    type: OPEN_PERIOD_DATE
  };
}

function closePeriodDateAction() {
  return {
    type: CLOSE_PERIOD_DATE
  };
}

function openFilterInterestFoodAction() {
  return {
    type: OPEN_FILTER_INTEREST_FOOD
  };
}

function closeFilterInterestFoodAction() {
  return {
    type: CLOSE_FILTER_INTEREST_FOOD
  };
}

function openModalAction() {
  return {
    type: OPEN_MODAL
  };
}

function closeModalAction() {
  return {
    type: CLOSE_MODAL
  };
}

function showHotelsAction() {
  return {
    type: SHOW_HOTELS
  };
}

function hideHotelsAction() {
  return {
    type: HIDE_HOTELS
  };
}

export function openMembers() {
  return (dispatch) => {
    dispatch(openMembersAction());
  };
}

export function closeMembers() {
  return (dispatch) => {
    dispatch(closeMembersAction());
  };
}

export function openStartDate() {
  return (dispatch) => {
    dispatch(openStartDateAction());
  };
}

export function closeStartDate() {
  return (dispatch) => {
    dispatch(closeStartDateAction());
  };
}

export function openFinishDate() {
  return (dispatch) => {
    dispatch(openFinishDateAction());
  };
}

export function closeFinishDate() {
  return (dispatch) => {
    dispatch(closeFinishDateAction());
  };
}

export function searchHotel() {
  return (dispatch, getState) => {
    const { offer, members, offerDates } = getState();
    const filterParams = {
      age_data: members.age_data,
      single_choice_products: members.single_choice_products,
      start_date: moment(offerDates.startDate).format('DD.MM.YYYY'),
      finish_date: moment(offerDates.finishDate).format('DD.MM.YYYY'),
      offer_id: offer.id
    };

    // console.log(filterParams);
    dispatch(openModalAction());

    action('/api/v1/booking/default_calculate', filterParams, 'POST')
      .then(
        result => {
          dispatch(searchHotelAction({
            ...result,
            previousApartmentPrograms: result.apartmentPrograms,
            previousHotels: result.hotels,
            previousApartments: result.apartments
          }));
          dispatch(closeModalAction());
          dispatch(initFilterFood());
          dispatch(initFilterInterest());
          dispatch(initFilterHotel());
          dispatch(initOptionList());
        }
      )
      .catch(
        error => console.log('Rejected:', error)
      );
  };
}

export function clearSelectedItems() {
  return (dispatch, getState) => {
    const { offerDates } = getState();

    dispatch(clearSelectedItemsAction());
    if (offerDates.isShowHotels) dispatch(clearSelectedOptionItems());
    dispatch(clearSelectedRoomItems());
    dispatch(clearHotelItems());
  };
}

export function openFilterInterest() {
  return (dispatch) => {
    dispatch(openFilterInterestAction());
  };
}

export function closeFilterInterest() {
  return (dispatch) => {
    dispatch(closeFilterInterestAction());
  };
}

export function openFilterHotels() {
  return (dispatch) => {
    dispatch(openFilterHotelsAction());
  };
}

export function closeFilterHotels() {
  return (dispatch) => {
    dispatch(closeFilterHotelsAction());
  };
}

export function openFilterFoods() {
  return (dispatch) => {
    dispatch(openFilterFoodsAction());
  };
}

export function closeFilterFoods() {
  return (dispatch) => {
    dispatch(closeFilterFoodsAction());
  };
}

export function openPeriodDate() {
  return (dispatch) => {
    dispatch(openPeriodDateAction());
  };
}

export function closePeriodDate() {
  return (dispatch) => {
    dispatch(closePeriodDateAction());
  };
}

export function openFilterInterestFood() {
  return (dispatch) => {
    dispatch(openFilterInterestFoodAction());
  };
}

export function closeFilterInterestFood() {
  return (dispatch) => {
    dispatch(closeFilterInterestFoodAction());
  };
}

export function openModal() {
  return (dispatch) => {
    dispatch(openModalAction());
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch(closeModalAction());
  };
}

export function showHotels() {
  return (dispatch) => {
    dispatch(showHotelsAction());
  };
}

export function hideHotels() {
  return (dispatch) => {
    dispatch(hideHotelsAction());
  };
}
