export const SELECTED_OPTION_ITEM = 'SELECTED_OPTION_ITEM';
export const REMOVE_SELECTED_OPTION_ITEM = 'REMOVE_SELECTED_OPTION_ITEM';
export const CLEAR_SELECTED_OPTION_ITEM = 'CLEAR_SELECTED_OPTION_ITEM';
export const OPEN_DESCRIPTION_OPTION_ITEM = 'OPEN_DESCRIPTION_OPTION_ITEM';
export const CLOSE_DESCRIPTION_OPTION_ITEM = 'CLOSE_DESCRIPTION_OPTION_ITEM';

function selectedOptionItemAction(data) {
  return {
    type: SELECTED_OPTION_ITEM,
    payload: data
  };
}

function removeSelectedOptionItemAction(data) {
  return {
    type: REMOVE_SELECTED_OPTION_ITEM,
    payload: data
  };
}

function clearSelectedOptionItemsAction() {
  return {
    type: CLEAR_SELECTED_OPTION_ITEM
  };
}

function openDescriptionOptionAction(data) {
  return {
    type: OPEN_DESCRIPTION_OPTION_ITEM,
    payload: data
  };
}

function closeDescriptionOptionAction(data) {
  return {
    type: CLOSE_DESCRIPTION_OPTION_ITEM,
    payload: data
  };
}

export function selectedOptionItem(programId, productId) {
  return (dispatch, getState) => {
    const obj = {};
    obj[programId] = productId;
    const selectedOptionIds = Object.assign({}, getState().optionItems.selectedOptionIds, obj);

    dispatch(selectedOptionItemAction(selectedOptionIds));
  };
}

export function removeSelectedOptionItem(programId, productId) {
  return (dispatch, getState) => {
    const obj = getState().optionItems.selectedOptionIds;
    delete obj[programId];
    const selectedOptionIds = Object.assign({}, obj);

    dispatch(removeSelectedOptionItemAction(selectedOptionIds));
  };
}

export function clearSelectedOptionItems() {
  return (dispatch) => {
    dispatch(clearSelectedOptionItemsAction());
  };
}

export function openDescriptionOption(optionId) {
  return (dispatch, getState) => {
    const descriptionOptionsIds = [...new Set([...getState().optionItems.descriptionOptionsIds, ...[optionId]])];

    dispatch(openDescriptionOptionAction(descriptionOptionsIds));
  };
}

export function closeDescriptionOption(optionId) {
  return (dispatch, getState) => {
    const descriptionOptionsIds = getState().optionItems.descriptionOptionsIds.filter((elem) => elem !== optionId);

    dispatch(closeDescriptionOptionAction(descriptionOptionsIds));
  };
}
