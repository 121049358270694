import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingRoomsItem from '../components/booking_rooms_item';
import * as BookingRoomsItemActions from '../actions/booking_rooms_item';
import * as SharedActions from '../actions/shared';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingRoomsItemActions, ...SharedActions }, dispatch);
}
const BookingRoomsItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingRoomsItem);

export default BookingRoomsItemContainer;
