import {
  INIT_INTEREST,
  SELECTED_INTEREST,
  REMOVE_SELECTED_INTEREST
} from '../actions/interest_categories';

const initialState = {
  selectedInterestIds: [],
  isOpenFilterInterest: false
};

const actionsMap = {
  [INIT_INTEREST]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedInterestIds: payload
    };
  },

  [SELECTED_INTEREST]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedInterestIds: payload
    };
  },

  [REMOVE_SELECTED_INTEREST]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedInterestIds: payload
    };
  }
};

export default function interestCategorieReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
