import React, { Component } from 'react';
// import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ChatApp from './ChatApp';
import configureStore from '../store/configureStore';
import { initChat } from '../actions/chat';
// import { loadState, saveState } from '../store/localStorage';
// import { throttle } from 'lodash/throttle';
// import { normalize } from 'normalizr';
// import * as schemas from '../schema/index';

// const persistedState = loadState();
const store = configureStore();

export default class Root extends Component {
  componentWillMount() {
    // console.log(this.props.data.chat);
    // const { offerId, channels, comments } = this.props.data.chat;
    // console.log(
    // 'normalized',
    // normalize(this.props.data.chat, schemas.channelsListSchema),
    // normalize(this.props.data.chat, schemas.commentsListSchema),
    // normalize(this.props.data.chat, schemas.ordersListSchema),
    // ...channels
    // );
    store.dispatch(initChat(this.props.data));
  }

  // componentDidMount() {
  //   store.subscribe(throttle(() => {
  //     saveState({
  //       chat: store.getState().chat.toJS()
  //     });
  //   }, 1000));
  // }

  render() {
    return (
      <Provider store={store}>
        <ChatApp />
      </Provider>
    );
  }
}
