import React, { PureComponent, Fragment } from 'react';
import AdultsCounterContainer from '../../containers/adults_counter';
import ChildsCounterContainer from '../../containers/childs_counter';
import KidsAgesContainer from '../../containers/kids_ages';
import NotesContainer from '../../containers/notes';
import { declOfNum } from '../../../../../common/javascript/api';
import Popup from '../../../components/Popup';
import Action from '../../../components/Action';
import Loader from '../../../components/Loader';

import * as styles from './Members.module.scss';

export default class Members extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSelectBoxClick = this.handleSelectBoxClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleClickFormClose = this.handleClickFormClose.bind(this);
  }

  componentDidMount() {
    const { initMembers } = this.props;

    initMembers();
  }

  handleClickFormClose() {
    const {
      isOpenMembers,
      closeMembers,
      closeForm,
      previousMembers
    } = this.props;

    closeForm();
    previousMembers();

    if (isOpenMembers) {
      closeMembers();
    }
  }

  handleSelectBoxClick() {
    const {
      isOpenMembers,
      openMembers,
      closeMembers,
      openForm
    } = this.props;

    if (isOpenMembers) {
      closeMembers();
    } else {
      openMembers();
      openForm();
    }
  }

  handleSaveClick() {
    const {
      addParams,
      closeMembers,
      searchHotel,
      clearSelectedItems,
      saveCurrentMembers,
      closeForm
    } = this.props;

    closeForm();
    addParams();
    closeMembers();
    clearSelectedItems();
    searchHotel();
    saveCurrentMembers();
  }

  quantitySelectedMembers() {
    const { offer, members } = this.props;
    let adultsText = declOfNum(members.adultsCount, ['взрослый', 'взрослых', 'взрослых']);
    let childs = null;

    if (offer.maxChildsCount === 0) {
      childs = null;
      adultsText = declOfNum(members.adultsCount, ['человек', 'человека', 'человек']);
    }

    if (offer.maxChildsCount > 0 && members.childsCount > 0) {
      childs = (
        <Fragment>
          <strong>{members.childsCount}</strong>
          &nbsp;
          {declOfNum(members.childsCount, ['ребёнок', 'детей', 'детей'])}
        </Fragment>
      );
    }

    if (offer.maxChildsCount > 0 && members.childsCount === 0) {
      childs = <Fragment>без детей</Fragment>;
    }

    if (childs !== null) {
      childs = (
        <span>
          {childs}
        </span>
      );
    }

    return (
      <Fragment>
        <span>
          <strong>{members.adultsCount}</strong>
          &nbsp;
          {adultsText}
          {childs ? ',' : ''}
          &nbsp;
        </span>
        {childs}
      </Fragment>
    );
  }

  renderSaveButton() {
    const { offer, members, offerDates } = this.props;

    if ((offer.maxAdultsCount < members.adultsCount)
      || (offer.maxChildsCount < members.childsCount)
      || (!offerDates.startDate || !offerDates.finishDate)
      || (Object.values(members.childs).filter(e => e === -1).length !== 0)) {
      return (
        <Action
          themes={['Button', 'ButtonSecondary', 'Fullwidth', 'ButtonCommon']}
          disabled
          onClick={null}
        >
          Сохранить
        </Action>
      );
    } else {
      return (
        <Action
          themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
          onClick={this.handleSaveClick}
        >
          Сохранить
        </Action>
      );
    }
  }

  render() {
    const { isOpenMembers, members, offer, isFormOpen } = this.props;
    const selectedMembers = this.quantitySelectedMembers();
    const offerType = offer.offers_types[0].id;
    // const text = 'ИДЕТ ПОИСК КЛАССНЫХ МЕСТ';
    const text = '';
    const isOneAdult = offer.maxAdultsCount === 1 && offer.maxChildsCount === 0;

    return (
      <Fragment>
        <Loader view={members.isSearchHotel} />
        <div
          className='booking__select-item booking__select-item-small'
        >
          <div
            className='booking__select-item-content'
          >
            <div className='booking__select-item-content-title'>Участники</div>
            <div
              className={`booking__selectbox ${isOpenMembers ? 'booking__selectbox--open' : ''}`}
            >
              <div
                className='booking__selectbox-head'
                onClick={isOneAdult ? null : this.handleSelectBoxClick}
              >
                <div className='booking__selectbox-head-text'>{selectedMembers}</div>
                {isOneAdult
                ? ''
                :
                  <div className='booking__selectbox-multi-other'>изменить</div>
                }
              </div>
              <div
                className='booking__selectbox-dropdown'
              >
                <div
                  className='booking__selectbox-head'
                  onClick={isOneAdult ? null : this.handleSelectBoxClick}
                >
                  <div className='booking__selectbox-head-text'>{selectedMembers}</div>
                </div>
                <div
                  className='booking__selectbox-dropdown-content'
                >
                  <Popup
                    show={isFormOpen}
                    onClose={this.handleClickFormClose}
                    variant='small'
                    withHeader
                    footer={(
                      <div className='booking__selectbox-btn-wrap'>
                        {this.renderSaveButton()}
                      </div>
                    )}
                  >
                    {/* <div className='booking__select-item-content-title'>Участники</div> */}
                    <div className={styles.Members}>
                      <AdultsCounterContainer />
                      <ChildsCounterContainer />
                    </div>
                    <KidsAgesContainer />
                    {/* <NotesContainer /> */}
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
