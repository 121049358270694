import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import BlockMessages from '../components/block_messages';
import * as BlockMessagesActions from '../actions/block_messages';
import { getCommentsVisible } from '../selectors/index';

function mapStateToProps(state) {
  return {
    active: state.chat.get('active'),
    asyncLoading: state.chat.get('asyncLoading'),
    flipped: state.chat.get('flipped'),
    page: state.chat.get('page'),
    channels: Map(state.chat.get('channels')).toJS(),
    comments: getCommentsVisible(state.chat).toJS(),
    scrollLoadThreshold: state.chat.get('scrollLoadThreshold'),
    offerId: state.chat.get('offerId')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BlockMessagesActions, dispatch);
}

const BlockMessagesApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockMessages);

export default BlockMessagesApp;
