export default function Ga(params) {
  // console.log('Ga');
  const action = params.split('_')[1];
  const label = params.split('_')[0];

  // ga('send', 'event', {
  //   eventCategory: 'Offer',
  //   eventAction: action,
  //   eventLabel: label
  // });
}
