export default function initOffset(clickEvent, blockClassName) {
  const links = document.querySelectorAll(blockClassName);

  if (links.length > 0) {
    links.forEach((item) => {
      item.removeEventListener('click', clickEvent, false);
      item.addEventListener('click', clickEvent, false);
    });
  }
}
