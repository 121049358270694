import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import KidsAges from '../components/KidsAges';
import * as MembersActions from '../actions/members';

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(MembersActions, dispatch);
}
const KidsAgesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KidsAges);

export default KidsAgesContainer;
