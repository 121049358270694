import mergeable from './merge_reducer';
import {
  OPEN_START_DATE,
  CLOSE_START_DATE,
  OPEN_FINISH_DATE,
  CLOSE_FINISH_DATE,
  OPEN_MEMBERS_DATE,
  CLOSE_MEMBERS_DATE,
  SEARCH_HOTEL,
  CLEAR_SELECTED_ITEMS,
  OPEN_FILTER_INTEREST,
  CLOSE_FILTER_INTEREST,
  OPEN_FILTER_HOTEL,
  CLOSE_FILTER_HOTEL,
  OPEN_FILTER_FOOD,
  CLOSE_FILTER_FOOD,
  OPEN_PERIOD_DATE,
  CLOSE_PERIOD_DATE,
  OPEN_FILTER_INTEREST_FOOD,
  CLOSE_FILTER_INTEREST_FOOD,
  OPEN_MODAL,
  CLOSE_MODAL,
  HIDE_HOTELS,
  SHOW_HOTELS
} from '../actions/shared';

const initialState = {
  isOpenFromDay: false,
  isOpenToDay: false,
  isOpenMembers: false,
  isSearchHotel: false,
  subOfferGroups: {},
  isOpenFilterInterest: false,
  isOpenFilterHotel: false,
  isOpenFilterFood: false,
  isOpenPeriodDate: false,
  isOpenFilterInterestFood: false,
  activeHotels: {},
  interestCategories: {},
  hotelFoodTypes: {},
  isShowHotels: true
};

const actionsMap = {
  [OPEN_START_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: true,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_START_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_FINISH_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: true,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_FINISH_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_MEMBERS_DATE]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: true,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_MEMBERS_DATE]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [SEARCH_HOTEL]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      apartmentPrograms: payload.apartmentPrograms,
      hotels: payload.hotels,
      apartments: payload.apartments,
      subOfferGroups: payload.subOfferGroups,
      subOffers: payload.subOffers,
      singleChoiceProductPrices: payload.singleChoiceProductPrices,
      activeHotels: payload.activeHotels,
      interestCategories: payload.interestCategories,
      hotelFoodTypes: payload.hotelFoodTypes,
      periodDates: payload.periodDates,
      periodDatesGroupByMonth: payload.periodDatesGroupByMonth,
      minPriceByPeriod: payload.minPriceByPeriod,
      previousApartmentPrograms: payload.apartmentPrograms,
      previousHotels: payload.hotels,
      previousApartments: payload.apartments
    };
  },

  [CLEAR_SELECTED_ITEMS]: (state) => {
    return {
      ...state,
      apartmentPrograms: {},
      hotels: {},
      apartments: {},
      subOfferGroups: {},
      subOffers: {}
    };
  },

  [OPEN_FILTER_INTEREST]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: true,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_FILTER_INTEREST]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_FILTER_HOTEL]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: true,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_FILTER_HOTEL]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_FILTER_FOOD]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: true,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_FILTER_FOOD]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_PERIOD_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: true,
      isOpenFilterInterestFood: false
    };
  },

  [CLOSE_PERIOD_DATE]: (state) => {

    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_FILTER_INTEREST_FOOD]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: true
    };
  },

  [CLOSE_FILTER_INTEREST_FOOD]: (state) => {
    return {
      ...state,
      isOpenFromDay: false,
      isOpenToDay: false,
      isOpenMembers: false,
      isOpenFilterInterest: false,
      isOpenFilterHotel: false,
      isOpenFilterFood: false,
      isOpenPeriodDate: false,
      isOpenFilterInterestFood: false
    };
  },

  [OPEN_MODAL]: (state) => {
    return {
      ...state,
      isSearchHotel: true
    };
  },

  [CLOSE_MODAL]: (state) => {
    return {
      ...state,
      isSearchHotel: false
    };
  },

  [SHOW_HOTELS]: (state) => {
    return {
      ...state,
      isShowHotels: true
    };
  },

  [HIDE_HOTELS]: (state) => {
    return {
      ...state,
      isShowHotels: false
    };
  }
};

function sharedReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}

export default mergeable(sharedReducer);
