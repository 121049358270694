export const INIT_INTEREST = 'INIT_INTEREST';
export const SELECTED_INTEREST = 'SELECTED_INTEREST';
export const REMOVE_SELECTED_INTEREST = 'REMOVE_SELECTED_INTEREST';

function initFilterInterestAction(data) {
  return {
    type: INIT_INTEREST,
    payload: data
  };
}

function selectedInterestAction(data) {
  return {
    type: SELECTED_INTEREST,
    payload: data
  };
}

function removeSelectedInterestAction(data) {
  return {
    type: REMOVE_SELECTED_INTEREST,
    payload: data
  };
}

export function initFilterInterest() {
  return (dispatch, getState) => {
    const { offer, members } = getState();
    const parent = Object.entries(members.interestCategories).length > 0 ? members : offer;
    const selectedInrerestIds = Object.keys(parent.interestCategories).map(elem => parseInt(elem, 10));

    dispatch(initFilterInterestAction(selectedInrerestIds));
  };
}

export function selectedInterest(interestIds) {
  return (dispatch) => {
    // const selectedInterestIds = [...new Set([...getState().interestCategories.selectedInterestIds, ...[interestId]])];

    dispatch(selectedInterestAction(interestIds));
  };
}

export function removeSelectedInterest(interestIds) {
  return (dispatch) => {
    // const selectedInterestIds = getState().interestCategories.selectedInterestIds.filter((elem) => elem !== interestId);

    dispatch(removeSelectedInterestAction(interestIds));
  };
}
