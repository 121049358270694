import React, { Component } from 'react';
import PersonApp from '../containers/PersonApp';

export default class People extends Component {
  shouldComponentUpdate() {
    if (this.props.asyncLoading) return false;
    return true;
  }

  render() {
    const { channels } = this.props;
    const person = Object.getOwnPropertyNames(channels).map((val) => {
      return (
        <PersonApp
          key={channels[val].id}
          id={channels[val].id}
          name={channels[val].name}
          description={channels[val].description}
          membersCount={channels[val].membersCount}
        />
      );
    });

    return (
      <div>
        {person}
      </div>
    );
  }
}
