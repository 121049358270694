import React, { PureComponent } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Action from '../../../components/Action';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import MinusIcon from '../../../../../../icons/minus.svg';
import PlusIcon from '../../../../../../icons/plus.svg';

import * as styles from './Counter.module.scss';

export default class Counter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleMinusCounterClick = this.handleMinusCounterClick.bind(this);
    this.handlePlusCounterClick = this.handlePlusCounterClick.bind(this);
  }

  handleMinusCounterClick() {
    const { handleMinusClick } = this.props;

    handleMinusClick();
  }

  handlePlusCounterClick() {
    const { handlePlusClick } = this.props;

    handlePlusClick();
  }

  render() {
    const { count, disableMinus, disablePlus, note } = this.props;

    return (
      <div className={styles.Counter}>
        <Action
          noTheme
          className={styles.Counter__control}
          onClick={this.handleMinusCounterClick}
          disabled={disableMinus}
        >
          <IconSvg icon={MinusIcon} />
        </Action>

        <span className={styles.Counter__counter}>{count}</span>

        <Action
          noTheme
          className={styles.Counter__control}
          onClick={this.handlePlusCounterClick}
          disabled={disablePlus}
        >
          <IconSvg icon={PlusIcon} />
        </Action>
      </div>
    );
  }
}
