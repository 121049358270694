import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OfferDates from '../components/offer_dates';
import * as OfferDatesActions from '../actions/offer_dates';
import * as SharedActions from '../actions/shared';
import * as OptionsListAction from '../actions/booking_options_list';
import WrapHook from '../components/WrapHook';

function mapStateToProps(state) {
  return {
    ...state,
    isOpenMembers: state.members.isOpenMembers,
    isOpenFromDay: state.offerDates.isOpenFromDay,
    isOpenToDay: state.offerDates.isOpenToDay,
    isOpenFilterInterest: state.interestCategories.isOpenFilterInterest,
    isOpenFilterHotel: state.filterHotels.isOpenFilterHotel,
    isOpenFilterFood: state.filterFoods.isOpenFilterFood,
    isOpenPeriodDate: state.offerDates.isOpenPeriodDate
  };
}

function mapDispatchToProps(dispatch) {
  // return bindActionCreators(OfferDatesActions, dispatch);
  return bindActionCreators({ ...OfferDatesActions, ...SharedActions, ...OptionsListAction }, dispatch);
}
const OfferDatesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapHook(OfferDates));

export default OfferDatesContainer;
