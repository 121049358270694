// import { onlineChatLocalStorage } from '../store/localStorage';

export const INIT_CHAT = 'INIT_CHAT';
export const SUBSCRIPTION_CHANNEL = 'SUBSCRIPTION_CHANNEL';
export const UNSUBSCRIPTION_CHANNEL = 'UNSUBSCRIPTION_CHANNEL';
export const SOCKET_OPEN = 'SOCKET_OPEN';
export const NEW_SOCKET_MESSAGE = 'NEW_SOCKET_MESSAGE';
export const DEL_SOCKET_MESSAGE = 'DEL_SOCKET_MESSAGE';
export const SOCKET_CLOSE = 'SOCKET_CLOSE';
export const RESET_CHANNEL = 'RESET_CHANNEL';

export function setData(chat) {
  return {
    type: INIT_CHAT,
    payload: chat
  };
}

export function subscriptions(url) {
  return {
    type: SUBSCRIPTION_CHANNEL,
    payload: url
  };
}

export function unsubscriptions() {
  return {
    type: UNSUBSCRIPTION_CHANNEL
  };
}

export function onSocketOpen(url) {
  return {
    type: SOCKET_OPEN,
    payload: url
  };
}

export function onSocketAddMessage(msg) {
  return {
    type: NEW_SOCKET_MESSAGE,
    payload: msg
  };
}

export function onSocketDelMessage(msg) {
  return {
    type: DEL_SOCKET_MESSAGE,
    payload: msg
  };
}

export function onSocketClose() {
  return {
    type: SOCKET_CLOSE
  };
}

function resetChannel() {
  return {
    type: RESET_CHANNEL
  };
}

export function socketOpen() {
  return (dispatch, getState) => {
    const { channelKeys } = getState().chat.toJS();
    let requestParams = '';
    const hostName = window.location.hostname.split('.').slice(-2).join('.');
    const ip = window.location.hostname;

    if (hostName === 'localhost') {
      requestParams = 'ws://127.0.0.1:9222/?';
    } else if (hostName === 'spotway.ru') {
      requestParams = `wss://${hostName}/notify?`;
    } else if (hostName === 'infospotway.ru') {
      requestParams = `ws://${hostName}/notify?`;
    } else if (ip === '95.213.204.29') {
      requestParams = `ws://${ip}/notify?`;
    } else if (ip === '1.0.4.59') {
      requestParams = 'ws://1.0.4.59:9222/?';
    }

    channelKeys.forEach((item, i) => {
      requestParams = requestParams.concat(`key${i}=${item}&`);
    });
    dispatch(onSocketOpen(requestParams));
  };
}

export function socketClose() {
  return (dispatch) => {
    dispatch(onSocketClose());
  };
}

export function socketAddMessage(msg) {
  return (dispatch) => {
    if (gon.current_user_id === msg.userId) return;
    dispatch(onSocketAddMessage(msg));
  };
}

export function socketDelMessage(msg) {
  return (dispatch) => {
    if (gon.current_user_id === msg.userId) return;
    dispatch(onSocketDelMessage(msg));
  };
}

export function initChat(chat) {
  return (dispatch) => {
    // console.log('INIT_CHAT', chat.comments);
    // Object.getOwnPropertyNames(chat.comments).map((id) => {
    //   // console.log('INIT_CHAT', chat, chat.comments[id], id);
    //   onlineChatLocalStorage.setItem(id, chat.comments[id]);
    // });
    dispatch(setData(chat));
  };
}

export function resetChannelAction() {
  return (dispatch) => {
    dispatch(resetChannel());
  };
}
