import React, { PureComponent } from 'react';
import cn from 'classnames';

import Counter from '../Counter';
import * as styles from './ChildsCounter.module.scss';

export default class ChildsCounter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleMinusClick = this.handleMinusClick.bind(this);
    this.handlePlusClick = this.handlePlusClick.bind(this);
  }

  handleMinusClick() {
    const { members, minusChilds, minusKidsAges } = this.props;

    minusChilds();
    minusKidsAges(members.childsCount, 0);
  }

  handlePlusClick() {
    const { members, plusChilds, plusKidsAges } = this.props;

    plusChilds();
    plusKidsAges(members.childsCount + 1, -1);
  }

  alertNotes() {
    const { offer, members } = this.props;

    if (offer.maxChildsCount <= members.childsCount && offer.maxChildsCount !== 0) {
      return (
        <div className={cn(styles.ChildsCounter__row, styles.ChildsCounter__invalid)}>
          <span>
            Достигнуто макс. кол-во детей в одном номере. Если вас едет больше, то просто оформите несколько заказов!
          </span>
        </div>
      );
    }

    return null;
  }

  render() {
    const { offer, members } = this.props;

    if (offer.maxChildsCount === 0) return '';

    return (
      <div className={styles.ChildsCounter}>
        <div className={styles.ChildsCounter__row}>
          <div className={styles.ChildsCounter__labelWrap}>
            <strong>Дети</strong>
            <div className={styles.ChildsCounter__hint}>от 0 до 17 лет</div>
          </div>
          <Counter
            handleMinusClick={this.handleMinusClick}
            handlePlusClick={this.handlePlusClick}
            count={members.childsCount}
            disableMinus={members.childsCount <= 0}
            disablePlus={offer.maxChildsCount <= members.childsCount}
            note={this.alertNotes()}
          />
        </div>
        {this.alertNotes()}
      </div>
    );
  }
}
