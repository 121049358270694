export const ADD_REPLY_MSG = 'ADD_REPLY_MSG';
export const DELETE_MSG = 'DELETE_MSG';

export function addReplyMsg(reply) {
  return {
    type: ADD_REPLY_MSG,
    payload: reply
  };
}

export function deleteMsg(msg) {
  return {
    type: DELETE_MSG,
    payload: msg
  };
}

export function addReplyMsgAction(replyMsg, replyAuthor) {
  return (dispatch) => {
    const reply = { replyIdMsg: replyMsg, attachMsgAuthor: replyAuthor };

    dispatch(addReplyMsg(reply));
  };
}

export function deleteMsgAction(channelId, msgId) {
  return (dispatch) => {
    const removeComment = {
      comment_id: msgId,
      channel_id: channelId
    };
    const urlAction = `/channels/${channelId}/comments/${msgId}?${$.param({ 'authenticity_token': $('[name="csrf-token"]')[0].content })}`;

    $.ajax({
      url: urlAction,
      type: 'DELETE',
      dataType: 'json',
      data: removeComment,
      cache: false,
      success(data) {
        console.log(`delete ${data.status}`);
        if (data.status === 'ok') {
          console.log(`dispatch delete ${data.status}`);
          dispatch(deleteMsg(msgId));
        }
      },
      error(xhr, status, err) {
        console.error(urlAction, status, err.toString());
      }
    });
  };
}
