import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InterestCategories from '../components/interest_categories';
import * as InterestCategoriesActions from '../actions/interest_categories';
import * as SharedActions from '../actions/shared';

function mapStateToProps(state) {
  return {
    ...state,
    isOpenMembers: state.members.isOpenMembers,
    isOpenFromDay: state.offerDates.isOpenFromDay,
    isOpenToDay: state.offerDates.isOpenToDay,
    isOpenFilterInterest: state.interestCategories.isOpenFilterInterest,
    isOpenFilterHotel: state.filterHotels.isOpenFilterHotel,
    isOpenFilterFood: state.filterFoods.isOpenFilterFood
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...InterestCategoriesActions, ...SharedActions }, dispatch);
}
const InterestCategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterestCategories);

export default InterestCategoriesContainer;
