import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Bubble from '../components/bubble';
import * as BubbleActions from '../actions/bubble';

function mapStateToProps(state) {
  return {
    active: state.chat.get('active'),
    asyncLoading: state.chat.get('asyncLoading'),
    newCommentsIds: Map(state.chat.get('newCommentsIds')).toJS()
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BubbleActions, dispatch);
}
const BubbleApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(Bubble);

export default BubbleApp;
