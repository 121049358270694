export default function showHideCallbackPhoneLink() {
  const link = document.querySelector('.js-header__show-form-callback-phone');
  const menu = document.querySelector('.header__menu-wrap--open');

  if (menu !== null) {
    link.style.visibility = 'hidden';
  } else {
    link.style.visibility = 'visible';
  }
}
