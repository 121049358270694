/* React UJS (call after Turbolinks) */
import ReactRailsUJS from 'react_ujs';

// React Components
// import InstagramEmbed from 'react-instagram-embed';
import InputPhone from '../common/javascript/components/input_phone/input_phone';
import Root from './javascript/chat/containers/Root';
import SubmitButton from '../common/javascript/components/submit_button/submit_button';
import Booking from './javascript/booking/containers';
import Slider from './javascript/components/Slider';

/* Swiper */
// important to import from dist, cause other import breaks in safari.
import {
  initOffsetContact,
  initOffsetEvent,
  initMassMedia,
  initOfferType,
  initCallbackForm,
  initShowFormCallbackPhone,
  initPartnerForm
} from './javascript/main_page';
import initElementScrollView from '../common/javascript/element_scroll_view/initElementScrollView';
import initBlockTemplates from './javascript/block_templates/initBlockTemplates';
import { initOffsetGoToBooking, initOffsetMenuTable } from './javascript/events';
import EventPageMenu from './javascript/events/event_page_menu/EventPageMenu';
// import { initJivoSite } from './jivo_site/initJivoSite';
import initOffsetJivoOpen from './javascript/jivo_site/initOffsetJivoOpen';
import { Goal } from './javascript/Vk';
import { InitBtnLogout } from '../common/javascript/api';
import {
  SpotwayLogoDefaultGradient,
  RecallGradient,
  InstagramGradient,
  MirGradient,
  VisaGradient
} from '../common/javascript/components/LinerGradient';
import AuthSiteHeader from './javascript/components/SiteHeader/AuthSiteHeader';
import SiteFooter from './javascript/components/SiteFooter';
import BlocksSlider from './javascript/components/BlocksSlider';
import VideosBlock from './javascript/components/VideosBlock';
import PressSection from './javascript/components/MainPage/PressSection';
import PartnersSection from './javascript/components/MainPage/PartnersSection/PartnersSection';
import BonusesList from './javascript/components/BonusesList/BonusesList';
import ChpuEvents from './javascript/components/ChpuEvents/ChpuEvents';

ReactRailsUJS.detectEvents();
window.Root = Root;
window.InputPhone = InputPhone;
window.SubmitButton = SubmitButton;
window.Booking = Booking;
// window.InstagramEmbed = InstagramEmbed;
window.EventPageMenu = EventPageMenu;
window.Slider = Slider;
window.SpotwayLogoDefaultGradient = SpotwayLogoDefaultGradient;
window.RecallGradient = RecallGradient;
window.InstagramGradient = InstagramGradient;
window.MirGradient = MirGradient;
window.VisaGradient = VisaGradient;
window.AuthSiteHeader = AuthSiteHeader;
window.SiteFooter = SiteFooter;
window.BlocksSlider = BlocksSlider;
window.VideosBlock = VideosBlock;
window.PressSection = PressSection;
window.PartnersSection = PartnersSection;
window.BonusesList = BonusesList;
window.ChpuEvents = ChpuEvents;

// function clickMenuHamburger() {
//   const menuHamburger = $('.header__menu-btn');

//   menuHamburger.on('click', () => {
//     $('body').toggleClass('header__menu--open');
//     $('.header__menu-wrap').toggleClass('header__menu-wrap--open');
//     $('.header__menu-hamburger').toggleClass('state-active');
//     showHideCallbackPhoneLink();
//   });

//   $('.js-header__menu-hamburger2').on('click', (e) => {
//     if ($(window).width() < 1000) {
//       e.preventDefault();
//       menuHamburger.click();
//     }
//   });
// }

// detect end of scroll
let isDocumentLoaded = false;

// document.addEventListener('turbolinks:load', () => {
document.addEventListener('DOMContentLoaded', () => {
  // clickMenuHamburger();
  initOffsetContact();
  initOffsetEvent();
  initMassMedia();
  initOfferType();
  initCallbackForm();
  initShowFormCallbackPhone();
  initPartnerForm();
  initBlockTemplates();
  initOffsetGoToBooking();
  initOffsetMenuTable();
  // initJivoSite();
  initOffsetJivoOpen();
  InitBtnLogout();
});

const interval = setInterval(() => {
  if (document.readyState === 'complete') {
    clearInterval(interval);
    initElementScrollView();
    isDocumentLoaded = true;
  }
}, 50);

// ******* header *******
let prevScrollpos = window.pageYOffset;

window.onscroll = () => {
  // const scroll = window.scrollY;

  if (isDocumentLoaded && ((window.innerHeight + Math.ceil(window.pageYOffset)) >= document.querySelector('.wrapper').clientHeight)) {
    Goal('scroll_pageend');
  }

  // if (scroll >= 100) {
  //   document.querySelector('.header').classList.add('header_fixed_top');
  // } else {
  //   document.querySelector('.header').classList.remove('header_fixed_top');
  // }

  if (window.innerWidth >= 749) return;

  const currentScrollPos = window.pageYOffset;

  if (prevScrollpos > currentScrollPos) {
    // document.querySelector('.header').style.top = '0';
  } else {
    // document.querySelector('.header').style.top = '-150px';
  }
  prevScrollpos = currentScrollPos;
};
// ***********************
