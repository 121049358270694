import {
  INIT_FILTER_HOTEL,
  SELECTED_FILTER_HOTEL,
  REMOVE_SELECTED_FILTER_HOTEL
} from '../actions/filter_hotels';

const initialState = {
  selectedHotelIds: [],
  isOpenFilterHotel: false
};

const actionsMap = {
  [INIT_FILTER_HOTEL]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedHotelIds: payload
    };
  },

  [SELECTED_FILTER_HOTEL]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedHotelIds: payload
    };
  },

  [REMOVE_SELECTED_FILTER_HOTEL]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedHotelIds: payload
    };
  }
};

export default function filterHotelsReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
