import React, { PureComponent } from 'react';
import BookingSelectboxDropdown from './BookingSelectboxDropdown';
import Popup from '../../components/Popup';

export default class FilterFoods extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFoodIds: [],
      initSelectedFoodIds: []
    };

    this.handleSelectedInput = this.handleSelectedInput.bind(this);
    this.handleClearSelectedInput = this.handleClearSelectedInput.bind(this);
    this.handleSelectedAllInput = this.handleSelectedAllInput.bind(this);
    this.handleSelectBoxClick = this.handleSelectBoxClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    const { initFilterFood, selectedFoodIds } = this.props;

    initFilterFood();
    this.initStateSelectedFoodIds();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.entries(props.activeFoodIds || []).length === 0) return null;
    // if (Object.entries(state.initSelectedFoodIds).length === 0) return null;

    if (props.activeFoodIds.filter(e => !state.initSelectedFoodIds.includes(e)).length > 0) {
      return {
        selectedFoodIds: props.activeFoodIds,
        initSelectedFoodIds: props.activeFoodIds
      };
    }

    return null;
  }

  initStateSelectedFoodIds() {
    const { members, offer } = this.props;
    const parentEl = Object.entries(members.activeHotels).length > 0 ? members : offer;
    this.setState(() => ({
      selectedFoodIds: Object.keys(parentEl.hotelFoodTypes).map(elem => parseInt(elem, 10)),
      initSelectedFoodIds: Object.keys(parentEl.hotelFoodTypes).map(elem => parseInt(elem, 10))
    }));
  }

  handleSelectBoxClick() {
    const { isOpenFilterFood, openFilterFoods, closeFilterFoods, filterFoods } = this.props;

    if (isOpenFilterFood) {
      this.setState(() => ({
        selectedFoodIds: Object.assign([], filterFoods.selectedFoodIds)
      }));
      closeFilterFoods();
    } else {
      openFilterFoods();
    }
  }

  handleSelectedInput(event) {
    const { selectedFoodIds } = this.state;
    const input = event.target;
    const foodId = parseInt(input.value, 10);
    const newSelectedFoodIds = (input.checked)
      ? [...new Set([...selectedFoodIds, ...[foodId]])]
      : selectedFoodIds.filter(elem => elem !== foodId);

    this.setState(() => ({
      selectedFoodIds: newSelectedFoodIds
    }));
  }

  handleClearSelectedInput() {
    this.setState(() => ({
      selectedFoodIds: []
    }));
  }

  handleSelectedAllInput() {
    this.initStateSelectedFoodIds();
  }

  handleSaveClick() {
    const { selectedFoodIds } = this.state;
    const { closeFilterFoods, selectedFilterFoods } = this.props;

    selectedFilterFoods(selectedFoodIds);
    closeFilterFoods();
  }

  render() {
    const { selectedFoodIds } = this.state;
    const { offer, members, isOpenFilterFood } = this.props;
    const parentEl = Object.entries(members.apartmentPrograms).length > 0 ? members : offer;
    const qntFoodTypes = Object.entries(parentEl.hotelFoodTypes).length;
    const oneFoodTypeName = Object.values(parentEl.hotelFoodTypes).length > 0 ? Object.values(parentEl.hotelFoodTypes)[0].name : 'Питание';

    return (
      <div className='booking__select-item booking__select-item-small'>
        <div className='booking__select-item-content'>
          <div
            className={`booking__selectbox ${isOpenFilterFood ? 'booking__selectbox--open' : ''}`}
          >
            <div
              className='booking__selectbox-head'
              onClick={qntFoodTypes > 1 ? this.handleSelectBoxClick : null}
            >
              <div className='booking__selectbox-head-text'>{qntFoodTypes > 1 ? 'Питание' : oneFoodTypeName}</div>
              {qntFoodTypes > 1 && (
                <div className='booking__selectbox-filter'>фильтр</div>
              )}
            </div>
          </div>
        </div>
        {qntFoodTypes > 1 && (
          <Popup show={isOpenFilterFood} onClose={this.handleSelectBoxClick} variant='small' withHeader={false}>
            <BookingSelectboxDropdown
              handleInputChange={this.handleSelectedInput}
              listTitle='Питание'
              nodes={parentEl.hotelFoodTypes}
              prefix='foodTypes'
              checkedItems={selectedFoodIds}
              onClick={this.handleSaveClick}
              onClearClick={this.handleClearSelectedInput}
              onSelectAllClick={this.handleSelectedAllInput}
              disabledSave={selectedFoodIds.length === 0}
            />
          </Popup>
        )}
      </div>
    );
  }
}
