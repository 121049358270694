import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import BubbleApp from '../containers/BubbleApp';

let supportsPassive = false;
let timerScrolling = 0;

try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      supportsPassive = true;
    }
  });
  window.addEventListener('test', null, opts);
} catch (e) {
  console.log('supportsPassive', e);
}


export default class BlockMessages extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isShowScrollToBottom: false
    };

    this.rafRequestId = null;
    this.scrollTop = 0;
    this.scrollHeight = undefined;

    this.pollScroll = this.pollScroll.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.handleClickScrollToBottom = this.handleClickScrollToBottom.bind(this);

    this.scrollableDomEl = React.createRef();
    this.scrollToBottom = React.createRef();
  }

  componentDidMount() {
    const { flipped } = this.props;
    // const scrollableDomEl = ReactDOM.findDOMNode(this);

    const heightDifference = flipped
      ? this.scrollableDomEl.current.scrollHeight - this.scrollableDomEl.current.clientHeight
      : 0;

    this.scrollableDomEl.current.scrollTop = heightDifference;
    this.scrollTop = heightDifference;

    // Unless passive events are supported, we must not hook onScroll event
    // directly - that will break hardware accelerated scrolling. We poll it
    // with requestAnimationFrame instead.
    if (supportsPassive) {
      this.scrollableDomEl.current.addEventListener('scroll', this.onScroll, { passive: true });
    } else {
      this.rafRequestId = window.requestAnimationFrame(this.pollScroll);
    }

    const msie = window.navigator.userAgent.indexOf('Trident');

    if (msie > 0) {
      this.scrollableDomEl.current.addEventListener('scroll', this.onScroll);
    }
  }

  componentDidUpdate() {
    this.updateScrollTop();
    this.showButtonToBottom();
  }

  componentWillUnmount() {
    // const scrollableDomEl = ReactDOM.findDOMNode(this);

    this.scrollableDomEl.current.scrollableDomEl.removeEventListener('scroll', this.onScroll, { passive: true });
    window.cancelAnimationFrame(this.rafRequestId);

    this.clearTimer();

    const msie = window.navigator.userAgent.indexOf('Trident');

    if (msie > 0) {
      this.scrollableDomEl.current.scrollableDomEl.removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll() {
    // const domNode = ReactDOM.findDOMNode(this);

    if (this.scrollableDomEl.current.scrollTop !== this.scrollTop) {
      if (this.shouldTriggerLoad(this.scrollableDomEl.current)) {
        this.loadMoreComments();
      }
      this.updateScrollTop();
      this.showButtonToBottom();
    }
  }

  handleClickScrollToBottom() {
    // const node = ReactDOM.findDOMNode(this);

    this.setState({ isShowScrollToBottom: false });
    this.scrollableDomEl.current.scrollTop = this.scrollableDomEl.current.scrollHeight;
  }

  pollScroll() {
    const { active, asyncLoading } = this.props;

    if (!active) return;
    if (asyncLoading) return;

    this.onScroll();
    this.rafRequestId = window.requestAnimationFrame(this.pollScroll);
  }

  isPassedThreshold(scrollLoadThreshold, scrollTop, scrollHeight, clientHeight) {
    const { flipped } = this.props;

    return flipped
      ? scrollTop <= scrollLoadThreshold
      : scrollTop >= (scrollHeight - clientHeight - scrollLoadThreshold);
  }

  shouldTriggerLoad(domNode) {
    const { flipped, scrollLoadThreshold, asyncLoading } = this.props;
    const passedThreshold = this.isPassedThreshold(
      scrollLoadThreshold,
      domNode.scrollTop,
      domNode.scrollHeight,
      domNode.clientHeight
    );

    return passedThreshold && !asyncLoading;
  }

  updateScrollTop() {
    const { flipped, scrollLoadThreshold } = this.props;
    // const scrollableDomEl = ReactDOM.findDOMNode(this);

    let newScrollTop = this.scrollableDomEl.current.scrollTop + (flipped
      ? this.scrollableDomEl.current.scrollHeight - (this.scrollHeight || 0)
      : 0);

    const scrollHeightDifference = this.scrollHeight ? this.scrollHeight - this.scrollableDomEl.current.scrollHeight : 0;

    if (flipped && scrollHeightDifference > 0) {
      newScrollTop += scrollHeightDifference;
    }

    if (newScrollTop !== this.scrollableDomEl.current.scrollTop) {
      this.scrollableDomEl.current.scrollTop = newScrollTop;
    }

    this.scrollTop = this.scrollableDomEl.current.scrollTop;
    this.scrollHeight = this.scrollableDomEl.current.scrollHeight;
  }

  clearTimer() {
    clearTimeout(timerScrolling);
    timerScrolling = 0;
  }

  showButtonToBottom() {
    this.clearTimer();

    timerScrolling = setTimeout(() => {
      const domNode = this.scrollableDomEl.current;

      if (domNode.scrollTop < (domNode.scrollHeight - domNode.clientHeight)) {
        this.setState({ isShowScrollToBottom: true });
      } else {
        this.setState({ isShowScrollToBottom: false });
      }
    }, 100);
  }

  loadMoreComments() {
    const { active, loadMoreCommentsAsync, page, asyncLoading, offerId } = this.props;

    if (!active || asyncLoading) return;

    loadMoreCommentsAsync(active, page, offerId);
  }

  renderBubble(comment) {
    const { channels, active } = this.props;
    const channelIdentType = channels[active].identType;
    const channelBlocked = channels[active].blocked;

    return (
      <BubbleApp
        key={comment.id}
        id={comment.id}
        channelId={comment.channelId}
        userId={comment.userId}
        roleUser={comment.roleUser}
        ordersUrl={comment.ordersUrl}
        name={comment.name}
        time={comment.time}
        commentText={comment.commentText}
        replyComment={comment.replyComment}
        viewed={comment.viewed}
        sms={comment.sms}
        email={comment.email}
        viewedOrder={comment.viewedOrder}
        identType={channelIdentType}
        blocked={channelBlocked}
      />
    );
  }

  renderWrap(keyWrap, prevDate, bubble) {
    return (
      <div key={keyWrap} className='chat-msgs-body__day-container'>
        <div className='chat-msgs-body__day-divider'>
          <div className='chat-msgs-body__day-divider-label'>
            {prevDate}
          </div>
        </div>
        <div className='chat-msgs-body__day-msgs'>
          {bubble}
        </div>
      </div>
    );
  }

  renderComments() {
    const { active } = this.props;

    if (!active) return;

    let currentDate = null,
      prevDate = null,
      bubble = [],
      bubbleNext = null,
      keyWrap = null,
      cntWrap = 1;

    const { channels, comments } = this.props;

    console.log(channels);
    console.log(comments);
    const commentsIds = channels[active].commentsIds;
    const allComments = [];

    // allComments = commentsIds.map((id) => {
    //   if (comments[id]) {
    //     const currentComment = comments[id];

    //     return (
    //       this.renderBubble(currentComment)
    //     );
    //   }
    // });


    [].map.call(commentsIds, (id) => {
      if (comments[id]) {
        const currentComment = comments[id];

        currentDate = currentComment.created;

        if (bubbleNext !== null) {
          bubble = [];
          bubble.push(bubbleNext);
          bubbleNext = null;
        }

        if (prevDate === null) {
          prevDate = currentDate;
          bubble.push(
            this.renderBubble(currentComment)
          );
        } else if (currentDate === prevDate) {
          prevDate = currentDate;
          bubble.push(
            this.renderBubble(currentComment)
          );
        } else if (currentDate !== prevDate) {
          bubbleNext = this.renderBubble(currentComment);

          keyWrap = `kw${cntWrap++}`;

          allComments.push(
            this.renderWrap(keyWrap, prevDate, bubble)
          );
          prevDate = currentDate;
          bubble = [];
        }
      }
    });

    if (bubble.length !== 0) {
      keyWrap = `kw${cntWrap++}`;

      allComments.push(
        this.renderWrap(keyWrap, prevDate, bubble)
      );
    }

    if (bubbleNext !== null) {
      keyWrap = `kw${cntWrap++}`;

      allComments.push(
        this.renderWrap(keyWrap, prevDate, bubbleNext)
      );
    }

    return allComments;
  }

  render() {
    const { flipped } = this.props;

    return (
      <div
        className='chat-msgs-body__scroller'
        data-ref='smoothScrollingWrapper'
        ref={this.scrollableDomEl}
      >
        <div className='chat-msgs-body__intro' />
        {this.renderComments()}
        <div
          onClick={this.handleClickScrollToBottom}
          className={`scroll-top-wrapper ${this.state.isShowScrollToBottom ? 'show' : ''}`}
          ref={this.scrollToBottom}
        >
          <span className='scroll-top-inner'>
            <svg viewBox="0 0 24 24">
                <path d="M11.9 17.404a.992.992 0 0 1-.725-.315L1.269 8.175a.994.994 0 1 1 1.45-1.361l9.181 8.141 9.181-8.141a.994.994 0 1 1 1.45 1.361l-9.906 8.914a.992.992 0 0 1-.725.315z"></path>
            </svg>
          </span>
        </div>
      </div>
    );
  }
}
