import {
  SELECTED_ROOM_ITEM,
  REMOVE_SELECTED_ROOM_ITEM,
  CLEAR_SELECTED_ROOM_ITEM,
  OPEN_DESCRIPTION_ROOM_ITEM,
  CLOSE_DESCRIPTION_ROOM_ITEM
} from '../actions/booking_rooms_item';

const initialState = {
  selectedRoomId: 0,
  selectedRoom: {},
  descriptionRoomsIds: {},
  apartments: {}
};

const actionsMap = {
  [SELECTED_ROOM_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      ...payload
    };
  },

  [REMOVE_SELECTED_ROOM_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      ...payload
    };
  },

  [OPEN_DESCRIPTION_ROOM_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionRoomsIds: payload
    };
  },

  [CLOSE_DESCRIPTION_ROOM_ITEM]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      descriptionRoomsIds: payload
    };
  },

  [CLEAR_SELECTED_ROOM_ITEM]: (state) => {
    return {
      ...state,
      selectedRoomId: 0,
      selectedRoom: {},
      descriptionRoomsIds: {}
    };
  }
};

export default function bookingRoomsItemReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
