import {
  INIT_BOOKING
} from '../actions/booking';

const initialState = {
  id: null,
  singleChoicePrograms: null,
  firstDate: undefined,
  lastDate: undefined,
  startDates: undefined,
  allowCustomDuration: false,
  maxAdultsCount: null,
  maxChildsCount: null,
  activeHotels: {}
};

const actionsMap = {
  [INIT_BOOKING]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      ...payload.offer
    };
  }
};

export default function bookingReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
