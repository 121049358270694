export default function clickJivoOpen() {
  jivo_init();
  const interval = setInterval(() => {
    clearInterval(interval);

    if (jivo_api && document.querySelector('#no-jivosite')) {
      try {
        jivo_api.open();
      } catch (err) {
        console.log('jivo_open');
      }
    }
  }, 2500);
}
