import hideMenuTable from '../../menu_table/hideMenuTable';
import { ReachGoal } from '../../../YandexMetrika';
// import { Ga } from '../../../GoogleAnalytics';
import { Goal } from '../../../Vk';

export default function clickGoToBooking(e) {
  hideMenuTable();

  if (e.currentTarget.dataset.reachgoal) {
    ReachGoal(e.currentTarget.dataset.target, { offer: e.currentTarget.dataset.offer });
  }
  // if (e.currentTarget.dataset.ga) {
  //   Ga(e.currentTarget.dataset.target);
  // }
  if (e.currentTarget.dataset.vkgoal) {
    Goal(e.currentTarget.dataset.target);
  }
  e.currentTarget.href = '#anchor-page-event__booking';
}
