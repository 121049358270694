import React, { PureComponent } from 'react';
import BookingSelectboxDropdown from './BookingSelectboxDropdown';
import Popup from '../../components/Popup';

export default class FilterHotels extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedHotelIds: [],
      initSelectedHotelIds: []
    };

    this.handleSelectedInput = this.handleSelectedInput.bind(this);
    this.handleClearSelectedInput = this.handleClearSelectedInput.bind(this);
    this.handleSelectedAllInput = this.handleSelectedAllInput.bind(this);
    this.handleSelectBoxClick = this.handleSelectBoxClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    const { initFilterHotel, selectedHotelIds } = this.props;

    initFilterHotel();
    this.initStateSelectedHotelIds();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.entries(props.activeHotelIds || []).length === 0) return null;
    // if (Object.entries(state.initSelectedHotelIds).length === 0) return null;

    if (props.activeHotelIds.filter(e => !state.initSelectedHotelIds.includes(e)).length > 0) {
      return {
        selectedHotelIds: props.activeHotelIds,
        initSelectedHotelIds: props.activeHotelIds
      };
    }

    return null;
  }

  initStateSelectedHotelIds() {
    const { members, offer } = this.props;
    const parentEl = Object.entries(members.activeHotels).length > 0 ? members : offer;

    this.setState(() => ({
      selectedHotelIds: [...new Set(...Object.values(parentEl.activeHotels))],
      initSelectedHotelIds: [...new Set(...Object.values(parentEl.activeHotels))]
    }));
  }

  handleSelectBoxClick() {
    const { isOpenFilterHotel, openFilterHotels, closeFilterHotels, filterHotels } = this.props;

    if (isOpenFilterHotel) {
      this.setState(() => ({
        selectedHotelIds: Object.assign([], filterHotels.selectedHotelIds)
      }));
      closeFilterHotels();
    } else {
      openFilterHotels();
    }
  }

  handleSelectedInput(event) {
    const { selectedHotelIds } = this.state;
    const input = event.target;
    const hotelId = parseInt(input.value, 10);
    const newselectedHotelIds = (input.checked)
      ? [...new Set([...selectedHotelIds, ...[hotelId]])]
      : selectedHotelIds.filter(elem => elem !== hotelId);

    this.setState(() => ({
      selectedHotelIds: newselectedHotelIds
    }));
  }

  handleClearSelectedInput() {
    this.setState(() => ({
      selectedHotelIds: []
    }));
  }

  handleSelectedAllInput() {
    this.initStateSelectedHotelIds();
  }

  handleSaveClick() {
    const { selectedHotelIds } = this.state;
    const { closeFilterHotels, selectedFilterHotels } = this.props;

    selectedFilterHotels(selectedHotelIds);
    closeFilterHotels();
  }

  viewHotel(hotel) {
    const { filterFoods, interestCategories, filterHotels } = this.props;

    return hotel.foodTypeCode.some(v => filterFoods.selectedFoodIds.includes(v))
      && hotel.interestCategoriesIds.some(v => interestCategories.selectedInterestIds.includes(v));
      // && filterHotels.selectedHotelIds.includes(hotel.id);
  }

  render() {
    const { selectedHotelIds } = this.state;
    const { offer, members, isOpenFilterHotel, typeView } = this.props;
    const parentEl = Object.entries(members.apartmentPrograms).length > 0 ? members : offer;
    let hotels = Object.entries(parentEl.apartmentPrograms).map(([id, program]) => program.hotels)[0];
    hotels = Object.values(hotels).filter(hotel => this.viewHotel(hotel)).reduce((acc = {}, hotel) => { acc[hotel.id] = hotel; return acc; }, {});
    const qntHotels = Object.entries(hotels).length;
    const oneHotelName = Object.values(hotels).length > 0 ? Object.values(hotels)[0].name : 'Отели';

    return (
      <div className='booking__select-item booking__select-item-small __desktop'>
        <div className='booking__select-item-content'>
          <div
            className={`booking__selectbox ${isOpenFilterHotel ? 'booking__selectbox--open' : ''}`}
          >
            <div
              className='booking__selectbox-head'
              onClick={qntHotels > 1 ? this.handleSelectBoxClick : null}
            >
              <div className='booking__selectbox-head-text'>{qntHotels > 1 ? 'Отели' : oneHotelName}</div>
              { qntHotels > 1 && (
                <div className='booking__selectbox-filter'>фильтр</div>
              )}
            </div>
          </div>
        </div>
        {qntHotels > 1 && (
          <Popup show={isOpenFilterHotel} onClose={this.handleSelectBoxClick} variant='small' withHeader={false}>
            <BookingSelectboxDropdown
              handleInputChange={this.handleSelectedInput}
              listTitle='Отели'
              nodes={hotels}
              prefix={`${typeView}__hotel`}
              checkedItems={selectedHotelIds}
              onClick={this.handleSaveClick}
              onClearClick={this.handleClearSelectedInput}
              onSelectAllClick={this.handleSelectedAllInput}
              disabledSave={selectedHotelIds.length === 0}
            />
          </Popup>
        )}
      </div>
    );
  }
}
