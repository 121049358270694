import { action } from '../../../../common/javascript/api';

export default function addMassMediaItems(e) {
  e.preventDefault();

  const content = document.querySelector('.about-us__mentions-content');
  const dataOffset = e.currentTarget.getAttribute('data-offset');
  const dataAllQuantity = e.currentTarget.getAttribute('data-all-quantity');
  const btn = document.querySelector('.about-us__mentions-btn-wrap1');

  if ((parseInt(dataOffset, 10) + 3) >= parseInt(dataAllQuantity, 10)) {
    btn.parentNode.removeChild(btn);
  }

  e.currentTarget.setAttribute('data-offset', parseInt(dataOffset, 10) + 3);

  action(`/mass_medias?offset=${dataOffset}`, {}, 'GET', 'text')
    .then(
      result => {
        // console.log(result);
        const parser = new DOMParser();
        const doc = parser.parseFromString(result, 'text/html');
        const nodes = doc.querySelectorAll('.about-us__mentions-item');

        if (nodes.length > 0) {
          nodes.forEach((item) => {
            content.appendChild(item);
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  // scrollPageTo(btn);
}
