import React from 'react';
import useAuth from '../../../../hooks/use-auth';
import usePopup from '../../../../hooks/use-popup';

const WrapHook = Component => (props) => {
  const { isLoggedIn } = useAuth();
  const [isFormOpen, openForm, closeForm] = usePopup();
  const [isFormOpen2, openForm2, closeForm2] = usePopup();
  const [isAuthFormOpen, openAuthForm, closeAuthForm] = usePopup();

  return (
    <Component
      {...props}
      isLoggedIn={isLoggedIn}
      isFormOpen={isFormOpen}
      openForm={openForm}
      closeForm={closeForm}
      isFormOpen2={isFormOpen2}
      openForm2={openForm2}
      closeForm2={closeForm2}
      isAuthFormOpen={isAuthFormOpen}
      openAuthForm={openAuthForm}
      closeAuthForm={closeAuthForm}
    />
  );
};

export default WrapHook;
