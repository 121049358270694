export default function Goal(params) {
  const event = params.split('_')[1];
  let vkEvent = '';
  // console.log('Goal', event);

  if (event === 'choose') vkEvent = 'initiate_checkout';
  if (event === 'booking') vkEvent = 'purchase';
  if (event === 'pageend') vkEvent = 'view_content';

  if (typeof (VK) === 'function') {
    try {
      VK.Goal(vkEvent);
    } catch (err) {
      console.log(err);
    }
  } else {
    // console.log('not func VK');
  }
}
