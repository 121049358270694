import {
  INIT_FILTER_FOOD,
  SELECTED_FILTER_FOOD,
  REMOVE_SELECTED_FILTER_FOOD
} from '../actions/filter_food';

const initialState = {
  selectedFoodIds: [],
  isOpenFilterFood: false,
  isOpenFilterInterestFood: false
};

const actionsMap = {
  [INIT_FILTER_FOOD]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedFoodIds: payload
    };
  },

  [SELECTED_FILTER_FOOD]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedFoodIds: payload
    };
  },

  [REMOVE_SELECTED_FILTER_FOOD]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      selectedFoodIds: payload
    };
  }
};

export default function filterFoodsReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
