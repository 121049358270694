import React, { useState, useRef } from 'react';

const SubmitButton = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [statusText, setStatusText] = useState('');
  const [styleStatus, setStyleStatus] = useState('');
  const refButton = useRef(null);
  const latestProps = useRef(props);

  const handleSubmit = () => {
    setIsDisabled(true);
    setStatusText('');
    setStyleStatus('');

    const currentForm = refButton.current.form;
    const formData = new FormData(currentForm);

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);

        if (data.status !== 'ok') {
          setStatusText(data.errors);
          setStyleStatus('label alert fadeOut');
        } else {
          setStatusText('Успешно сохранено');
          setStyleStatus('label success fadeOut');
        }
        setIsDisabled(false);
      }
    };

    xhr.open(currentForm.method, currentForm.action, true);
    xhr.send(formData);
  };

  // const getMsg = () => {
  //   return (
  //     <div className={`callout ${styleStatus}`} data-closable=''>
  //       <p>{statusText}</p>
  //       <button className='close-button' aria-label='Close alert' type='button' data-close=''>
  //         <span aria-hidden='true'>&times;</span>
  //       </button>
  //     </div>
  //   );
  // };

  const getLabel = () => {
    return (
      <span className={styleStatus}>
        {statusText}
      </span>
    );
  };

  return (
    <div className='grid-y'>
      <div className='cell small-2'>
        <button
          ref={refButton}
          type='submit'
          className={latestProps.current.buttonClass}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          <span
            className='btn-text'
          >
            {latestProps.current.buttonName}
          </span>
        </button>
      </div>
      <div className='cell auto'>
        {getLabel()}
      </div>
    </div>
  );
};

SubmitButton.defaultProps = {
  buttonClass: 'button',
  buttonName: 'Сохранить'
};

export default SubmitButton;
