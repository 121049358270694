import { Map } from 'immutable';
import {
  INIT_CHAT,
  SUBSCRIPTION_CHANNEL,
  UNSUBSCRIPTION_CHANNEL,
  NEW_SOCKET_MESSAGE,
  DEL_SOCKET_MESSAGE,
  RESET_CHANNEL
} from '../actions/chat';
import { REQUEST_COMMENTS, RECEIVE_COMMENTS, ERROR_REQUEST } from '../actions/block_messages';
import {
  REQUEST_ADD_COMMENT,
  RECEIVE_ADD_COMMENT,
  ERROR_REQUEST_ADD_COMMENT,
  REMOVE_REPLY_MSG
} from '../actions/form_comments';
import { ADD_REPLY_MSG, DELETE_MSG } from '../actions/bubble';
import { RECEIVE_PERSON, CHOOSE_ACTIVE_CHAT, VIEW_NEW_COMMENTS } from '../actions/person';

const initialState = Map({
  asyncLoading: false,
  asyncError: null,
  active: null,
  page: 1,
  isAddComment: false,
  newCommentsIds: null,
  unreadCommentsIds: null,
  socketUrl: null,
  replyIdMsg: '',
  attachMsgAuthor: '',
  flipped: true,
  scrollLoadThreshold: 10
});

const actionsMap = {
  // chat
  [INIT_CHAT]: (state, action) => {
    const { payload } = action;

    return state.merge({
      ...payload
    });
  },

  [SUBSCRIPTION_CHANNEL]: (state, action) => {
    const url = action.payload;

    return state.merge({
      socketUrl: url
    });
  },

  [UNSUBSCRIPTION_CHANNEL]: (state) => {
    return state.merge({
      socketUrl: null
    });
  },

  [NEW_SOCKET_MESSAGE]: (state, action) => {
    const { payload } = action;
    const newCommentsIds = Map(state.get('newCommentsIds'));
    const unreadCommentsIds = Map(state.get('unreadCommentsIds'));

    return state.merge({
      comments: Map(state.get('comments')).merge(payload.comments),
      channels: Map(state.get('channels')).merge(payload.channels),
      newCommentsIds: newCommentsIds.update(payload.channelId.toString(), v => v.concat([payload.id])),
      unreadCommentsIds: unreadCommentsIds.update(payload.channelId.toString(), v => v.concat([payload.id]))
    });
  },

  [DEL_SOCKET_MESSAGE]: (state, action) => {
    const id = action.payload;

    return state.merge({
      comments: state.get('comments').delete(id)
    });
  },

  [RESET_CHANNEL]: (state) => {
    return state.merge({
      active: null
    });
  },

  // block messages
  [REQUEST_COMMENTS]: (state, action) => {
    const { currentPage } = action.payload.toJS();

    return state.merge({
      asyncLoading: true,
      page: currentPage
    });
  },

  [RECEIVE_COMMENTS]: (state, action) => {
    const { payload } = action;
    const ids = action.unreadIds;
    const { currentLoadingStart } = action;

    return state.merge({
      comments: Map(state.get('comments')).merge(payload),
      asyncLoading: false,
      loadingStart: currentLoadingStart,
      unreadCommentsIds: ids
    });
  },

  [ERROR_REQUEST]: (state, action) => {
    const { payload } = action;

    return state.merge({
      ...payload,
      asyncLoading: false
    });
  },

  [REQUEST_ADD_COMMENT]: (state) => {
    return state.merge({
      isAddComment: true
    });
  },

  [RECEIVE_ADD_COMMENT]: (state, action) => {
    const { payload } = action;

    return state.merge({
      comments: Map(state.get('comments')).merge(payload.comments),
      channels: Map(state.get('channels')).merge(payload.channels),
      isAddComment: false
    });
  },

  [ERROR_REQUEST_ADD_COMMENT]: (state, action) => {
    const { payload } = action;

    return state.merge({
      ...payload,
      isAddComment: false
    });
  },
  // person
  [RECEIVE_PERSON]: (state, action) => {
    const { payload } = action;

    return state.merge({
      channels: state.get('channels').mergeDeep(payload)
    });
  },

  [CHOOSE_ACTIVE_CHAT]: (state, action) => {
    const channelId = action.activeChannel;

    return state.merge({
      active: channelId
    });
  },

  [VIEW_NEW_COMMENTS]: (state, action) => {
    const unreadIds = action.payload;

    return state.merge({
      unreadCommentsIds: unreadIds
    });
  },
  // bubble
  [ADD_REPLY_MSG]: (state, action) => {
    const { payload } = action;

    return state.merge({
      ...payload
    });
  },

  [REMOVE_REPLY_MSG]: (state) => {
    return state.merge({
      replyIdMsg: '',
      attachMsgAuthor: ''
    });
  },

  [DELETE_MSG]: (state, action) => {
    const id = action.payload;

    return state.merge({
      comments: state.get('comments').delete(id)
    });
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
