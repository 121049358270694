import { formSubmit } from '../../../../common/javascript/api';

export default function sendPhone(e) {
  e.preventDefault();

  const currentForm = document.querySelector('#callback_phone_id');
  const inputPhone = document.querySelector('.react-phone-number-input__row');
  // const elemError = document.querySelector('.react-phone-number-input__error');
  const elemError = document.querySelector('.errors_callback');
  const data = formSubmit(currentForm);

  if (data.status === 'no_content') {
    elemError.innerText = data.callback_text;

    return;
  }

  if (data.status !== 'ok') {
    if (elemError) {
      elemError.innerText = 'Неправильно указан телефон.';
    } else {
      const elemError2 = document.createElement('div');
      elemError2.classList.add('react-phone-number-input__error');
      inputPhone.parentNode.insertBefore(elemError2, inputPhone.nextSibling);
      elemError2.innerText = 'Неправильно указан телефон.';
    }
  } else {
    elemError.innerText = data.callback_text;
  }
}
