import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Chat from '../components/chat';
import * as ChatActions from '../actions/chat';

function mapStateToProps(state) {
  return state.chat.toJS();
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ChatActions, dispatch);
}

const ChatApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat);

export default ChatApp;
