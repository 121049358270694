export const REQUEST_ADD_COMMENT = 'REQUEST_ADD_COMMENT';
export const RECEIVE_ADD_COMMENT = 'RECEIVE_ADD_COMMENT';
export const ERROR_REQUEST_ADD_COMMENT = 'ERROR_REQUEST_ADD_COMMENT';
export const REMOVE_REPLY_MSG = 'REMOVE_REPLY_MSG';

export function requestAddComment() {
  return {
    type: REQUEST_ADD_COMMENT
  };
}

export function receiveAddComments(comments) {
  return {
    type: RECEIVE_ADD_COMMENT,
    payload: comments
  };
}

export function errorRequest(error) {
  return {
    type: ERROR_REQUEST_ADD_COMMENT,
    payload: error
  };
}

export function removeReplyMsg() {
  return {
    type: REMOVE_REPLY_MSG
  };
}

export function addCommentAction(channel, comment) {
  return (dispatch) => {
    dispatch(requestAddComment());

    const commentWithToken = Object.assign(comment, { 'authenticity_token': $('[name="csrf-token"]')[0].content });

    $.ajax({
      url: `/channels/${channel}/comments`,
      type: 'POST',
      dataType: 'json',
      // data: JSON.stringify(comment),
      data: commentWithToken,
      cache: false,
      success(data) {
        // console.log(data, channel);
        dispatch(receiveAddComments(data));
      },
      error(xhr, status, err) {
        console.error(`/channels/${channel}/comments`, status, err.toString());
        dispatch(errorRequest(Map({ asyncError: err.toString() })));
      }
    });
  };
}
