import React, { Component } from 'react';

export default class Bubble extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleReply = this.handleReply.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.asyncLoading) return false;
    if (!nextProps.newCommentsIds[this.props.channelId.toString()].includes(this.props.id)) return false;
    // console.log('nextProps1', nextProps.newCommentsIds[this.props.channelId.toString()]);
    // console.log('nextProps2', this.props.id);
    // console.log('nextProps3', nextProps.newCommentsIds[this.props.channelId.toString()].includes(this.props.id));
    return true;
  }

  handleRemove() {
    const { active, id, deleteMsgAction } = this.props;

    deleteMsgAction(active, id.toString());
  }

  handleReply() {
    const { id, name, addReplyMsgAction } = this.props;

    addReplyMsgAction(id, name);
  }

  wrapAnchorTag(text) {
    const { id } = this.props;
    const urlPath = text.match(/((https?:\/\/|www\.)?[^\s\(\)<>]+\.[a-zA-Z]{2,4}(\/\S*)?)/ig);
    const textSplit = text.replace(/((https?:\/\/|www\.)?[^\s\(\)<>]+\.[a-zA-Z]{2,4}(\/\S*)?)/ig, '$!$').split('$!$');
    const retText = [];

    if (urlPath) {
      urlPath.forEach((item, i) => {
        let href = '';

        if (item.match(/^(?:f|ht)tps?:\/\//ig)) {
          href = item;
        } else {
          href = `http://${item}`;
        }

        retText.push(textSplit[i]);
        retText.push(<a key={`wrap${i}-${id}`} href={href} target='_blank'>{item}</a>);
      });

      if (textSplit.length > urlPath.length) {
        retText.push(textSplit[textSplit.length - 1]);
      }
      return retText;
    }

    return text;
  }

  bubbleToggle() {
    const {
      id,
      channelId,
      userId,
      roleUser,
      ordersUrl,
      name,
      time,
      commentText,
      replyComment,
      viewed,
      sms,
      email,
      viewedOrder,
      identType,
      blocked
    } = this.props;
    const commentId = `comment${id}`;
    let linkOrders, removeLink, viewedMsg, replyCommentText, replyBlock, labelSms, labelEmail, cntViewed;

    const leftRigth = (roleUser === 'user') ? 'chat-message' : 'chat-message chat-message_right';

    if (sms) {
      labelSms = (<i className='fa fa-mobile fa-fw'/>);
    }

    if (email) {
      labelEmail = (<i className='fa fa-envelope fa-fw'/>);
    }

    if (gon.current_user_role === 'admin') {
      linkOrders = (
        <span className='chat-message__message-autor'>
          <a href={ordersUrl}>{name}</a>
        </span>
      );
      removeLink = (
        <a onClick={this.handleRemove}>Удалить</a>
      );

      if (identType !== 'private') {
        const cnt = (viewed - viewedOrder) < 1 ? 0 : (viewed - viewedOrder);

        cntViewed = (
          <i className='fa fa-eye fa-fw'>
            {cnt}
          </i>
        );
      }

      viewedMsg = (
        <span className='chat-message__viewed'>
          {labelSms}
          {labelEmail}
          {cntViewed}
        </span>
      );
    } else {
      linkOrders = (
        <span className='chat-message__message-autor'>
          {name}
        </span>
      );
      removeLink = '';
      viewedMsg = '';
    }

    if (replyComment !== null) {
      const reply = JSON.parse(replyComment);

      replyCommentText = (
        <blockquote className='chat-message__quoted-text'>
          {this.wrapAnchorTag(reply.commentText)}
          <cite>
            {reply.name}
          </cite>
        </blockquote>
      );
    }

    if (gon.current_user_id.toString() !== userId.toString() &&
      identType !== 'private' &&
      !blocked) {
      replyBlock = (
        <a data-channel-id={channelId}
          data-reply-id={id}
          onClick={this.handleReply}
        >
          Ответить
        </a>
      );
    }


    const wrapCommentText = this.wrapAnchorTag(commentText);

    if (roleUser === 'user') {
      return (
        <div className='chat-message' id={commentId}>
          <div className='chat-message__message'>
            {linkOrders}
            <span className='chat-message__message-time'>{time}</span>
            {viewedMsg}
            {replyCommentText}
            <div className='chat-message__message-text'>{wrapCommentText}</div>
          </div>
          <div className='chat-message__actions'>
            {replyBlock}
            {removeLink}
          </div>
        </div>
      );
    }

    return (
      <div className='chat-message chat-message_right' id={commentId}>
        <div className='chat-message__actions'>
          {removeLink}
          {replyBlock}
        </div>
        <div className='chat-message__message chat-message__message_admin'>
          {linkOrders}
          <span className='chat-message__message-time'>{time}</span>
          {viewedMsg}
          {replyCommentText}
          <div className='chat-message__message-text'>{wrapCommentText}</div>
        </div>
      </div>
    );
  }

  render() {
    const { id } = this.props;
    const commentId = `wrap-comment${id}`;

    return (
      <div key={commentId}>{this.bubbleToggle()}</div>
    );
  }
}
