import * as React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { dateFnsLocales } from '../../../../helpers/dates';

import * as styles from './DateShow.module.scss';

const DateShow = ({ date, locale }) => {
  const dateFnsLocale = dateFnsLocales[locale];
  const dayAndMonth = format(date, 'd MMMM', { locale: dateFnsLocale });
  const dayName = format(date, 'eeeeee', { locale: dateFnsLocale });

  return (
    <span className={styles.Date}>
      {dayAndMonth}
      <span className={styles.Date__dayName}>{` ${dayName}`}</span>
    </span>
  );
};

DateShow.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  locale: PropTypes.string
};

DateShow.defaultProps = {
  locale: 'ru'
};

export default DateShow;
