import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPicker,  { DateUtils } from 'react-day-picker';
import LocaleUtils from 'react-day-picker/moment';


export default class BookingDatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  handleDayClick(day, modifiers) {
    if (modifiers.disabled) return;

    const { onChange } = this.props;

    onChange(day);
  }

  render() {
    const {
      firstDate,
      lastDate,
      startDate,
      finishDate,
      recommendedDate,
      initialMonth,
      enabledDays,
      startPicker,
      endPicker
    } = this.props;
    const currentFirstDate = moment(firstDate).toDate();
    const currentLastDate = moment(lastDate).toDate();
    const currentStartDate = moment(startDate).toDate();
    const currentFinishDate = finishDate;
    const currentInitialMonth = initialMonth || moment().toDate();
    const currentRecommendedDate = recommendedDate;

    const modifiers = {
      'weekend': (day) => {
        return moment(day).isoWeekday() === 7
          || moment(day).isoWeekday() === 6;
      },

      'start': (day) => {
        return currentStartDate
          && moment(day).isSame(currentStartDate)
          && startPicker;
      },

      'finish': (day) => {
        return currentFinishDate
          && moment(day).isSame(currentFinishDate)
          && endPicker;
      },

      'disabled': (day) => {
        return moment(day).isBefore(moment())
          || moment(day).isBefore(currentFirstDate, 'd')
          || moment(day).isAfter(currentLastDate, 'd')
          || (startPicker && !enabledDays.includes(moment(day).format('YYYY-MM-DD')))
          || (endPicker && moment(day).isBefore(moment(enabledDays[0].fromEndDate).toDate(), 'd'))
          || (endPicker && moment(day).isAfter(moment(enabledDays[0].toEndDate).toDate(), 'd'));
      },

      'selected': (day) => {
        return (endPicker && (enabledDays[0].fromEndDate === enabledDays[0].toEndDate) && moment(day).isSame(moment(enabledDays[0].toEndDate).toDate(), 'd'));
      },

      'recommended': currentRecommendedDate
    };

    return (
      <DayPicker
        locale='ru'
        localeUtils={LocaleUtils}
        modifiers={modifiers}
        onDayClick={this.handleDayClick}
        fromMonth={currentFirstDate}
        toMonth={currentLastDate}
        month={currentInitialMonth}
        showOutsideDays
      />
    );
  }
}

BookingDatePicker.propTypes = {
  firstDate: PropTypes.instanceOf(Date).isRequired,
  lastDate: PropTypes.instanceOf(Date).isRequired,
  initialMonth: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date),
  finishDate: PropTypes.instanceOf(Date),
  recommendedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired
};
