import React, { PureComponent } from 'react';

import cn from 'classnames';
import Select from '../../../components/Select';
import { declOfNum } from '../../../../../common/javascript/api';

import * as styles from './KidsAges.module.scss';

export default class KidsAges extends PureComponent {
  handleSelectChange(num, e) {
    const { plusKidsAges } = this.props;
    const age = parseInt(e.currentTarget.value, 10);

    plusKidsAges(num, age);
  }

  initialOptions(quantity) {
    const arrOptions = [];

    arrOptions.push(
      <option
        key={'valueSelect_empty'}
        value={-1}
      >
        не выбрано
      </option>
    );

    for (let i = 0; i < quantity; i++) {
      arrOptions.push(
        <option
          key={`valueSelect_${i}`}
          value={i}
        >
          {`${i} ${declOfNum(i, ['год', 'года', 'лет'])}`}
        </option>
      );
    }

    return arrOptions;
  }

  initialSelectTags(num) {
    const { members } = this.props;

    return (
      <div className={styles.KidsAges}>
        <div className={styles.KidsAges__childAgeRow} key={`kids_ages-${num}`}>
          <span>{`Ребёнок ${num + 1}`}</span>
          <span>
            <Select
              className={cn({[styles.KidsAges__childAgeRow__invalid]: members.childs[num + 1] === -1 })}
              id={`kids_ages-${num}`}
              onChange={this.handleSelectChange.bind(this, num + 1)}
              value={members.childs[num + 1]}
            >
              {this.initialOptions(18)}
            </Select>
          </span>
        </div>
        {(members.childs[num + 1] === -1) && (
          <div className={cn(styles.KidsAges__childAgeRow, styles.KidsAges__invalid)} key={`kids_ages-invalid-${num}`}>
            <span>
              Укажите возраст ребенка
            </span>
          </div>
        )}
      </div>
    );
  }

  createSelectTags() {
    const { members } = this.props;
    const arrKidsAgess = [];

    for (let i = 0; i < members.childsCount; i++) {
      arrKidsAgess.push(this.initialSelectTags(i));
    }

    return arrKidsAgess;
  }

  render() {
    const { offer, members } = this.props;

    if (offer.maxChildsCount === 0 || members.childsCount === 0) return '';

    return (
      <div className={cn(styles.KidsAges, styles.KidsAges__childAges)}>
        <div className={styles.KidsAges__labelWrap}>
          <strong>Cколько полных лет ребенку на дату выезда?</strong>
        </div>
        {this.createSelectTags()}
      </div>
    );
  }
}
