// import clickLink from '../../common/clickLink';
import showHideCallbackPhoneLink from '../../callback_phone/showHideCallbackPhoneLink';
import hideHamburgerMenu from '../../common/hideHamburgerMenu';

export default function clickEvent(e) {
  hideHamburgerMenu();
  e.currentTarget.href = '/#anchor-page-main__block_events';
  // clickLink(e, '.page-main__block_events', 'events');
  showHideCallbackPhoneLink();
}
