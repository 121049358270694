import React, { PureComponent } from 'react';

import Checkbox from '../../../components/Checkbox';
import Action from '../../../components/Action';

import * as styles from './BookingSelectboxDropdown.module.scss';
import { sortObject } from '../../../../../common/javascript/api';

export default class BookingSelectboxDropdown extends PureComponent {
  renderSelectboxListGroup() {
    const { nodes, handleInputChange, prefix, checkedItems } = this.props;
    let arrSelectboxListGroup = [];
    console.log(sortObject(nodes));


    // for (const [key, value] of Object.entries(nodes)) {
    arrSelectboxListGroup = sortObject(nodes)?.map((node) => {
      return (
        <Checkbox
          key={`${prefix}_CheckBox_${node.key}`}
          label={node.value.name}
          id={`${prefix}_CheckBox_${node.key}`}
          name={`${prefix}_CheckBoxInputName_${node.key}`}
          type='checkbox'
          checked={checkedItems.includes(parseInt(node.key, 10))}
          onChange={handleInputChange}
          value={node.key}
        />
      );
    });

    return arrSelectboxListGroup;
  }

  render() {
    const { listTitle, onSelectAllClick, onClearClick, onClick, disabledSave } = this.props;

    return (
      <div
        className={styles.BookingSelectboxDropdown}
      >
        <div className={styles.BookingSelectboxDropdown__title}>{listTitle}</div>
        <div className={styles.BookingSelectboxDropdown__group}>
          {this.renderSelectboxListGroup()}
        </div>
        <div className={styles.BookingSelectboxDropdown__group_btn}>
          <span>
            <Action
              onClick={onSelectAllClick}
            >
              ВЫБРАТЬ ВСЁ
            </Action>
            <Action
              onClick={onClearClick}
            >
              ОЧИСТИТЬ
            </Action>
          </span>
          <Action
            themes={['Button', 'ButtonCommon', 'ButtonWarning']}
            onClick={onClick}
            disabled={disabledSave}
          >
            сохранить
          </Action>
        </div>
      </div>
    );
  }
}
