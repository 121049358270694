import React from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import usePopup from '../../../../../hooks/use-popup';
import Popup from '../../../components/Popup';
import Action from '../../../components/Action';
import EventsList from './EventsList';
import FaqList from './FaqList';

import InfoOutIcon from '../../../../../../icons/infoOut.svg';
import PriceIcon from '../../../../../../icons/price2.svg';
import FaqIcon from '../../../../../../icons/question.svg';

import * as styles from './BookingCrossBar.module.scss';

const BookingCrossBar = ({ offerId, bonuses }) => {
  const [isFaqFormOpen, openFaqForm, closeFaqForm] = usePopup();
  const [isInfoFormOpen, openInfoForm, closeInfoForm] = usePopup();

  return (
    <>
      <div className={styles.BookingCrossBar}>
        <Action
          className={styles.BookingCrossBar__link}
          onClick={openInfoForm}
        >
          <div className={styles.BookingCrossBar__icon}>
            {/* <div className={styles.BookingCrossBar__infoIcon}>≣</div> */}
            <IconSvg icon={InfoOutIcon} className={styles.BookingCrossBar__infoIcon} />
          </div>
          <div className={styles.BookingCrossBar__text}>
            Меню
          </div>
        </Action>
        <Action
          className={styles.BookingCrossBar__link}
          href='#anchor-page-event__booking'
        >
          <div className={styles.BookingCrossBar__icon}>
            {/* <div className={styles.BookingCrossBar__price}>₽</div> */}
            <IconSvg icon={PriceIcon} className={styles.BookingCrossBar__price} />
          </div>
          <div className={styles.BookingCrossBar__text}>
            Бронирование
          </div>
        </Action>
        <Action
          className={styles.BookingCrossBar__link}
          onClick={openFaqForm}
        >
          <div className={styles.BookingCrossBar__icon}>
            {/* <div className={styles.BookingCrossBar__faq}>?</div> */}
            <IconSvg icon={FaqIcon} className={styles.BookingCrossBar__faq} />
          </div>
          <div className={styles.BookingCrossBar__text}>
            FAQ
          </div>
        </Action>
      </div>

      <Popup show={isInfoFormOpen} onClose={closeInfoForm} variant='medium'>
        <EventsList offerId={offerId} onClose={closeInfoForm} />
      </Popup>

      <Popup show={isFaqFormOpen} onClose={closeFaqForm} variant='medium'>
        <FaqList offerId={offerId} bonuses={bonuses} />
      </Popup>
    </>
  );
};

BookingCrossBar.propTypes = {
  offerId: PropTypes.number.isRequired,
  bonuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired
};

export default BookingCrossBar;
