import * as React from 'react';
import PropTypes from 'prop-types';

import DateShow from '../DateShow';

import * as styles from './DatePeriodShow.module.scss';

const DatePeriodShow = ({ startDate, finishDate }) => {
  return (
    <div className={styles.DatePeriodShow}>
      <DateShow date={startDate} />
      <span>&nbsp;–&nbsp;</span>
      <DateShow date={finishDate} />
    </div>
  );
};

DatePeriodShow.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  finishDate: PropTypes.instanceOf(Date).isRequired
};

export default DatePeriodShow;
