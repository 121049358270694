import { ADD_START_DATE, ADD_FINISH_DATE } from '../actions/offer_dates';

const initialState = {
  firstDate: undefined,
  lastDate: undefined,
  startDate: undefined,
  finishDate: undefined,
  isOpenFromDay: false,
  isOpenToDay: false
};

const actionsMap = {
  [ADD_START_DATE]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      startDate: payload,
      isOpenFromDay: false
    };
  },

  [ADD_FINISH_DATE]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      finishDate: payload,
      isOpenToDay: false
    };
  }
};

export default function offerDatesReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];

  return fn ? fn(state, action) : state;
}
