import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BookingPrice from '../components/booking_price';
import * as BookingActions from '../actions/booking';
import * as BookingOptionsItemActions from '../actions/booking_options_item';
import * as BookingRoomsItemActions from '../actions/booking_rooms_item';
import WrapHook from '../components/WrapHook';

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingActions, ...BookingOptionsItemActions, ...BookingRoomsItemActions }, dispatch);
}

const BookingPriceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapHook(BookingPrice));

export default BookingPriceContainer;
