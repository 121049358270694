import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Members from '../components/Members';
import * as MembersActions from '../actions/members';
import * as SharedActions from '../actions/shared';
import WrapHook from '../components/WrapHook';

function mapStateToProps(state) {
// console.log(state.members);
// console.log(state.offerDates);

  return {
    ...state,
    isOpenMembers: state.members.isOpenMembers,
    isOpenFromDay: state.offerDates.isOpenFromDay,
    isOpenToDay: state.offerDates.isOpenToDay,
    isOpenFilterInterest: state.interestCategories.isOpenFilterInterest,
    isOpenFilterHotel: state.filterHotels.isOpenFilterHotel,
    isOpenFilterFood: state.filterFoods.isOpenFilterFood,
    currentAdultsCount: state.members.currentAdultsCount,
    currentChildsCount: state.members.currentChildsCount
  };
}

function mapDispatchToProps(dispatch) {
  // return bindActionCreators(MembersActions, dispatch);
  return bindActionCreators({ ...MembersActions, ...SharedActions }, dispatch);
}
const MembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapHook(Members));

export default MembersContainer;
