import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import Action from '../Action';
import BonusCard from '../BonusCard/BonusCard';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import BonusesIcon from '../../../../../icons/bonuses.svg';

import * as styles from './BonusesList.module.scss';

const BonusesList = ({ bonuses, bonusesBackground, icon }) => {
  const [isBonusesOpen, openBonuses, closeBonuses] = usePopup();
  const currentThemes = () => {
    if (bonusesBackground === 'purple') {
      return ['Link'];
    }
    if (bonusesBackground === 'white' || bonusesBackground === 'transparent') {
      return ['Button', 'ButtonSmall', 'ButtonBadge', 'Fullwidth'];
    }
    if (bonusesBackground === 'grey') {
      return ['Button', 'ButtonSecondary'];
    }
    if (bonusesBackground === 'yellow') {
      return ['Button'];
    }
  };

  const BonusesListItems = bonuses?.map(bonus => (
    <BonusCard key={`bonus-card__${bonus.id}`} bonus={bonus} />
  ));

  return (
    <>
      {bonuses?.length > 0 && (
        <>
          <Action
            className={cn(
              styles.BonusesList__btn,
              { [styles.BonusesList__btnWhite]: bonusesBackground === 'white' },
              { [styles.BonusesList__btnTransparent]: bonusesBackground === 'transparent' },
              { [styles.BonusesList__btnPurple]: bonusesBackground === 'purple' },
              { [styles.BonusesList__btnGrey]: bonusesBackground === 'grey' },
              { [styles.BonusesList__btnYellow]: bonusesBackground === 'yellow' }
            )}
            themes={currentThemes()}
            to='/'
            onClick={() => openBonuses()}

          >
            {icon && (
              <IconSvg icon={BonusesIcon} className={styles.BonusesList__icon} />
            )}
            <span>Бонусы в подарок</span>
          </Action>
          <Popup
            show={isBonusesOpen}
            onClose={closeBonuses}
            variant='medium'
            withHeader={false}
          >
            <div className={styles.BonusesList}>
              {BonusesListItems}
            </div>
            <div className={styles.BonusesList__center}>
              <Action
                themes={['Button', 'ButtonCommon', 'ButtonWarning', 'Fullwidth']}
                onClick={closeBonuses}
              >
                ок, понятно
              </Action>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

BonusesList.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired,
  bonusesBackground: PropTypes.string.isRequired,
  icon: PropTypes.bool
};

BonusesList.defaultProps = {
  icon: false
};

export default BonusesList;
